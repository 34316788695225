import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import { PDFDownloadLink } from '@react-pdf/renderer';
import { usePDF, pdf } from "@react-pdf/renderer";
import PDFMerger from "pdf-merger-js";

import ErrorBoundary from "../../components/common/errorBoundary";
import Modal from "../../components/common/modal";
import MyDocument from "./pdfDoc";
import { formatters } from "../../utils/viz";
import {
  eventNames,
  sendClickedDownloadResults,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

const REPORT_VERSION_V2 = "v2";

// modal that shows user info about their download
const PdfModal = ({
  closeUrl,
  microbiome,
  contextLanguage,
  date,
  test,
  communityType,
  resultPDF,
}) => {
  const [loadingPDF, setLoadingPDF] = useState(false);

  const navigate = useNavigate();

  const [instance] = usePDF({
    document: (
      <MyDocument microbiome={microbiome} contextLanguage={contextLanguage} />
    ),
  });

  const resultsFilename = `evvy_results_${formatters.pdfDate(date)}.pdf`;

  const resultsDownloadAnalyticsArgs = {
    cst: communityType.name,
    testOrder: test.test_order,
    testHash: test.hash,
  };

  // PDF downloader that combines MDX PDF report with Evvy Results PDF
  const onDownloadResultsClick = async () => {
    setLoadingPDF(true);
    const resultsPDFUrl = resultPDF.url;
    const reportVersion = resultPDF.version;

    // only show Evvy intro page if there is no MDX PDF
    const showEvvyIntroPage = resultsPDFUrl ? false : true;

    // generate Evvy PDF
    const ogDoc = (
      <MyDocument
        microbiome={microbiome}
        contextLanguage={contextLanguage}
        showIntroPage={showEvvyIntroPage}
      />
    );
    const asPdf = pdf();
    asPdf.updateContainer(ogDoc);
    let evvyGeneratedPdfBlob = await asPdf.toBlob();

    // fetch MDX pdf
    const response = await fetch(resultsPDFUrl);
    let mdxPDFBlob = await response.blob();

    let merger = new PDFMerger();

    // if we have version 2 of the mdx report, then include this report and then add evvy report to the end
    if (reportVersion === REPORT_VERSION_V2) {
      await merger.add(mdxPDFBlob, null);
      await merger.add(evvyGeneratedPdfBlob, null);
    } else {
      // otherwise, this is our old PDF report so add the evvy report and just page 3 of the MDX report
      await merger.add(evvyGeneratedPdfBlob, null);
      await merger.add(mdxPDFBlob, ["3"]); // only add page 3
    }

    const mergedPdf = await merger.saveAsBlob();
    const url = window.URL.createObjectURL(mergedPdf);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", resultsFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoadingPDF(false);
    sendClickedDownloadResults({
      downloadType: "mdx",
      ...resultsDownloadAnalyticsArgs,
    });
  };

  return (
    <Modal
      widthClass="w-full md:w-4/12"
      closeModal={() => {
        navigate(closeUrl);
      }}
    >
      <ErrorBoundary>
        <div className="bg-white p-10">
          <div className="mb-12">
            <h2>Download Results</h2>
            <p>To show your medical provider</p>
          </div>

          <div>
            {/* if there is an MDX pdf */}
            {resultPDF?.url ? (
              <button
                disabled={loadingPDF}
                onClick={onDownloadResultsClick}
                className="cursor-pointer tracking-wider mx-auto w-full py-6 px-4 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
              >
                Download PDF
              </button>
            ) : (
              <a
                href={instance.url}
                disabled={instance.loading || !instance.url}
                download={resultsFilename}
              >
                <button
                  className="cursor-pointer tracking-wider mx-auto w-full py-6 px-4 font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
                  onClick={analyticsClickHandler({
                    eventName: eventNames.CLICKED_RESULTS_DOWNLOAD,
                    eventArgs: {
                      downloadType: "evvy",
                      ...resultsDownloadAnalyticsArgs,
                    },
                  })}
                >
                  {instance.loading || !instance.url
                    ? "Loading..."
                    : "Download PDF"}
                </button>
              </a>
            )}
          </div>
        </div>
      </ErrorBoundary>
    </Modal>
  );
};

export default PdfModal;
