import React, { useMemo, useState } from "react";

import OutlinedButton from "../../components/plan_unfurled/outlinedButton";
import { ReactComponent as Chat } from "../../components/plan_unfurled/icons/chat.svg";
import { ReactComponent as Coach } from "../../components/plan_unfurled/icons/coach.svg";

import { secondaryColors } from "../../utils/viz";
import {
  eventNames,
  sendCareExpandFAQ,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { calendlyURL } from "./constants.js";
import { GENERAL_COACHING_CALL_LINK } from "../../pages/constants";
import Markdown from "markdown-to-jsx";
import { useIntercom } from "react-use-intercom";
import { useFAQs } from "../../hooks/care/useFAQs";

// FAQs for the treatment plan
const CareFAQs = ({
  consult,
  analyticsEventArgs,
  faqsPage,
  aLaCareEligible,
  v0BundleEligible,
}) => {
  const { show } = useIntercom();
  const { faqs } = useFAQs(faqsPage);

  const careEligibilityStatus = useMemo(() => {
    if (aLaCareEligible && v0BundleEligible) return "both";
    if (aLaCareEligible) return "a-la-care";
    if (v0BundleEligible) return "v0-bundle";
    return "ineligible";
  }, [aLaCareEligible, v0BundleEligible]);

  const coachingLink =
    v0BundleEligible && consult?.status === "CP"
      ? calendlyURL
      : GENERAL_COACHING_CALL_LINK;

  // keep track of list of IDs that are expanded
  const [expandedIds, setExpandedIds] = useState([]);

  // handles expands/contracts question and triggers analytics event
  const toggleQuestion = (id) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((i) => i !== id));
    } else {
      setExpandedIds([...expandedIds, id]);

      const faqItem = faqs.find((faq) => faq.id === id);
      if (faqItem) {
        sendCareExpandFAQ({
          ...analyticsEventArgs,
          faqQuestion: faqItem.question,
        });
      }
    }
  };

  // renders with method b/c we do this in different places on web & mobile
  const renderActionButtons = () => {
    return (
      <React.Fragment>
        <div className="t3 medium">
          If you can’t find what you’re looking for, don’t hesitate to reach out
          to our support team:
        </div>

        <div className="mt-8 max-w-xs inline-block space-y-4">
          <OutlinedButton
            icon={
              <Chat
                stroke={secondaryColors["evvy-pine"]}
                width="100%"
                height="100%"
              />
            }
            text="Ask us a question"
            color="evvy-pine"
            onClick={analyticsClickHandler({
              eventName: eventNames.CARE_ASK_QUESTION,
              eventArgs: analyticsEventArgs,
              clickHandler: () => show(),
            })}
          />
          <div>
            <OutlinedButton
              href={coachingLink}
              icon={
                <Coach
                  stroke={secondaryColors["evvy-pine"]}
                  width="100%"
                  height="100%"
                />
              }
              text="Contact your coach"
              color="evvy-pine"
              onClick={analyticsClickHandler({
                eventName: eventNames.CARE_CLICKED_TALK_TO_EXPERT,
                eventArgs: { ...analyticsEventArgs, location: "faq" },
              })}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  if (!faqs) return null;

  return (
    <div id="care-faqs" className="bg-white py-12 sm:py-20 px-4">
      <div className="max-w-6xl mx-auto sm:flex sm:space-x-12 px-3 sm:px-0">
        <div className="flex-1 sm:pr-8">
          <h2 className="text-4xl sm:text-5xl">
            Have questions?<br></br>We're here to help.
          </h2>
          <div className="hidden sm:block">{renderActionButtons()}</div>
        </div>

        <div className="flex-1 flex-shrink-0 mt-8 sm:mt-0">
          {faqs
            ?.filter((q) => q.care_eligibility.includes(careEligibilityStatus))
            .map((q) => (
              <div key={q.id} className="pb-8 mb-8 border-b">
                <div className="flex items-center">
                  <h5
                    className="t2 medium flex-grow cursor-pointer mb-0"
                    onClick={() => toggleQuestion(q.id)}
                  >
                    {q?.question}
                  </h5>

                  <div
                    className="ml-8 block border-2 rounded-full border-gray-300 bg-white cursor-pointer p-2 hover:text-evvy-blue"
                    onClick={() => toggleQuestion(q.id)}
                  >
                    {expandedIds.includes(q.id) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 12H6"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                {expandedIds.includes(q.id) && (
                  <div className="b1 mt-4">
                    <Markdown>{q.answer}</Markdown>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className="block sm:hidden">{renderActionButtons()}</div>
      </div>
    </div>
  );
};

export default CareFAQs;
