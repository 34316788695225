import { Field } from "formik";
import QuestionTitle from "./questionTitle";
import { cn } from "../../../utils/cn";
import { generateYearOptions } from "../../../utils/datetime";
import { SectionIntro } from "../../../types/test";
import { Question } from "../../../utils/healthHistory";

const TextFieldQuestion = ({
  questionTitle,
  type,
  selectYearOnly = false,
  className,
  startYear = 1950,
  endYear = new Date().getFullYear(),
  sectionIntro,
  value = "",
  currentQuestion,
}: {
  questionTitle: string;
  type: "date" | "text" | "number";
  selectYearOnly?: boolean;
  className?: string;
  startYear?: number;
  endYear?: number;
  sectionIntro?: SectionIntro;
  value: string;
  currentQuestion?: Question;
}) => {
  return (
    <>
      <QuestionTitle
        questionTitle={questionTitle}
        sectionIntro={sectionIntro}
        optional={currentQuestion?.optional}
      />
      <Field
        as={selectYearOnly && "select"}
        id={questionTitle}
        name={questionTitle}
        type={type}
        className={cn(
          "border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4",
          className
        )}
        placeholder="Your Answer"
        autoComplete="off"
        value={value}
      >
        {selectYearOnly ? (
          <>
            <option value="" label="Select year" />
            {generateYearOptions(startYear, endYear).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </>
        ) : null}
      </Field>
    </>
  );
};

export default TextFieldQuestion;
