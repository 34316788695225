import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
} from "../../utils/answerUtils";

const QualityOfLifeForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const qualityOfLifeQuestion = useRef(null);
  const lifeMostAffectedQuestion = useRef(null);

  const allQuestions = {
    related_diagnoses_quality_of_life: qualityOfLifeQuestion,
    quality_of_life_most_affected: lifeMostAffectedQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const populateInitialValues = () => {
    return {
      related_diagnoses_quality_of_life: prefillAnswer({
        questionName: "related_diagnoses_quality_of_life",
        healthContext,
        transformationFn: (ans) => String(ans),
      }),
      quality_of_life_most_affected: prefillMultiSelectAnswer({
        questionName: "quality_of_life_most_affected",
        healthContext,
      }),
    };
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "quality of life",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Quality of Life</h3>
        <div className="flex-1 leading-5">
          Vaginal health can be so much more than what's up, down there. We know
          that symptoms like burning, excessive discharge, itching, and overall
          pain can have a big impact on your quality of life and mental and
          physical wellbeing. From no big deal to unbearable, help us help you
          by sharing the degree to which your vaginal health is interfering with
          your day to day.
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions
          const requiredQuestions = ["related_diagnoses_quality_of_life"];
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          if (parseInt(values["related_diagnoses_quality_of_life"]) >= 6) {
            const requiredMultiselectQuestions = [
              "quality_of_life_most_affected",
            ];
            requiredMultiselectQuestions.forEach((key) => {
              if (!values[key].length) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
              }
            });
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            values["related_diagnoses_quality_of_life"] = parseInt(
              values["related_diagnoses_quality_of_life"]
            );
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("related_diagnoses_quality_of_life", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "related_diagnoses_quality_of_life",
                errors,
                apiErrors
              )}
              data-for="related_diagnoses_quality_of_life"
              ref={qualityOfLifeQuestion}
            >
              <ReactTooltip
                id="related_diagnoses_quality_of_life"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("related_diagnoses_quality_of_life")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p className="block sm:hidden">
                <div>1: Not at all</div>
                <div>10: Huge impact, I alter aspects of my life around it</div>
              </p>
              <div className="flex items-center flex-wrap">
                <div className="mr-4 hidden sm:block">Not at all</div>
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="flex flex-grow"
                >
                  {[...Array(11).keys()]
                    .filter((k) => k !== 0)
                    .map(
                      (
                        o,
                        i // range from 1 - 10
                      ) => (
                        <label
                          className="mx-0 md:mx-2 cursor-pointer text-center flex-1"
                          key={String(o)}
                        >
                          <div className="mb-1">{o}</div>
                          <Field
                            type="radio"
                            name="related_diagnoses_quality_of_life"
                            value={String(o)}
                            className={indexRadioStyles(i)}
                          />
                        </label>
                      )
                    )}
                </div>
                <div className="ml-4 hidden sm:block">
                  Huge impact,
                  <br />I alter aspects of
                  <br />
                  my life around it{" "}
                </div>
              </div>
            </div>

            {parseInt(values.related_diagnoses_quality_of_life) >= 6 ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("quality_of_life_most_affected", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "quality_of_life_most_affected",
                  errors,
                  apiErrors
                )}
                data-for="quality_of_life_most_affected"
                ref={lifeMostAffectedQuestion}
              >
                <ReactTooltip
                  id="quality_of_life_most_affected"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("quality_of_life_most_affected")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.quality_of_life_most_affected.map(
                    (o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="quality_of_life_most_affected"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QualityOfLifeForm;
