import {
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
  Test,
} from "../types/test";

type QuestionType =
  | "multiselect_dimensional"
  | "multiselect"
  | "singleselect"
  | "multitext"
  | "singletext"
  | "number"
  | "date"
  | "number_scale"
  | "multidates";

export type EducationalMomentType = {
  getText: (healthContextValues?: HealthContextFields) => string | undefined;
  getHeader?: (healthContextValues?: HealthContextFields) => string | undefined;
  style?: "headerSmall";
};

export type SectionIntro = {
  getText: (healthContextValues?: HealthContextFields) => string | undefined;
  getHeader?: (healthContextValues?: HealthContextFields) => string | undefined;
};

export type Question = {
  slug: keyof HealthContextFields; // URL identifier for the question
  showQuestion: (params: {
    healthContextValues?: HealthContextFields;
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
  }) => boolean;
  getInitialValue: (params: {
    healthContextValues: HealthContextFields;
    value: any;
  }) => any;
  handleValidate?: (params: {
    healthContextValues?: HealthContextFields;
  }) => boolean;
  getOptions?: () => any;
  answerType: QuestionType;
  options?: string[]; // used for multidimensional questions
  optional?: boolean;
};

export type Page = {
  slug: string; // unique URL identifier for the page
  section: string; // header for the page (used to group pages)
  showPage: (params: {
    healthContextValues?: HealthContextFields;
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
  }) => boolean; // whether the page should be shown given current values
  questions: Question[]; // all potential questions that can be shown on a page
  sectionIntro?: SectionIntro; // section intro (defined for first page of a section)
  intro?: SectionIntro; // optional section intro for the first page of a section
  educationalMoment?: EducationalMomentType; // optional educational moment
  showEducationalMoment?: (params: {
    healthContextValues?: HealthContextFields;
    relatedDiagnoses?: HealthContextRelatedDiagnosesFields;
  }) => boolean; // function to determine if the educational moment should be shown; if no function, always show
};

export const getHealthHistoryURL = (hash?: string, slug?: string) => {
  return `/tests/${hash}/health-history/${slug}`;
};

export const HEALTH_HISTORY_PAGES: Page[] = [
  // INTRO STATIC PAGES
  // ADDRESS PAGE
  {
    slug: "re-test",
    section: "Updates",
    showPage: () => true,
    sectionIntro: {
      getHeader: () => "Updates",
      getText: () => "Let's review changes since your last test",
    },
    questions: [
      {
        slug: "basics_hoping_to_learn",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_hoping_to_learn;
        },
        answerType: "singletext",
      },
      {
        slug: "basics_follow_recommendations",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues?.basics_follow_recommendations;
        },
        answerType: "singleselect",
      },
      {
        slug: "basics_follow_recommendations_details",
        showQuestion: ({ healthContextValues }) => {
          return healthContextValues?.basics_follow_recommendations === "YE";
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.basics_follow_recommendations_details;
        },
        answerType: "multitext",
      },
      {
        slug: "basics_retest_symptoms_changed",
        showQuestion: ({ healthContextValues }) => {
          return healthContextValues?.basics_follow_recommendations === "YE";
        },
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues?.basics_retest_symptoms_changed;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "post-care",
    section: "Updates",
    showPage: () => true,
    questions: [
      {
        slug: "post_care_symptom_change",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_symptom_change;
        },
        answerType: "singleselect",
      },
      {
        slug: "post_care_improved_symptoms",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_improved_symptoms;
        },
        answerType: "singleselect",
      },
      {
        slug: "post_care_worsened_symptoms",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.post_care_improved_symptoms;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "symptoms",
    section: "Symptoms",
    showPage: () => true,
    questions: [
      {
        slug: "symptoms_has_current",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_has_current;
        },
        answerType: "singleselect",
      },
      {
        slug: "symptoms_best_description",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_best_description;
        },
        answerType: "singleselect",
      },
      {
        slug: "symptoms_reason_for_test",
        showQuestion: () => true,
        getInitialValue: ({ healthContextValues }) => {
          return healthContextValues.symptoms_reason_for_test;
        },
        answerType: "singleselect",
      },
    ],
  },
  {
    slug: "symptoms-trigger",
    section: "Symptoms",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "current-symptoms",
    section: "Symptoms",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "discharge",
    section: "Discharge",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "discharge-details",
    section: "Discharge",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "diagnoses",
    section: "Related Diagnoses",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "treatments",
    section: "Treatments",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "treatment-details",
    section: "Treatments",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "treatment-response",
    section: "Treatments",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "sexual-activity",
    section: "Sexual Activity",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "sexual-activity-details",
    section: "Sexual Activity",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "main-problem",
    section: "How we can help",
    showPage: () => true,
    questions: [],
  },
  // TAKE SAMPLE HERE
  {
    slug: "demographics",
    section: "Demographics",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "demographics-gender",
    section: "Demographics",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "lifestyle",
    section: "Lifestyle",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "vaginal-health",
    section: "Related Diagnoses",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "related-diagnoses",
    section: "Related Diagnoses",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "autoimmune",
    section: "Related Diagnoses",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "fertility",
    section: "Related Diagnoses",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "cancer-other",
    section: "Other Cancer",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "ovarian-cancer",
    section: "Ovarian Cancer",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "cervical-cancer",
    section: "Cervical Cancer",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "endometrial-cancer",
    section: "Endometrial Cancer",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "breast-cancer",
    section: "Breast Cancer",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "diabetes",
    section: "Diabetes",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "hpv",
    section: "HPV",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "cin",
    section: "Cervical Intra-Epithelial Neoplasia",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "endometriosis",
    section: "Endometriosis",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "pregnancy",
    section: "Pregnancy",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "currently-pregnant",
    section: "Pregnancy",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "pregnancy-history",
    section: "Pregnancy",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "hormonal",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "menopause",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "early-menopause",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "recent-period",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "contraception",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "hormonal-symptoms",
    section: "Hormonal Health",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "quality-of-life",
    section: "Quality of Life",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "why-evvy",
    section: "Why Evvy",
    showPage: () => true,
    questions: [],
  },
  {
    slug: "more-info",
    section: "How did you hear about us?",
    showPage: () => true,
    questions: [],
  },
  // Completed screens here
];

export const getNextPage = ({
  currentPage,
  healthContextValues,
}: {
  currentPage: Page;
  healthContextValues: HealthContextFields;
}): Page | undefined => {
  const currentIndex = HEALTH_HISTORY_PAGES.findIndex(
    (page) => page.slug === currentPage.slug
  );
  for (let i = currentIndex + 1; i < HEALTH_HISTORY_PAGES.length; i++) {
    const page = HEALTH_HISTORY_PAGES[i];
    if (page.showPage({ healthContextValues })) {
      return page;
    }
  }
};

export const getPreviousPage = ({
  currentPage,
  healthContextValues,
}: {
  currentPage: Page;
  healthContextValues: HealthContextFields;
}): Page | undefined => {
  const currentIndex = HEALTH_HISTORY_PAGES.findIndex(
    (page) => page.slug === currentPage.slug
  );
  for (let i = currentIndex - 1; i > 0; i--) {
    const page = HEALTH_HISTORY_PAGES[i];
    if (page.showPage({ healthContextValues })) {
      return page;
    }
  }
};

// used to decide which questions to render on each page--refresh when health context values refresh
export const getCurrentQuestions = ({
  questions,
  healthContextValues,
}: {
  questions: Question[];
  healthContextValues: HealthContextFields;
}) => {
  return questions.filter((question) =>
    question.showQuestion({ healthContextValues })
  );
};
