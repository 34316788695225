import { Test } from "./test";

export type Period = {
  id: string;
  start_date: string;
  end_date: string;
  days: number;
};

export enum PrescriptionTag {
  REDUCE_DISRUPTIVE = "Reduce Disruptive",
  PROMOTE_PROTECTIVE = "Promote Protective",
  MAINTENANCE = "Maintenance",
}

export type PrescriptionRecord = {
  uid: string;
  product: Prescription;
  deleted: boolean;
  is_refillable_product: boolean;
  refills_remaining: number;
  can_refill: boolean;
};

export type TreatmentFormFactor =
  | "pill"
  | "cream"
  | "suppository"
  | "applicator-cream";

export type TreatmentPurposeTag = "rebuild" | "relief" | "treat";
type PrescriptionType =
  | "antibiotic"
  | "antifungal"
  | "probiotic"
  | "soothe"
  | "boric_acid"
  | "hormone";

export type Prescription = {
  slug: string;
  type: PrescriptionType;
  ecomm_product: EcommProduct;
  title: string;
  title_short_display: string;
  description?: string;
  image_url: string;
  tag: PrescriptionTag;
  dose_text?: string;
  quantity_text?: string;
  subtitle_text?: string;
  instructions?: string;
  form_factor?: TreatmentFormFactor;
  ingredients?: string;
  pre_purchase_disclaimer?: string;
  use_tag?: string;
  purpose_tag?: TreatmentPurposeTag;
  do_not_take_if_pregnant: boolean;
  warning?: string;
  reason_for_prescribing?: string;
  side_effects?: string;
  preview_title: string;
  preview_short_description: string;
  preview_long_description: string;
  sti_preventing_future_infections?: string;
  sti_management_of_partners?: string;
  research_rating?: ResearchRating;
  productcitation_set: ProductCitation[];
};

export type ProductCitation = {
  title: string;
  url: string;
};

export type EcommProduct = {
  name: string;
  price: number;
};

export type ResearchRating = {
  title: string;
  description: string;
  image: string;
};

// allowed adjust types are "remove", "cadence", "shorten", "delay"
export type TreatmentAdjustmentType =
  | "remove"
  | "cadence"
  | "shorten"
  | "delay"
  | "add";

export type CadenceOptionType =
  | "once-per-week"
  | "twice-per-week"
  | "three-times-per-week";

export type CalendarTreatment = {
  id: string;
  product: Prescription;
  allowed_adjustments: TreatmentAdjustmentType[];
  deleted: boolean;
  is_modified: boolean;
  dates: {
    start_date?: string;
    end_date?: string;
  };
};

export type TreatmentScheduleDay = {
  week: number;
  day: number;
  date?: string | null;
  period_id?: string | null;
  prescriptions: Prescription[];
};

export type TreatmentCalendar = {
  start_date?: string | null;
  current_treatment_day: { week: number; day: number };
  last_treatment_week: number;
  periods: Period[];
  treatment_schedule: TreatmentScheduleDay[];
  calendar_treatments: CalendarTreatment[];
};

export type TreatmentPlan = {
  calendar: TreatmentCalendar;
  consult: Consult;
  test: Test;
  prescriptions: Prescription[];
  shipping: any;
  purchase_type: PurchaseType;
};

export type CarePricingInfo = {
  discounted_total: number;
  full_total: number;
  telehealth_fee: number;
  prescription_services: number;
};

export type TreatmentPathway = {
  slug: string;
  type: PlanType;
  can_remove_antibiotics: boolean;
  pricing_info: CarePricingInfo;
  primary_treatment: "antibiotic" | "antifungal" | "probiotic" | "hormone";
  products: Prescription[];
};

export type ALaCareRecommendations = {
  a_la_care: {
    eligible: boolean;
    recommended_treatments: Prescription[];
    all_treatments: Prescription[];
  };
  v0_bundle: {
    eligible: boolean;
    eligible_treatment_pathways: TreatmentPathway[];
  };
};

export type Consult = {
  allows_new_messages: boolean;
  can_open_sti_treatment_consult: boolean;
  clinician_display_name: string;
  consult_paid: boolean;
  consult_pending: boolean;
  consultmessagethread: {
    has_unread_message: boolean;
    last_message_at: string;
  };
  diagnosis_text: string;
  error_details: string;
  error_reason: string;
  error_reason_display: string;
  get_status_display: string;
  has_recommended_treatment_plan: boolean;
  ineligible_reason: string;
  intake_complete: boolean;
  is_closed: boolean;
  physician_note: string;
  plan_type: PlanType;
  purchase_type: PurchaseType;
  prescription_tracking_link: string;
  prescriptions: Prescription[];
  ready_for_refills: boolean;
  referral_note: string;
  status: string;
  sti_positive: boolean;
  test_hash: string;
  test_pretty_hash: string;
  treatment_pathway: TreatmentPathway;
  type: "sti" | "vaginitis";
  uid: string;
  selected_treatments: Prescription[];
};

export type PlanType = "treatment" | "maintenance";
export type PurchaseType = "a-la-care" | "v0-bundle";

export type AddressInfo = {
  address_first_line: string;
  address_second_line: string;
  city: string;
  state_code: string;
  zip_code: string;
  phone_number: string;
};

export type ConsultIntake = {
  consult: Consult;
  terms_agreed_at: string;
  submitted_at: string;
  height: number;
  weight: number;
  past_health_history?: {
    demographics_height: number;
    demographics_weight: number;
    related_diagnoses: string[];
    related_diagnoses_other: string;
  };
  currently_pregnant?: string;
  has_hormone_based_pathway: boolean;
  active_estrogen_prescription?: string;
  medical_conditions?: string[];
  medical_conditions_other?: string;
  current_symptoms?: string[];
  post_period_symptoms?: string;
  severe_symptoms?: string;
  anything_else?: string;
  sti_intake_known_exposures?: string[];
  sti_intake_known_exposures_details?: string;
  sti_intake_allergies?: string[];
  sti_intake_allergies_other?: string;
  patient_selected_pharmacy?: PharmacyResultType;
  address_first_line: string;
  address_second_line: string;
  city: string;
  zip_code: string;
  state_code: string;
  phone_number: string;
  _options: {
    active_estrogen_prescription: string[];
    medical_conditions: string[];
    current_symptoms: string[];
    post_period_symptoms: string[];
    severe_symptoms: string[];
    currently_pregnant: string[];
    sti_intake_known_exposures: string[];
    sti_intake_allergies: string[];
  };
};

export type PharmacyResultType = {
  name: string;
  address: {
    line: string[];
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  telecom: {
    system: string;
    value: string;
  }[];
  identifier: {
    system: string;
    value: string;
  }[];
};

export type TreatmentPhase = {
  number: number;
  tag: PrescriptionTag;
  goal: string;
  bgClass: string;
};

export type FAQ = {
  question: string;
  answer: string;
  care_eligibility: string;
};
