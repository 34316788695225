import { useMemo, useRef, useState } from "react";
import {
  HealthContextFields,
  HealthContextOptions,
  HealthContextRelatedDiagnosesFields,
  isHealthContextRelatedDiagnosesField,
} from "../../../types/test";
import { Formik, Form, Field } from "formik";
import MultiselectQuestionMultidimension from "./multidimensionalQuestion";
import FormFooter from "./formFooter";
import {
  ALL_CANCERS,
  HEALTH_PROFILE_SECTIONS,
  Question,
  getGenericQuestionName,
} from "../../../utils/healthHistory";
import ReactTooltip from "react-tooltip";
import TextFieldQuestion from "./textField";
import SingleQuestion from "./singleQuestion";
import NumberScale from "./numberScaleQuestion";
import QuestionTitle from "./questionTitle";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
} from "../../../utils/answerUtils";
import { indexRadioStyles } from "../../../utils/colors";

const QuestionWrapper = ({
  currentQuestion,
  currentSection,
  submitCurrentPage,
  healthContext,
  isSubmitting,
  isLastPage,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  submitCurrentPage: ({
    data,
    extraDataItems,
    relatedDiagnoses,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    relatedDiagnoses: any;
    onError: any;
  }) => void;
  healthContext: HealthContextFields;
  currentQuestion: Question;
  currentSection: string;
  isSubmitting: boolean;
  isLastPage: boolean;
  hdyhauSurvey?: any;
  submitHdyhauSurvey?: any;
}) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({});
  const [
    unsubmittedMultidimensionalOptions,
    setUnsubmittedMultidimensionalOptions,
  ] = useState<Array<string>>([]);

  const questionTooltip = useRef(null);
  const question = currentQuestion.question;
  const questionAnswerType = currentQuestion.answerType;
  const questionOptions = currentQuestion.options;
  const questionOptionsType = currentQuestion.multiselectOptionType;

  const currentUser = useLoggedInUser();
  const birthYear = Number(currentUser?.identity?.birthday?.split("-")[0]);

  const getError = ({
    key,
    errors,
    apiErrors,
  }: {
    key: any;
    errors: any;
    apiErrors: any;
  }) => {
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key]) {
      return apiErrors[key][0];
    }
  };

  const filteredTreatmentResponseQuestions = useMemo(() => {
    const getTreatmentResponseKeyFromQuestion = (question: string) => {
      return question.replace("treatment_response_", "").replace("_", "-");
    };
    if (question === "treatment_response") {
      let prescriptionTreatments: string[] = [];
      const options = [
        "prescription_treatment_antibiotics_metro",
        "prescription_treatment_antibiotics_clinda",
        "prescription_treatment_antibiotics_not_listed",
        "prescription_treatment_antifungal",
        "prescription_treatment_hormone_therapy",
      ];
      options?.map((option) => {
        if (healthContext[option as keyof HealthContextFields] === "") {
          return false;
        } else {
          prescriptionTreatments.push(
            option.replace("prescription_treatment_", "").replace("_", "-")
          );
        }
      });

      const vaginalHealthProducts =
        healthContext?.["other_vaginal_health_products"] || [];
      const allTreatments = prescriptionTreatments.concat(
        vaginalHealthProducts
      );

      return questionOptions?.filter((option) => {
        const treatmentKey = getTreatmentResponseKeyFromQuestion(option);
        return allTreatments.includes(treatmentKey);
      });
    } else {
      return questionOptions;
    }
  }, [question, healthContext, questionOptions]);

  const renderQuestion = (
    values: { [key: string]: any },
    setFieldValue: any,
    handleChange: any
  ) => {
    if (question === "demographics_height") {
      return (
        <>
          <QuestionTitle questionTitle={question} />
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col">
              <label>Feet</label>
              <Field
                id="demographics_height_feet"
                name="demographics_height_feet"
                type="number"
                className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4 mr-4"
                placeholder="Your Answer"
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col sm:mt-0 mt-2">
              <label>Inches</label>
              <Field
                id="demographics_height_inches"
                name="demographics_height_inches"
                type="number"
                className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4"
                placeholder="Your Answer"
                autoComplete="off"
              />
            </div>
          </div>
        </>
      );
    } else if (question === "treatment_response") {
      return (
        <div>
          <p className="t3">
            How have your symptoms changed as a result of the prescription
            treatments or other vaginal products you've tried?
          </p>
          {filteredTreatmentResponseQuestions
            ?.filter((option) => !option.includes("none"))
            .map((option) => {
              return (
                <div>
                  <div className="mb-8">
                    <SingleQuestion
                      healthContext={healthContext}
                      question={option as keyof HealthContextOptions}
                      healthContextOptions={
                        healthContext._options[
                          question as keyof HealthContextOptions
                        ]
                      }
                      questionType={"singleselect"}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      values={values}
                      sectionIntro={currentQuestion.sectionIntro}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      );
    } else if (currentSection === "hdyhau") {
      return (
        <div>
          <div>
            <QuestionTitle questionTitle={"hdyhau"} />
            <div role="group" aria-labelledby="checkbox-group">
              {hdyhauSurvey?._options?.hdyhau.map((o: any, i: any) => (
                <label
                  className="block mb-3 cursor-pointer flex items-center"
                  key={o[0]}
                >
                  <Field
                    type="radio"
                    name="hdyhau"
                    value={o[0]}
                    className={indexRadioStyles(i)}
                  />
                  <span className="ml-2">{o[1]}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="mt-8">
            <QuestionTitle
              questionTitle={"when_heard_about_evvy"}
              optional={currentQuestion?.optional}
            />
            <div role="group" aria-labelledby="checkbox-group">
              {hdyhauSurvey?._options?.when_heard_about_evvy.map(
                (o: any, i: any) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="when_heard_about_evvy"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                )
              )}
            </div>
          </div>
          <div className="mt-8">
            <TextFieldQuestion
              currentQuestion={{
                question: "health_history_anything_else",
                answerType: "text",
                optional: true,
              }}
              questionTitle={"health_history_anything_else"}
              type={"text"}
              sectionIntro={currentQuestion.sectionIntro}
              value={values["health_history_anything_else"]}
            />
          </div>
        </div>
      );
    } else if (questionAnswerType === "multiselect_dimensional") {
      return (
        <MultiselectQuestionMultidimension
          question={question}
          options={questionOptions}
          optionsType={questionOptionsType}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          healthContext={healthContext}
          values={values}
          sectionIntro={currentQuestion.sectionIntro}
          setUnsubmittedOptions={setUnsubmittedMultidimensionalOptions}
        />
      );
    } else if (
      questionAnswerType === "singleselect" ||
      questionAnswerType === "multiselect"
    ) {
      return (
        <SingleQuestion
          healthContext={healthContext}
          currentQuestion={currentQuestion}
          question={question as keyof HealthContextOptions}
          healthContextOptions={
            healthContext._options[question as keyof HealthContextOptions]
          }
          questionType={questionAnswerType}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          values={values}
          sectionIntro={currentQuestion.sectionIntro}
        />
      );
    } else if (
      questionAnswerType === "text" ||
      questionAnswerType === "date" ||
      questionAnswerType === "number"
    ) {
      return (
        <TextFieldQuestion
          currentQuestion={currentQuestion}
          questionTitle={question}
          type={questionAnswerType}
          sectionIntro={currentQuestion.sectionIntro}
          value={values[question]}
        />
      );
    } else if (questionAnswerType === "number_scale") {
      return (
        <NumberScale
          questionTitle={question}
          sectionIntro={currentQuestion.sectionIntro}
        />
      );
    } else if (
      questionAnswerType === "multidates" &&
      Array.isArray(values[question])
    ) {
      // map through values to render multiple date fields
      return (values[question] as string[])?.map((value: string) => (
        <TextFieldQuestion
          key={value}
          type="date"
          questionTitle={`${question}_${value}`}
          startYear={birthYear}
          selectYearOnly
          className="mb-8 last:mb-0"
          value={values[`${question}_${value}`]}
        />
      ));
    }
  };

  const getRelatedDiagnosisMap = (
    relatedDiagnoses: HealthContextRelatedDiagnosesFields[]
  ) => {
    return relatedDiagnoses.reduce((acc, condition) => {
      const conditionKey = condition.condition;
      acc[conditionKey] = condition;
      return acc;
    }, {} as { [key: string]: HealthContextRelatedDiagnosesFields });
  };

  const currentRelatedDiagnosesMap = useMemo(() => {
    if (!healthContext.healthcontextrelateddiagnoses_set) return {};
    return getRelatedDiagnosisMap(
      healthContext.healthcontextrelateddiagnoses_set
    );
  }, [healthContext.healthcontextrelateddiagnoses_set]);

  const pastRelatedDiagnosesMap = useMemo(() => {
    if (!healthContext.past_health_history?.healthcontextrelateddiagnoses_set)
      return {};
    return getRelatedDiagnosisMap(
      healthContext.past_health_history?.healthcontextrelateddiagnoses_set
    );
  }, [healthContext.healthcontextrelateddiagnoses_set]);

  const currentRelatedDiagnosesArray = useMemo(() => {
    return Object.keys(currentRelatedDiagnosesMap).sort();
  }, [currentRelatedDiagnosesMap]);

  const pastRelatedDiagnosesArray = useMemo(() => {
    return Object.keys(pastRelatedDiagnosesMap).sort();
  }, [currentRelatedDiagnosesMap]);

  const getInitialQuestionValue = () => {
    const { question, options } = currentQuestion;

    // Prefill answers for health profile section
    if (HEALTH_PROFILE_SECTIONS.includes(currentSection)) {
      if (question === "pregnancy_status") {
        if (Array.isArray(healthContext["pregnancy_status"])) {
          return { pregnancy_status: healthContext["pregnancy_status"][0] };
        }
      }
      if (question === "related_diagnoses") {
        return {
          [question]:
            currentRelatedDiagnosesArray.length > 0
              ? currentRelatedDiagnosesArray
              : pastRelatedDiagnosesArray,
        };
      }
      if (!options && isHealthContextRelatedDiagnosesField(question)) {
        if (question === "diagnosis_year") {
          return {
            [question]: currentRelatedDiagnosesArray,
            ...currentRelatedDiagnosesArray.reduce((acc, condition: string) => {
              acc[`${question}_${condition}`] =
                currentRelatedDiagnosesMap[condition].diagnosis_year;
              return acc;
            }, {} as { [key: string]: any }),
          };
        }
        let relatedDiagnosesKey = question.split("_")[0];
        if (getGenericQuestionName(question).startsWith("cancer")) {
          // cancer follow-ups are formatted as "{cancerType}_{question}"
          const cancerType = question.split("_")[0];
          relatedDiagnosesKey = `cancer-${cancerType}`;
        } else if (question.endsWith("last_screening_date")) {
          const conditionType = question.split("_")[0];
          relatedDiagnosesKey = conditionType;
        }
        if (currentRelatedDiagnosesMap?.[relatedDiagnosesKey]) {
          let formattedQuestion: any = question;
          if (getGenericQuestionName(question).startsWith("cancer")) {
            formattedQuestion = getGenericQuestionName(question);
          }
          if (
            getGenericQuestionName(question).endsWith("last_screening_date")
          ) {
            formattedQuestion = getGenericQuestionName(question);
          }
          let currentAnswer =
            currentRelatedDiagnosesMap[relatedDiagnosesKey]?.[
              formattedQuestion as keyof HealthContextRelatedDiagnosesFields
            ];

          if (
            currentAnswer === "" ||
            (Array.isArray(currentAnswer) && currentAnswer?.length === 0)
          ) {
            if (pastRelatedDiagnosesMap?.[relatedDiagnosesKey]) {
              const pastAnswer =
                pastRelatedDiagnosesMap[relatedDiagnosesKey]?.[
                  question as keyof HealthContextRelatedDiagnosesFields
                ];
              return { [question]: pastAnswer };
            }
          }
          return { [question]: currentAnswer };
        } else {
          return {};
        }
      } else if (options && isHealthContextRelatedDiagnosesField(question)) {
        const optionsQuestion: { [key: string]: any } = {};
        options.forEach((option: any) => {
          const conditionType = question.split("_")[0];
          const currentRelatedDiagnosisOption =
            currentRelatedDiagnosesMap?.[conditionType]?.[
              option as keyof HealthContextRelatedDiagnosesFields
            ];
          const pastRelatedDiagnosisOption =
            currentRelatedDiagnosesMap?.[conditionType]?.[
              option as keyof HealthContextRelatedDiagnosesFields
            ];
          optionsQuestion[option] =
            currentRelatedDiagnosisOption || pastRelatedDiagnosisOption;
        });
        return optionsQuestion;
      }

      if (
        currentQuestion.answerType !== "multiselect" &&
        currentQuestion.answerType !== "multiselect_dimensional"
      ) {
        if (question === "demographics_height") {
          return {
            demographics_height_feet: prefillAnswer({
              questionName: "demographics_height",
              healthContext,
              transformationFn: (heightNumber) =>
                Math.floor(Number(heightNumber) / 12),
            }),
            demographics_height_inches: prefillAnswer({
              questionName: "demographics_height",
              healthContext,
              transformationFn: (heightNumber) => Number(heightNumber) % 12,
            }),
          };
        }
        if (Object.keys(healthContext).includes(question + "_other")) {
          return {
            [question]: prefillAnswer({
              questionName: question,
              healthContext,
            }),
            [question + "_other"]: prefillAnswer({
              questionName: question + "_other",
              healthContext,
            }),
          };
        }
        const answer = prefillAnswer({
          questionName: question,
          healthContext,
        });
        return { [question]: answer };
      } else if (currentQuestion.answerType === "multiselect") {
        if (
          Object.keys(healthContext).includes(question + "_other") &&
          healthContext[(question + "_other") as keyof HealthContextFields]
        ) {
          return {
            [question]: prefillMultiSelectAnswer({
              questionName: question,
              healthContext,
              answerOptions: options,
            }),
            [question + "_other"]: prefillMultiSelectAnswer({
              questionName: question + "_other",
              healthContext,
              answerOptions: options,
            }),
          };
        }
        const answer = prefillMultiSelectAnswer({
          questionName: question,
          healthContext,
          answerOptions: options,
        });
        return { [question]: answer };
      }
    }

    // Initial values for multiselect-dimensional questions
    if (options) {
      const optionsQuestion: { [key: string]: any } = {};
      options.forEach((option: any) => {
        optionsQuestion[option] =
          healthContext[option as keyof HealthContextFields];
      });
      return optionsQuestion;
    }

    // Initial values for extra data questions
    const healthContextKey = question as keyof HealthContextFields;
    if (currentQuestion.question === "other_vaginal_health_products") {
      var extraDataQuestions: any = [];
      healthContext?._options.other_vaginal_health_products
        ?.filter((o) => o[0] !== "none" && o[0] !== "other")
        .forEach((o) => {
          const key = `treatments__${o[0]}__brand_or_product`;
          const existingAnswer =
            healthContext.healthcontextextradata_set.filter(
              (e: any) => e.key === key
            )[0];
          extraDataQuestions.push({
            key: key,
            value: existingAnswer ? existingAnswer.value : "",
          });
        });

      return Object.assign(
        {
          other_vaginal_health_products:
            healthContext.other_vaginal_health_products || [],
          other_vaginal_health_products_other:
            healthContext.other_vaginal_health_products_other || "",
        },
        extraDataQuestions.reduce(
          (obj: any, item: any) =>
            Object.assign(obj, { [item.key]: item.value }),
          {}
        )
      );
    }
    const getSymptomDiagnoses = (vaginalDischarge?: string) => {
      switch (vaginalDischarge) {
        case "thin-grey":
          return "BV";
        case "white-chunky":
          return "YEAST";
        case "yellow-copious":
          return "AV";
        default:
          return undefined;
      }
    };

    const getVaginalDischargeColor = (diagnoses?: string) => {
      if (healthContext["symptoms_vaginal_discharge_color"] === "") {
        switch (diagnoses) {
          case "BV":
            return { symptoms_vaginal_discharge_color: "GY" }; // Gray
          case "YEAST":
            return { symptoms_vaginal_discharge_color: "WC" }; // White/cream/off-white
          case "AV":
            return { symptoms_vaginal_discharge_color: "YE" }; // Yellow
          default:
            return {
              symptoms_vaginal_discharge_color:
                healthContext["symptoms_vaginal_discharge_color"],
            };
        }
      } else {
        return {
          symptoms_vaginal_discharge_color:
            healthContext["symptoms_vaginal_discharge_color"],
        };
      }
    };

    const getVaginalDischargeConsistency = (diagnoses?: string) => {
      if (healthContext["symptoms_vaginal_discharge_consistency"] === "") {
        switch (diagnoses) {
          case "BV":
            return { symptoms_vaginal_discharge_consistency: "TH" }; // Thin/milky
          case "YEAST":
            return { symptoms_vaginal_discharge_consistency: "CH" }; // Chunky/cottage cheese
          case "AV":
            return { symptoms_vaginal_discharge_consistency: "TV" }; // Thick/viscous
          default:
            return {};
        }
      } else {
        return {
          symptoms_vaginal_discharge_consistency:
            healthContext["symptoms_vaginal_discharge_consistency"],
        };
      }
    };

    const getVaginalSmell = (diagnoses?: string) => {
      if (healthContext["symptoms_vaginal_smell"]?.length === 0) {
        if (diagnoses === "BV") {
          return { symptoms_vaginal_smell: ["FI"] }; // Fishy
        } else {
          return {};
        }
      } else {
        return {
          symptoms_vaginal_smell: healthContext["symptoms_vaginal_smell"],
        };
      }
    };

    // Prefilling values for symptoms questions (BV, AV, YI)
    if (Object.keys(healthContext).includes(healthContextKey)) {
      const diagnoses = getSymptomDiagnoses(healthContext["vaginal_discharge"]);

      if (healthContextKey === "symptoms_vaginal_discharge_color") {
        return getVaginalDischargeColor(diagnoses);
      } else if (
        healthContextKey === "symptoms_vaginal_discharge_consistency"
      ) {
        return getVaginalDischargeConsistency(diagnoses);
      } else if (healthContextKey === "symptoms_vaginal_smell") {
        return getVaginalSmell(diagnoses);
      }
      return { [healthContextKey]: healthContext[healthContextKey] };
    }
    return {};
  };

  const getAllQuestionsAnswered = (values: any) => {
    if (currentQuestion.optional) {
      return true;
    }
    if (currentQuestion.question === "treatment_response") {
      let allTreatmentsAnswered = true;
      filteredTreatmentResponseQuestions?.map((treatmentResponse) => {
        if (values[treatmentResponse] === "") {
          allTreatmentsAnswered = false;
        }
      });
      return allTreatmentsAnswered;
    }

    if (
      currentQuestion.answerType === "multiselect_dimensional" &&
      unsubmittedMultidimensionalOptions?.length > 0
    ) {
      let allMultidimensionalAnswered = true;
      unsubmittedMultidimensionalOptions?.map((option) => {
        if (values[option] === "" || values[option]?.length === 0) {
          allMultidimensionalAnswered = false;
        }
      });
      return allMultidimensionalAnswered;
    }

    if (
      !values ||
      !(
        Array.isArray(values[currentQuestion.question]) ||
        typeof values[currentQuestion.question] === "string"
      )
    ) {
      return true;
    }
    if (
      values[currentQuestion.question]?.includes("OT") ||
      values[currentQuestion.question]?.includes("other")
    ) {
      if (!values[currentQuestion.question + "_other"]) {
        return false;
      }
    }
    if (currentQuestion.question === "other_vaginal_health_products") {
      let hasEmpty = false;
      values["other_vaginal_health_products"]
        ?.filter((o: string) => o[0] !== "none" && o[0] !== "other")
        .filter((o: string) => {
          const key = `treatments__${o}__brand_or_product`;
          if (values[key] === "") {
            hasEmpty = true;
          }
        });
      return !hasEmpty;
    }
    return true;
  };

  const handleSubmit = (values: any) => {
    let extraDataItems: any;
    let relatedDiagnoses: any;

    if (
      values["other_vaginal_health_products"] &&
      values["other_vaginal_health_products"].filter(
        (product: string) => product !== "none" && product !== "other"
      )?.length > 0
    ) {
      extraDataItems = values["other_vaginal_health_products"]
        .filter((product: string) => product !== "none" && product !== "other")
        .map((product: string) => {
          return {
            key: `treatments__${product}__brand_or_product`,
            value: values[`treatments__${product}__brand_or_product`],
          };
        });
    }

    // For related diagnoses follow-up questions
    if (isHealthContextRelatedDiagnosesField(question)) {
      if (question === "diagnosis_year") {
        const { diagnosis_year, ...yearValues } = values;
        relatedDiagnoses = {
          field_updates: yearValues,
          updated_conditions: diagnosis_year,
        };
      } else {
        const diagnosesUpdates = values;
        const conditionsToUpdate = question.split("_")[0];
        relatedDiagnoses = {
          field_updates: diagnosesUpdates,
          updated_conditions: conditionsToUpdate,
        };
      }
      if (getGenericQuestionName(question).startsWith("cancer")) {
        const cancerType = question.split("_")[0];
        relatedDiagnoses = {
          field_updates: values,
          updated_conditions: [`cancer-${cancerType}`],
        };
      }
      if (question.endsWith("last_screening_date")) {
        const conditionType = question.split("_")[0];
        relatedDiagnoses = {
          field_updates: values,
          updated_conditions: [conditionType],
        };
      }
    } else if (question === "infertility_treatments") {
      relatedDiagnoses = {
        field_updates: values,
        updated_conditions: ["infertility"],
      };
    }

    // For related diagnoses initial questions
    if (question === "related_diagnoses") {
      relatedDiagnoses = {
        updated_conditions: values["related_diagnoses"],
      };
    }

    if (
      values["demographics_height_feet"] &&
      values["demographics_height_inches"] &&
      question === "demographics_height"
    ) {
      values["demographics_height"] =
        values["demographics_height_feet"] * 12 +
        values["demographics_height_inches"];
    }
    if (values["hpv_last_screening_date"]) {
      values["last_screening_date"] = values["hpv_last_screening_date"];
      delete values["hpv_last_screening_date"];
    }
    if (values["cin_last_screening_date"]) {
      values["last_screening_date"] = values["cin_last_screening_date"];
      delete values["cin_last_screening_date"];
    }

    ALL_CANCERS.map((cancerType) => {
      if (values[`${cancerType}_cancer_treatment`]) {
        values[`cancer_treatment`] = values[`${cancerType}_cancer_treatment`];
        delete values[`${cancerType}_cancer_treatment`];
      }
      if (values[`${cancerType}_cancer_treatment_other`]) {
        values[`cancer_treatment_other`] =
          values[`${cancerType}_cancer_treatment_other`];
        delete values[`${cancerType}_cancer_treatment_other`];
      }
      if (values[`${cancerType}_cancer_last_surgery_date`]) {
        values[`cancer_last_surgery_date`] =
          values[`${cancerType}_cancer_last_surgery_date`];
        delete values[`${cancerType}_cancer_last_surgery_date`];
      }
      if (values[`${cancerType}_cancer_last_treatment_date`]) {
        values[`cancer_last_treatment_date`] =
          values[`${cancerType}_cancer_last_treatment_date`];
        delete values[`${cancerType}_cancer_last_treatment_date`];
      }
      if (values[`${cancerType}_cancer_in_remission`]) {
        values[`cancer_in_remission`] =
          values[`${cancerType}_cancer_in_remission`];
        delete values[`${cancerType}_cancer_in_remission`];
      }
    });

    submitCurrentPage({
      data: relatedDiagnoses ? {} : values,
      extraDataItems,
      relatedDiagnoses,
      onError: (response: any) => {
        if (typeof response === "object") {
          setApiErrors(response);
          Object.keys(response).forEach(() => {
            if (questionTooltip.current) {
              ReactTooltip.show(questionTooltip.current);
            }
          });
        } else {
          setError(response || "Error saving health context");
        }
      },
    });
  };

  const handleValidate = (values: any) => {
    let errors: { [key: string]: string } = {};
    if (questionTooltip.current) {
      ReactTooltip.hide(questionTooltip.current);
    }

    if (currentQuestion.optional) {
      return;
    }

    if (question === "how_did_you_hear_about_evvy") {
      if (!values["hdyhau"]) {
        errors[question] = "Required";
      } else {
        return;
      }
    }

    if (question)
      if (question === "demographics_height") {
        if (
          values["demographics_height_feet"] === "" ||
          values["demographics_height_inches"] === ""
        ) {
          errors[currentQuestion.question] = "Required";
        }
        if (values["demographics_height_inches"] > 11) {
          errors[currentQuestion.question] = "Inches must be less than 12";
        }
        if (values["demographics_height_inches"] < 0) {
          errors[currentQuestion.question] = "Inches must be greater than 0";
        }
        const height =
          values["demographics_height_feet"] * 12 +
          values["demographics_height_inches"];
        if (height < 36 || height > 108) {
          errors[currentQuestion.question] = "Height out of range";
        }
      } else if (question === "diagnosis_year") {
        // map through values object and check that every value is filled out
        Object.values(values).forEach((value) => {
          if (!value || value === "") {
            errors[currentQuestion.question] = "Required";
          }
        });
      } else if (
        !currentQuestion.options &&
        (!values[currentQuestion.question] ||
          values[currentQuestion.question] === "" ||
          values[currentQuestion.question]?.length === 0) &&
        currentQuestion.question !== "diabetes_hba1c" &&
        currentQuestion.question !== "diabetes_last_hba1c_date"
      ) {
        errors[currentQuestion.question] = "Required";
      }

    // Show the tooltip if there are errors
    if (Object.keys(errors).length > 0 && questionTooltip.current) {
      ReactTooltip.show(questionTooltip.current);
    }
    return errors;
  };

  // don't render any forms until we've loaded up the health context
  if (!healthContext || !healthContext.healthcontextrelateddiagnoses_set)
    return null;

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialQuestionValue()}
      onSubmit={(values): any => {
        return handleSubmit(values);
      }}
      validate={(values): any => {
        return handleValidate(values);
      }}
      validateOnChange={question === "diagnosis_year" ? false : true}
      validateOnBlur={false}
    >
      {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
        <Form>
          {error && (
            <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
              {error}
            </div>
          )}
          <div
            className={`mt-8 mb-8 p-8 border rounded-lg bg-white ${
              getError({ key: currentQuestion.question, errors, apiErrors })
                ? "border-coral"
                : "border-transparent"
            }`}
            data-tip={getError({
              key: currentQuestion.question,
              errors,
              apiErrors,
            })}
            data-for={currentQuestion.question}
            ref={questionTooltip}
          >
            <ReactTooltip
              id={currentQuestion.question}
              effect="solid"
              place="left"
              type="error"
              backgroundColor="#FFA684"
              textColor="#11161A"
              className="rounded-xs py-20"
            />
            {renderQuestion(values, setFieldValue, handleChange)}
          </div>
          <FormFooter
            handleSubmit={handleSubmit}
            errors={errors}
            isSubmitting={isSubmitting}
            isLastPage={isLastPage}
            disabled={!getAllQuestionsAnswered(values)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default QuestionWrapper;
