import React from "react";
import {
  HEALTH_CONTEXT_SECTIONS,
  HEALTH_PROFILE_SECTIONS,
  getAllQuestionOrderLogic,
} from "../../utils/healthHistory";
import "./styles.css"; // Make sure to import your CSS file

const ProgressBar = ({
  currentSection,
  currentQuestion,
}: {
  currentSection?: string;
  currentQuestion?: string;
}) => {
  const questionsOrderLogic = getAllQuestionOrderLogic();
  const allQuestions = questionsOrderLogic.map(
    (branchingLogic) => branchingLogic.currentQuestion
  );
  const allSections = questionsOrderLogic.map(
    (branchingLogic) => branchingLogic.currentSection
  );

  if (!currentSection && !currentQuestion) {
    return null;
  }

  let progress = 0;
  if (currentQuestion !== "") {
    progress =
      (allQuestions.indexOf(currentQuestion) / allQuestions.length) * 100;
  } else if (currentSection) {
    progress = (allSections.indexOf(currentSection) / allSections.length) * 100;
  }

  const healthContextPercentage = 150;

  if (currentSection && HEALTH_CONTEXT_SECTIONS.includes(currentSection)) {
    progress =
      (allQuestions.indexOf(currentQuestion) / allQuestions.length) *
      healthContextPercentage;
  }

  if (currentSection === "sample_confirm") {
    progress = 50;
  }
  if (currentSection === "profile") {
    progress = 55;
  }

  if (currentSection && HEALTH_PROFILE_SECTIONS.includes(currentSection)) {
    progress =
      40 + (allQuestions.indexOf(currentQuestion) / allQuestions.length) * 40;
  }

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
