import React from "react";
import { Routes, Route } from "react-router-dom";
import HealthHistoryContextForm from "../../../components/healthHistoryV2/forms/healthHistoryContextForm";
import { AddressInfo, HealthContextFields, Test } from "../../../types/test";
import HealthHistoryVerify from "./screens/verify";
import HealthHistoryConfirmStart from "./screens/start";
import PersonalInformationForm from "../../../components/healthHistoryV2/forms/personalInformationForm";
import { Upgrade } from "../additional/upgrade";
import TakeSampleConfirm from "./screens/takeSampleConfirm";
import ProfileIntro from "./screens/profileIntro";
import AdditionalForm from "../../../components/healthHistory/additionalForm";
import HealthHistoryForm from "../../../components/healthHistoryV2/forms/healthHistoryForm";

const HealthHistory = ({
  healthContext,
  ldtEnabled,
  updateHealthContext,
  createHealthContext,
  recordConsent,
  test,
  addressInfo,
  updateAddressInfo,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  healthContext: HealthContextFields;
  ldtEnabled: boolean;
  createHealthContext: any;
  recordConsent: any;
  updateHealthContext: (
    hash: string,
    data: any,
    onSubmit: any,
    onError: any
  ) => void;
  test: Test;
  addressInfo: AddressInfo;
  updateAddressInfo: any;
  hdyhauSurvey: any;
  submitHdyhauSurvey: any;
}) => {
  return (
    <div>
      <Routes>
        <Route
          path="/start"
          element={<HealthHistoryConfirmStart test={test} />}
        />
        <Route
          path="/verify"
          element={
            <HealthHistoryVerify
              createHealthContext={createHealthContext}
              healthContext={healthContext}
              test={test}
              ldtEnabled={ldtEnabled}
              recordConsent={recordConsent}
            />
          }
        />
        <Route
          path="/personal-information"
          element={
            <PersonalInformationForm
              healthContext={healthContext}
              test={test}
              addressInfo={addressInfo}
              updateAddressInfo={updateAddressInfo}
              ldtEnabled={ldtEnabled}
            />
          }
        />
        <Route
          path="/sample-confirm"
          element={
            <TakeSampleConfirm test={test} healthContext={healthContext} />
          }
        />
        <Route
          path="/additional"
          element={<Upgrade healthContext={healthContext} />}
        />
        <Route path="/profile" element={<ProfileIntro />} />
        <Route
          path=":slug"
          element={
            <HealthHistoryForm
              test={test}
              healthContext={healthContext}
              updateHealthContext={updateHealthContext}
            />
          }
        />
        <Route
          path=":section/:page"
          element={
            <HealthHistoryContextForm
              updateHealthContext={updateHealthContext}
              healthContext={healthContext}
              test={test}
              ldtEnabled={ldtEnabled}
              hdyhauSurvey={hdyhauSurvey}
              submitHdyhauSurvey={submitHdyhauSurvey}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default HealthHistory;
