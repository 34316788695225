import { useState, useEffect } from "react";
import {
  Routes,
  NavLink,
  useParams,
  useLocation,
  Navigate,
  Route,
} from "react-router-dom";

import Layout from "../../components/layout";

import ContextWhy from "./context/why";
import ContextVerify from "./context/verify";
import ContextPage from "./context/page";
import HealthHistory from "./healthHistory";
import LDTLabelReminder from "./sample/stop";
import SampleTake from "./sample/take";
import HistoryInfo from "./history/info";
import HistoryPage from "./history/page";
import TestResponses from "./testResponses";

import { testsService } from "../../services/tests";
import { healthContextService } from "../../services/healthContext";
import { surveyService } from "../../services/surveys";

const TestDetail = () => {
  const [test, setTest] = useState(null); // needs to be populated
  const [loadingTest, setLoadingTest] = useState(true);
  const [healthContext, setHealthContext] = useState(null); // OK if is null, will create for first timers
  const [hdyhauSurvey, setHdyhauSurvey] = useState(null); // OK if is null, will create for first timers
  const [addressInfo, setAddressInfo] = useState(null);
  const [ldtEnabled, setLdtEnabled] = useState(false); // feature is enabled AND test is_ldt

  // route params
  const { testHash: hash } = useParams();
  const location = useLocation();

  // effects
  useEffect(() => {
    fetchTest(hash);
    fetchHealthContext(hash);
    fetchHdyhauSurvey();
    fetchAddressInfo();
  }, [hash]); // Fetches test upon page load & health context if it exists

  // actions
  const fetchTest = (hash, onSuccess) => {
    setLoadingTest(true);
    testsService.fetchTest(
      hash,
      (response) => {
        setTest(response.data);
        setLdtEnabled(response.data.is_ldt);
        setLoadingTest(false);
        if (onSuccess) {
          return onSuccess();
        }
      },
      (error) => {
        console.error(error);
        setLoadingTest(false);
      }
    );
  };

  // hdyhau survey is needed for last page of health profile
  const fetchHdyhauSurvey = () => {
    surveyService.getHdyhauSurvey(
      (response) => {
        setHdyhauSurvey(response.data);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // create hdyhau survey only if user answered first question!
  const submitHdyhauSurvey = (data, onSuccess, onError) => {
    const hdyhauAns = data.hdyhau;
    // only submit if hdyhau answer is filled out and
    // hdyhauSurvey was not previously created
    if (hdyhauAns && !hdyhauSurvey.uid) {
      surveyService.createHdyhauSurvey(
        data,
        (response) => {
          setHdyhauSurvey(response.data);
          if (onSuccess) {
            onSuccess(response.data);
          }
        },
        (error, response) => {
          if (onError) {
            onError(response);
          }
        }
      );
    }
  };

  // healthContext is needed for every ContextPage.
  const fetchHealthContext = (hash) => {
    healthContextService.getContext(
      hash,
      (response) => {
        setHealthContext(response.data);
      },
      (error) => {
        // totally valid scenario
      }
    );
  };

  // used from ContextVerify if one doesn't exist
  const createHealthContext = (hash, onSuccess, onError) => {
    if (healthContext) {
      console.error("context already exists");
      return;
    }

    healthContextService.createContext(
      hash,
      (response) => {
        setHealthContext(response.data);
        // refetch test with it's new health context
        fetchTest(hash, () => {
          if (onSuccess) {
            onSuccess();
          }
        });
      },
      (error) => {
        if (onError) {
          onError();
        }
      }
    );
  };

  const updateHealthContext = (hash, data, onSuccess, onError) => {
    // update it in the server, then the local state (in case user goes back to a previous form)
    healthContextService.updateContext(
      hash,
      data,
      (response) => {
        setHealthContext(Object.assign(healthContext, response.data));
        if (onSuccess) {
          onSuccess(response.data);
        }
      },
      (error, response) => {
        if (onError) {
          onError(response);
        }
      }
    );
  };

  // record consent to our terms of service for this test
  const recordConsent = (hash, onSuccess, onError) => {
    if (!ldtEnabled) {
      // skip if not enabled
      return;
    }
    testsService.recordConsent(
      hash,
      (response) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        if (onError) {
          onError();
        }
      }
    );
  };

  // fetch addres info
  const fetchAddressInfo = (onSuccess) => {
    testsService.fetchAddressInfo(
      hash,
      (response) => {
        setAddressInfo(response.data);
        if (onSuccess) {
          return onSuccess();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const updateAddressInfo = (data, onSuccess, onError) => {
    // update it in the server, then the local state (in case user goes back to a previous form)
    testsService.submitAddressInfo(
      hash,
      data,
      (response) => {
        setAddressInfo(response.data);
        if (onSuccess) {
          onSuccess(response.data);
        }
      },
      (error, response) => {
        if (onError) {
          onError(response);
        }
      }
    );
  };

  // responses page doesn't show the header
  const isResponsesPage = location.pathname.includes("/responses/");

  return !loadingTest &&
    test &&
    location.pathname.includes("/health-history") ? (
    <Routes>
      <Route
        path="health-history/*"
        element={
          <HealthHistory
            test={test}
            healthContext={healthContext}
            ldtEnabled={ldtEnabled}
            addressInfo={addressInfo}
            updateAddressInfo={updateAddressInfo}
            updateHealthContext={updateHealthContext}
            createHealthContext={createHealthContext}
            recordConsent={recordConsent}
            hdyhauSurvey={hdyhauSurvey}
            submitHdyhauSurvey={submitHdyhauSurvey}
          />
        }
      />
    </Routes>
  ) : (
    <Layout title="Test">
      {!isResponsesPage && (
        <>
          <div className="sm:pb-10 mx-auto w-full hidden sm:flex items-center">
            <div className="flex-1 font-medium text-lg p-2 justify-center text-center">
              Test ID {test ? test.pretty_hash : ""}
            </div>
            <NavLink
              className="flex-1 flex items-center justify-center color-evvy-black opacity-50 p-2 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent"
              to={`/tests/${hash}/context/`}
              activeClassName="text-crazy-purple opacity-100"
            >
              <div
                className={`border rounded-full text-center mr-2 ${
                  location.pathname.includes("/context/")
                    ? "border-crazy-purple bg-crazy-purple text-white"
                    : "border-black"
                }`}
              >
                <div className="w-6 h-6 block leading-6">1</div>
              </div>
              <div>Current Health Context</div>
            </NavLink>
            <NavLink
              className="flex-1 flex items-center justify-center color-evvy-black opacity-50 p-2 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent"
              to={`/tests/${hash}/sample/`}
              activeClassName="text-coral opacity-100"
            >
              <div
                className={`border rounded-full text-center mr-2 ${
                  location.pathname.includes("/sample/")
                    ? "border-coral bg-coral text-white"
                    : "border-black"
                }`}
              >
                <div className="w-6 h-6 block leading-6">2</div>
              </div>
              <div>Take Your Sample</div>
            </NavLink>
            <NavLink
              className="flex-1 flex items-center justify-center color-evvy-black opacity-50 p-2 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent"
              to={`/tests/${hash}/history/`}
              activeClassName="text-dv-pond opacity-100"
            >
              <div
                className={`border rounded-full text-center mr-2 ${
                  location.pathname.includes("/history/")
                    ? "border-dv-pond bg-dv-pond text-white"
                    : "border-black"
                }`}
              >
                <div className="w-6 h-6 block leading-6">3</div>
              </div>
              <div>Health Profile</div>
            </NavLink>
          </div>

          <div className="block sm:hidden py-6 px-8 text-lg font-semibold uppercase tracking-wider">
            <div
              className={`flex items-center ${
                location.pathname.includes("/context/")
                  ? "block text-crazy-purple"
                  : "hidden"
              }`}
            >
              <div className="border rounded-full border-crazy-purple bg-crazy-purple text-white text-center mr-2">
                <div className="w-6 h-6 block leading-6">1</div>
              </div>
              <div>Current Health Context</div>
            </div>
            <div
              className={`flex items-center ${
                location.pathname.includes("/sample/")
                  ? "block text-coral"
                  : "hidden"
              }`}
            >
              <div className="border rounded-full border-coral bg-coral text-white text-center mr-2">
                <div className="w-6 h-6 block leading-6">2</div>
              </div>
              <div>Take Your Sample</div>
            </div>
            <div
              className={`flex items-center ${
                location.pathname.includes("/history/")
                  ? "block text-marine"
                  : "hidden"
              }`}
            >
              <div className="border rounded-full border-marine bg-marine text-white text-center mr-2">
                <div className="w-6 h-6 block leading-6">3</div>
              </div>
              <div>Health Profile</div>
            </div>
          </div>
        </>
      )}

      {!loadingTest && test && hdyhauSurvey && (
        <div className="max-w-full sm:max-w-4xl py-4 mx-auto px-8 sm:px-0">
          <Routes>
            <Route
              path="health-history/*"
              element={
                <HealthHistory
                  test={test}
                  healthContext={healthContext}
                  ldtEnabled={ldtEnabled}
                  addressInfo={addressInfo}
                  updateAddressInfo={updateAddressInfo}
                  updateHealthContext={updateHealthContext}
                  createHealthContext={createHealthContext}
                  recordConsent={recordConsent}
                  hdyhauSurvey={hdyhauSurvey}
                  submitHdyhauSurvey={submitHdyhauSurvey}
                />
              }
            />
          </Routes>

          <Routes>
            {/* health context info + form pages */}
            <Route
              path="context/*"
              element={
                <Routes>
                  <Route path="/" element={<ContextWhy test={test} />} />
                  <Route
                    path="verify/"
                    element={
                      <ContextVerify
                        createHealthContext={createHealthContext}
                        healthContext={healthContext}
                        test={test}
                        ldtEnabled={ldtEnabled}
                        recordConsent={recordConsent}
                      />
                    }
                  />
                  <Route
                    path=":page/"
                    element={
                      <ContextPage
                        healthContext={healthContext}
                        test={test}
                        updateHealthContext={updateHealthContext}
                        ldtEnabled={ldtEnabled}
                        addressInfo={addressInfo}
                        updateAddressInfo={updateAddressInfo}
                      />
                    }
                  />
                </Routes>
              }
            />
            {/* label tube reminder for LDTs */}
            <Route
              path="label/sample/"
              element={<LDTLabelReminder fetchTest={fetchTest} />}
            />

            {/* take sample */}
            <Route
              path="sample/"
              element={
                <SampleTake
                  healthContext={healthContext}
                  test={test}
                  updateHealthContext={updateHealthContext}
                  fetchTest={fetchTest}
                />
              }
            />

            {/* health history info + form pages */}
            <Route
              path="history/*"
              element={
                <Routes>
                  <Route
                    path="/"
                    element={
                      <HistoryInfo healthContext={healthContext} test={test} />
                    }
                  />
                  <Route
                    path="continue/"
                    element={
                      test?.expan_upsell_enabled ? (
                        <Navigate
                          to={`/tests/${hash}/additional/info/`}
                          replace
                        />
                      ) : (
                        <Navigate to={`/tests/${hash}/history/`} replace />
                      )
                    }
                  />
                  <Route
                    path=":page/"
                    element={
                      <HistoryPage
                        healthContext={healthContext}
                        test={test}
                        updateHealthContext={updateHealthContext}
                        hdyhauSurvey={hdyhauSurvey}
                        submitHdyhauSurvey={submitHdyhauSurvey}
                      />
                    }
                  />
                </Routes>
              }
            />

            {/* view responses after submitting */}
            <Route
              path="responses/"
              element={
                <TestResponses test={test} healthContext={healthContext} />
              }
            />

            {/* redirect on load */}
            <Route path="/" element={<Navigate to="context/" />} />
          </Routes>
        </div>
      )}
      {!loadingTest && !test && (
        <Navigate to={`/404/?from=${location.pathname}`} />
      )}
    </Layout>
  );
};

export default TestDetail;
