import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Markdown from "markdown-to-jsx";
import { eventNames } from "../../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../../utils/analytics/helpers";
import Header from "../../../../components/healthHistoryV2/questions/header";
import { getHealthHistoryURL } from "../../../../utils/healthHistory";
import { CircleCheckmark } from "../../../../components/common/circleCheckmark";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../../constants";

const INTRO_COPY =
  "We want to make sure it’s the right time to take your sample. Please confirm the following:";
const BULLETS = [
  "You’re not currently on your period or spotting",
  "You haven’t had sex in the past 24 hours",
  "You haven’t used any vulvar topical creams or gels for 24 hours (e.g. HRT, steriod cream)",
  "You haven’t used any vaginally-inserted treatments in the last 48 hours (e.g. suppositories, creams, gels, etc.)",
  "You haven't used antibiotics in the last 7 days",
];

const LDT_BULLETS = [
  "High fever (>101.9F/38.8C)",
  "Shortness of breath, difficulty breathing, chest pain, or heart palpitations",
  "Abnormal bruising or bleeding",
  "Extreme fatigue, dizziness, new weakness or paralysis, or pain",
  "Difficulty with speech or confusion",
  "Suicidal thoughts",
  "Inability to remain hydrated or keep down fluid",
];

const LDT_DISCLAIMER =
  "You are not currently experiencing any of the following:";

const TEST_LATER_COPY_MD =
  "If you’re experiencing any of the above, please take the test at another time.  No worries — we’ll keep your test activated in *My Tests* whenever you’re ready. ";

const HealthHistoryVerify: React.FC<{
  healthContext?: any;
  createHealthContext: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
  test: { hash: string; test_order: number };
  ldtEnabled: boolean;
  recordConsent: (
    hash?: string,
    success?: () => void,
    failure?: () => void
  ) => void;
}> = ({
  healthContext,
  createHealthContext,
  test,
  ldtEnabled,
  recordConsent,
}) => {
  const [laterModalOpen, setLaterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  const toGoHealthContext = () => {
    var nextPage = getHealthHistoryURL(
      hash,
      "symptoms",
      "symptoms_has_current"
    );
    if (ldtEnabled) {
      nextPage = getHealthHistoryURL(hash, "personal-information");
    }
    // user clicked continue. if health context exists, go for it. otherwise create then go
    if (healthContext) {
      // record consent timestamp
      recordConsent(hash);
      navigate(nextPage);
      window.scrollTo(0, 0);
    } else {
      setLoading(true);
      createHealthContext(
        hash,
        () => {
          // record consent timestamp
          recordConsent(hash);
          navigate(nextPage);
          window.scrollTo(0, 0);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  return (
    <div
      className="w-full text-center min-h-screen"
      style={{
        background:
          "linear-gradient(212deg, #D6D6FF 14.25%, #CEDEFC 63.21%, #D6D6FF 109.88%)",
      }}
    >
      <Header
        currentSection={"verify"}
        previousPage={{ section: "start" }}
        hash={hash}
      />
      <Helmet>
        <title>Test | Context | Verify</title>
      </Helmet>
      <h3 className="text-center px-4 pt-8">What to expect</h3>
      <div className="text-left font-medium max-w-full w-11/12 md:w-5/12 sm:px-0 mx-auto mt-6 border rounded-lg bg-white">
        <div className="p-8 mx-auto">
          <p className="b2 semibold mb-0">{INTRO_COPY}</p>
          <br />
          <br />
          <ul className="list-none ml-0 flex-1">
            {BULLETS.map((bullet, i) => (
              <li key={i} className="flex mb-6">
                <span className="mt-0.5 mr-4">
                  <CircleCheckmark
                    circleClassName="fill-highlighter-mint"
                    checkmarkClassName="stroke-evvy-black"
                  />
                </span>
                {bullet}
              </li>
            ))}
            <li className="flex">
              <span className="mt-0.5 mr-4">
                <CircleCheckmark
                  circleClassName="fill-highlighter-mint"
                  checkmarkClassName="stroke-evvy-black"
                />
              </span>
              {LDT_DISCLAIMER}
            </li>
            <ul className="list-none ml-12 flex-1 font-normal">
              {LDT_BULLETS.map((bullet, i) => (
                <li key={i} className="flex mb-4">
                  • {bullet}
                </li>
              ))}
              <p>
                If you are experiencing any of these, please seek immediate
                medical attention or call 911.
              </p>
            </ul>
          </ul>
          <div className="my-8 bg-evvy-black opacity-20 h-px w-full" />
          <div className="text-left">
            <Markdown>{TEST_LATER_COPY_MD}</Markdown>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div className="p-8">
        <div
          className="cursor-pointer"
          onClick={analyticsClickHandler({
            eventName: eventNames.HEALTH_HISTORY_FORM_ACCEPT_SAMPLE_CRITERIA,
            eventArgs: { testOrder: test.test_order, testHash: test.hash },
            clickHandler: toGoHealthContext,
          })}
        >
          <button
            disabled={loading}
            className="tracking-wider py-6 px-10 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
          >
            I'm Ready →
          </button>
        </div>
        <div className="py-6">
          <Link
            to={"/tests/"}
            className="uppercase underline inline-block font-semibold tracking-wider text-sm cursor-pointer"
          >
            I'll Test Later
          </Link>
        </div>
        <div className="flex-1 hidden"></div>
        {ldtEnabled && (
          <div className="italic text-sm mx-auto mb-12">
            <div className="md:w-4/12 mx-auto">
              By clicking the above button, you agree to our{" "}
              <a
                href={PRIVACY_POLICY}
                target="_blank"
                className="hover:underline persistSize"
              >
                privacy policy
              </a>
              ,{" "}
              <a
                href={TERMS_OF_SERVICE}
                target="_blank"
                className="hover:underline persistSize"
              >
                terms of service
              </a>{" "}
              and{" "}
              <a
                href={TELEHEALTH_INFORMED_CONSENT}
                target="_blank"
                className="hover:underline persistSize"
              >
                telehealth informed consent
              </a>
              .
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HealthHistoryVerify;
