import { Page, Question } from "../../utils/healthHistoryV2";
import { HealthContextFields } from "../../types/test";
import QuestionV2 from "./questions/questionV2";
import { Form, Formik } from "formik";
import FormFooter from "./questions/formFooter";
import SectionIntroCard from "./sectionIntroCard";

const PageWrapper = ({
  page,
  questions,
  healthContext,
  submitPage,
}: {
  page: Page;
  questions: Question[];
  healthContext: HealthContextFields;
  submitPage: ({
    data,
    extraDataItems,
    relatedDiagnoses,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    relatedDiagnoses: any;
    onError: any;
  }) => void;
}) => {
  // Depending on their current health history answers, we show different questions
  const getCurrentQuestions = (values: any) => {
    const filteredQuestions = questions.filter((question) => {
      return question.showQuestion({ healthContextValues: values });
    });
    return filteredQuestions;
  };

  // Pre-populate with currently saved values on first render
  const getInitialValues = () => {
    const initialValues: Record<string, any> = {};
    questions.forEach((question) => {
      // if there's custom pre-population logic, prioritize that
      if (question.getInitialValue) {
        initialValues[question.slug] = question.getInitialValue({
          healthContextValues: healthContext,
          value: healthContext[question.slug as keyof HealthContextFields],
        });
      } else {
        // if not, just try to grab the value from the current health history
        initialValues[question.slug] =
          healthContext[question.slug as keyof HealthContextFields];
      }
    });
    return initialValues;
  };

  // Special validation for each question on the page
  const handleValidate = (values: any) => {
    const errors: Record<string, boolean> = {};
    questions.forEach((question) => {
      if (question.showQuestion({ healthContextValues: values })) {
        const value = values[question.slug];
        if (question.handleValidate) {
          const error = question.handleValidate({
            healthContextValues: healthContext,
          });
          if (error) {
            errors[question.slug] = error;
          }
        } else {
          // if there's no custom validation, just check if it's empty
          if (!value) {
            errors[question.slug] = true;
          }
        }
      }
    });
    return errors;
  };

  // Finally, update the health context
  const handleSubmit = (values: any) => {
    submitPage({
      data: values,
      extraDataItems: {},
      relatedDiagnoses: {},
      onError: {},
    });
  };

  return (
    <div>
      {/* Show section intro if there is one defined for the current page */}
      {page.sectionIntro && (
        <SectionIntroCard
          healthContext={healthContext}
          sectionIntro={page.sectionIntro}
        />
      )}
      {/* Section title */}
      <div className="mt-8 text-center rounded-t-lg h-full p-5 bg-evvy-dark-beige">
        <p className="t2 semibold p-0 m-0">{page.section}</p>
      </div>
      <div>
        {/* Show educational moment if it's a show for everyone (no show condition) */}
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validate={(values): any => {
            return handleValidate(values);
          }}
          onSubmit={(values): any => {
            return handleSubmit(values);
          }}
        >
          {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
            <Form>
              <div className={`mb-8 p-8 border rounded-b-lg bg-white`}>
                {getCurrentQuestions(values).map((question, index) => {
                  return (
                    <QuestionV2
                      key={index}
                      question={question}
                      values={values}
                      healthContext={healthContext}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                    />
                  );
                })}
              </div>
              <FormFooter handleSubmit={handleSubmit} errors={errors} />
            </Form>
          )}
        </Formik>
        {/* Show educational moment at bottom if there is a show condition */}
      </div>
    </div>
  );
};

export default PageWrapper;
