import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet";

import AlreadySubmitted from "../../../../components/healthContext/alreadySubmittedMessage";
import TestLaterModal from "../../../../components/sample/testLaterModal";

import { testsService } from "../../../../services/tests";
import {
  eventNames,
  sendViewedHealthHistorySection,
} from "../../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../../utils/analytics/helpers";
import { HealthContextFields, Test } from "../../../../types/test";
import Header from "../../../../components/healthHistoryV2/questions/header";
import {
  Page,
  getHealthHistoryURL,
  getPrevQuestionWithLogic,
} from "../../../../utils/healthHistory";
import ProgressBar from "../../../../components/healthHistoryV2/progressBar";

const TakeSampleConfirm = ({
  test,
  healthContext,
}: {
  test: Test;
  healthContext: HealthContextFields;
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>();
  const [laterModalOpen, setLaterModalOpen] = useState(false);

  // route params
  const { testHash: hash } = useParams();
  const navigate = useNavigate();

  const navigateToNextPage = () => {
    // navigate to additional upsell screen if feature is enabled and test is ldt and they haven't already paid
    if (test.is_ldt && !test.has_vpcr_test && test.expan_upsell_enabled) {
      navigate(getHealthHistoryURL(hash, "additional"));
    } else {
      // otherwise go to health history
      navigate(
        getHealthHistoryURL(hash, "demographics", "demographics_height")
      );
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    sendViewedHealthHistorySection({
      section: "sample",
      sectionStatus: "blank",
      testOrder: test.test_order,
      testHash: hash,
    });
  }, []); // Should always start at top of screen

  // don't render any sample until we've loaded up the health context
  if (!healthContext) return <></>;

  const confirmSection = "sample_confirm";
  const previousPage = getPrevQuestionWithLogic({
    section: confirmSection,
    healthContextValues: healthContext,
    test,
  });

  return (
    <div className="bg-evvy-cream min-h-screen">
      <Header
        currentSection={"sample-confirm"}
        previousPage={previousPage}
        hash={hash}
      />
      <ProgressBar currentSection={"sample_confirm"} />
      <Helmet>
        <title>Test | Sample</title>
      </Helmet>
      {healthContext && healthContext.health_history_submitted_at ? (
        <AlreadySubmitted />
      ) : (
        ""
      )}

      <Formik
        initialValues={{
          taken: healthContext && healthContext.sample_taken_at ? true : false,
        }}
        validate={(values) => {
          var errors = {};

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          // if the test is already submitted, just go to the health context
          if (test.healthcontext?.sample_taken_at) {
            navigateToNextPage();
            return;
          }

          // otherwise make the request
          setError(null);
          setLoading(true);
          testsService.submitSample(
            hash!,
            undefined,
            () => {
              setLoading(false);
              navigateToNextPage();
            },
            (error: any, response: any) => {
              setError(response);
              setLoading(false);
            }
          );
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form className="mb-0">
            <div className="max-w-full sm:max-w-4xl py-8 mx-auto px-8 sm:px-0">
              <div className="text-center">
                <h2>Time to test!</h2>
              </div>

              <div className="flex-1 flex flex-col font-medium bg-white p-8 rounded-lg mx-auto w-11/12 md:w-9/12">
                <p className="text-lg">
                  Grab your test kit and follow the instructions to take your
                  sample. Make sure to come back here to finish the activation
                  process!
                </p>
                <div className="mt-auto rounded-md bg-highlighter-yellow flex p-4 items-center">
                  <div role="group" aria-labelledby="checkbox-group">
                    <label className="block cursor-pointer" key={"taken"}>
                      <Field
                        type="checkbox"
                        name="taken"
                        onClick={analyticsClickHandler({
                          eventName:
                            eventNames.HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN,
                          eventArgs: {
                            testOrder: test.test_order,
                            testHash: hash,
                          },
                        })}
                        className={`border-none w-6 h-6 rounded-sm shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-evvy-blue opacity-100 bg-white active:bg-white checked:bg-evvy-blue active:bg-evvy-blue hover:bg-evvy-blue focus:bg-evvy-blue`}
                      />
                      <span className="ml-4">I've taken my sample</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="mb-6">
                <button
                  type="submit"
                  disabled={loading || !values.taken}
                  className="cursor-pointer w-full flex-1 tracking-wider py-6 px-10 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
                >
                  Continue →
                </button>
              </div>
              <div className="">
                <div
                  className="uppercase inline-block underline font-semibold tracking-wider uppercase cursor-pointer"
                  onClick={() => setLaterModalOpen(true)}
                >
                  I'll test later
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {laterModalOpen ? (
        <TestLaterModal closeModal={() => setLaterModalOpen(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default TakeSampleConfirm;
