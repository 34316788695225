import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";

import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import QuestionTitle from "../healthHistoryV2/questions/questionTitle";

const SymptomsForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null); // general error (str)
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const vaginalDischargeOverallQuestion = useRef(null);
  const vaginalSmellQuestion = useRef(null);
  const vaginalSmellOtherQuestion = useRef(null);
  const vaginalDischargeColorQuestion = useRef(null);
  const vaginalDischargeColorOtherQuestion = useRef(null);
  const vaginalDischargeConsistencyQuestion = useRef(null);
  const vaginalDischargeConsistencyOtherQuestion = useRef(null);
  const symptomsQuestion = useRef(null);

  const allQuestions = {
    vaginal_discharge: vaginalDischargeOverallQuestion,
    symptoms_vaginal_smell: vaginalSmellQuestion,
    symptoms_vaginal_smell_other: vaginalSmellOtherQuestion,
    symptoms_vaginal_discharge_color: vaginalDischargeColorQuestion,
    symptoms_vaginal_discharge_color_other: vaginalDischargeColorOtherQuestion,
    symptoms_vaginal_discharge_consistency: vaginalDischargeConsistencyQuestion,
    symptoms_vaginal_discharge_consistency_other:
      vaginalDischargeConsistencyOtherQuestion,
  };

  // treating this one a bit differently
  const symptomsQuestions = [
    [
      "symptoms_additional_excessive_discharge",
      getQuestionTitle("symptoms_additional_excessive_discharge"),
    ],
    [
      "symptoms_additional_odorous_discharge",
      getQuestionTitle("symptoms_additional_odorous_discharge"),
    ],
    [
      "symptoms_additional_vagina_pain",
      getQuestionTitle("symptoms_additional_vagina_pain"),
    ],
    [
      "symptoms_additional_vulva_pain",
      getQuestionTitle("symptoms_additional_vulva_pain"),
    ],
    [
      "symptoms_additional_vulva_redness",
      getQuestionTitle("symptoms_additional_vulva_redness"),
    ],
    [
      "symptoms_additional_vaginal_swelling",
      getQuestionTitle("symptoms_additional_vaginal_swelling"),
    ],
    [
      "symptoms_additional_external_itchiness",
      getQuestionTitle("symptoms_additional_external_itchiness"),
    ],
    [
      "symptoms_additional_internal_itchiness",
      getQuestionTitle("symptoms_additional_internal_itchiness"),
    ],
    [
      "symptoms_additional_dryness",
      getQuestionTitle("symptoms_additional_dryness"),
    ],
    [
      "symptoms_additional_burning_sensation",
      getQuestionTitle("symptoms_additional_burning_sensation"),
    ],
    [
      "symptoms_additional_pain_with_sex",
      getQuestionTitle("symptoms_additional_pain_with_sex"),
    ],
    [
      "symptoms_additional_pain_while_peeing",
      getQuestionTitle("symptoms_additional_pain_while_peeing"),
    ],
  ];
  var initialSymptomsValues = {};
  symptomsQuestions.forEach((q) => {
    initialSymptomsValues[q[0]] = healthContext[q[0]] || "NO"; // default to Not Experiencing
  });

  const infoTooltips = {
    symptoms_additional_vulva_pain:
      "Your vulva is everything on the outside that surrounds the opening to your vagina, including your clitorisis and labia.",
    symptoms_additional_vulva_redness:
      "Your vulva is everything on the outside that surrounds the opening to your vagina, including your clitorisis and labia.",
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "symptoms",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Symptoms</h3>
        <div className="flex-1 leading-5">
          If you think the word "moist" is cringe, wait until you hear the
          descriptions for vaginal smells/discharge. No one wishes there was
          better terminology than us. But these are key indicators that can
          directly correlate with what's going on down there. Bear with us!{" "}
        </div>
      </div>

      <Formik
        initialValues={Object.assign(
          {
            vaginal_discharge: healthContext.vaginal_discharge || "",
            // backend data type is multiselect (array of str), but always only has one value, so convert to single select
            symptoms_vaginal_smell: healthContext.symptoms_vaginal_smell.length
              ? healthContext.symptoms_vaginal_smell[0]
              : "",
            symptoms_vaginal_smell_other:
              healthContext.symptoms_vaginal_smell_other || "",
            symptoms_vaginal_discharge_color:
              healthContext.symptoms_vaginal_discharge_color || "",
            symptoms_vaginal_discharge_color_other:
              healthContext.symptoms_vaginal_discharge_color_other || "",
            symptoms_vaginal_discharge_consistency:
              healthContext.symptoms_vaginal_discharge_consistency || "",
            symptoms_vaginal_discharge_consistency_other:
              healthContext.symptoms_vaginal_discharge_consistency_other || "",
          },
          initialSymptomsValues
        )}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions (multi select, needs at least 1)
          const requiredQuestions = [
            "vaginal_discharge",
            "symptoms_vaginal_discharge_color",
            "symptoms_vaginal_discharge_consistency",
            "symptoms_vaginal_smell",
          ];
          requiredQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required "other" context
          const requiredOtherQuestions = [
            "symptoms_vaginal_discharge_color",
            "symptoms_vaginal_smell",
            "symptoms_vaginal_discharge_consistency",
          ];
          requiredOtherQuestions.forEach((key) => {
            if (values[key] === "OT" && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
            }
          });

          // handle the multiselect table differently each question is required, but we just have 1 tooltip for all of them
          ReactTooltip.hide(symptomsQuestion.current);
          symptomsQuestions.forEach((q) => {
            if (!values[q[0]]) {
              errors["symptoms_additional"] = true;
              ReactTooltip.show(symptomsQuestion.current); // manually show, without requiring hover
            }
          });

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) =>
                ReactTooltip.show(allQuestions[key].current)
              );
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("vaginal_discharge", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("vaginal_discharge", errors, apiErrors)}
              data-for="vaginal_discharge"
              ref={vaginalDischargeOverallQuestion}
            >
              <ReactTooltip
                id="vaginal_discharge"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <QuestionTitle questionTitle={"vaginal_discharge"} />
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.vaginal_discharge.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="vaginal_discharge"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("symptoms_vaginal_smell", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("symptoms_vaginal_smell", errors, apiErrors)}
              data-for="symptoms_vaginal_smell"
              ref={vaginalSmellQuestion}
            >
              <ReactTooltip
                id="symptoms_vaginal_smell"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("symptoms_vaginal_smell")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>
                Reminder: this is the current odor, at the time of taking your
                test
              </p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.symptoms_vaginal_smell.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="symptoms_vaginal_smell"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.symptoms_vaginal_smell.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("symptoms_vaginal_smell_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "symptoms_vaginal_smell_other",
                  errors,
                  apiErrors
                )}
                data-for="symptoms_vaginal_smell_other"
                ref={vaginalSmellOtherQuestion}
              >
                <ReactTooltip
                  id="symptoms_vaginal_smell_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("symptoms_vaginal_smell_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="symptoms_vaginal_smell_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("symptoms_vaginal_discharge_color", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "symptoms_vaginal_discharge_color",
                errors,
                apiErrors
              )}
              data-for="symptoms_vaginal_discharge_color"
              ref={vaginalDischargeColorQuestion}
            >
              <ReactTooltip
                id="symptoms_vaginal_discharge_color"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("symptoms_vaginal_discharge_color")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>
                Reminder: it's normal for discharge to fluctuate throughout the
                cycle! This is the current color, at the time of taking your
                test
              </p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.symptoms_vaginal_discharge_color.map(
                  (o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="symptoms_vaginal_discharge_color"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  )
                )}
              </div>
            </div>

            {values.symptoms_vaginal_discharge_color === "OT" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError(
                    "symptoms_vaginal_discharge_color_other",
                    errors,
                    apiErrors
                  )
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "symptoms_vaginal_discharge_color_other",
                  errors,
                  apiErrors
                )}
                data-for="symptoms_vaginal_discharge_color_other"
                ref={vaginalDischargeColorOtherQuestion}
              >
                <ReactTooltip
                  id="symptoms_vaginal_discharge_color_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("symptoms_vaginal_discharge_color_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="symptoms_vaginal_discharge_color_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError(
                  "symptoms_vaginal_discharge_consistency",
                  errors,
                  apiErrors
                )
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "symptoms_vaginal_discharge_consistency",
                errors,
                apiErrors
              )}
              data-for="symptoms_vaginal_discharge_consistency"
              ref={vaginalDischargeConsistencyQuestion}
            >
              <ReactTooltip
                id="symptoms_vaginal_discharge_consistency"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("symptoms_vaginal_discharge_consistency")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>
                Reminder: it's normal for discharge to fluctuate throughout the
                cycle! This is the current consistency, at the time of taking
                your test
              </p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.symptoms_vaginal_discharge_consistency.map(
                  (o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="symptoms_vaginal_discharge_consistency"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  )
                )}
              </div>
            </div>

            {values.symptoms_vaginal_discharge_consistency === "OT" ? (
              <div
                className={`p-4 border rounded-md ${
                  getError(
                    "symptoms_vaginal_discharge_consistency_other",
                    errors,
                    apiErrors
                  )
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "symptoms_vaginal_discharge_consistency_other",
                  errors,
                  apiErrors
                )}
                data-for="symptoms_vaginal_discharge_consistency_other"
                ref={vaginalDischargeConsistencyOtherQuestion}
              >
                <ReactTooltip
                  id="symptoms_vaginal_discharge_consistency_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle(
                    "symptoms_vaginal_discharge_consistency_other"
                  )}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="symptoms_vaginal_discharge_consistency_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border relative rounded-md ${
                getError("symptoms_additional", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={
                getError("symptoms_additional", errors, apiErrors)
                  ? "Please select one option for each"
                  : ""
              }
              data-for="symptoms_additional"
              ref={symptomsQuestion}
            >
              <ReactTooltip
                id="symptoms_additional"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />

              <h4 className="t1">
                {getQuestionTitle("symptoms_additional")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>

              <div className="max-w-full table-fixed p-0 overflow-x-auto">
                <ReactTooltip
                  id="symptoms_additional_helper"
                  effect="solid"
                  place="right"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <table className="table-fixed ">
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        className="w-24 bg-white sticky left-0"
                      ></th>
                      {healthContext._options.symptoms_additional.map((o) => (
                        <th className="w-28" key={o[0]}>
                          {o[1]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {symptomsQuestions.map((q, i) => (
                      <tr key={q[0]}>
                        <td className="sticky left-0 bg-white" colSpan="2">
                          <span>{q[1]}</span>
                          {infoTooltips[q[0]] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 opacity-75 hover:opacity-100 cursor-pointer inline ml-1 text-evvy-blue"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              data-tip={infoTooltips[q[0]]}
                              data-for="symptoms_additional_helper"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </td>
                        {healthContext._options.symptoms_additional.map((o) => (
                          <td key={o[0]}>
                            <Field
                              type="radio"
                              name={q[0]}
                              value={o[0]}
                              className={`h-5 w-5 rounded-full ${indexCheckboxStyles(
                                i
                              )}`}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SymptomsForm;
