import { AxiosError, AxiosResponse } from "axios";
import { patchRequest, postRequest, getRequest } from "../utils/axios";

function fetchTests(
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return getRequest(
    "/api/v1/user-tests/",
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchTest(
  hash: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return getRequest(
    `/api/v1/user-tests/${hash}/`,
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

async function fetchTestAsync(hash: string) {
  return getRequest(
    `/api/v1/user-tests/${hash}/`,
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError, response: AxiosResponse) => {
      throw error;
    }
  );
}

async function fetchCurrentTest() {
  const response = await getRequest(
    "/api/v1/user-tests/current/",
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError, response: AxiosResponse) => {
      console.error(error);
      if (error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  );
  return response;
}

async function fetchTestInitialResults(hash: string) {
  const response = await getRequest(
    `/api/v1/user-tests/${hash}/initial-results/`,
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError, response: AxiosResponse) => {
      console.error(error);
      throw error;
    }
  );
  return response;
}

function fetchLatestReadyTest(
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return getRequest(
    `/api/v1/user-tests/latest_ready/`,
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function attachTest(
  hash: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return postRequest(
    "/api/v1/user-tests/",
    { hash: hash },
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function authorizeReleaseToProvider(
  hash: string,
  authorize: boolean,
  onSuccess?: (response: AxiosResponse) => void,
  onError?: (error: AxiosError, response: AxiosResponse) => void
) {
  return postRequest(
    `/api/v1/user-tests/${hash}/provider-release/`,
    {
      provider_release: authorize,
    },
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function submitSample(
  hash: string,
  ph?: string,
  onSuccess?: (response: AxiosResponse) => void,
  onError?: (error: AxiosError, response: AxiosResponse) => void
) {
  return postRequest(
    `/api/v1/user-tests/${hash}/sample/`,
    { ph: ph },
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function submitHealthHistory(
  hash: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return postRequest(
    `/api/v1/user-tests/${hash}/submit/`,
    {},
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function recordConsent(
  hash: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return postRequest(
    `/api/v1/user-tests/${hash}/consent/`,
    {},
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function fetchAddressInfo(
  hash: string,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return getRequest(
    `/api/v1/lab-order-intake/${hash}/address/`,
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

function submitAddressInfo(
  hash: string,
  data: any,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError, response: AxiosResponse) => void
) {
  return patchRequest(
    `/api/v1/lab-order-intake/${hash}/`,
    data,
    (response: AxiosResponse) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
    (error: AxiosError, response: AxiosResponse) => {
      if (onError) {
        onError(error, response);
      }
    }
  );
}

const VPCR_ADD_ON = "vPCR-add-on";

async function asyncCreateTestCheckoutURL(testHash: string) {
  const response = await postRequest(
    `/api/v1/user-tests/${testHash}/checkout/`,
    { checkout_type: VPCR_ADD_ON },
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
}

const fetchTestProblemsSummary = async (testHash: string) => {
  const response = await getRequest(
    `/api/v1/user-tests/${testHash}/problems-summary/`,
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

const fetchTestALaCareRecommendations = async (testHash: string) => {
  const response = await getRequest(
    `/api/v1/user-tests/${testHash}/a-la-care-recommendations/`,
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const testsService = {
  fetchCurrentTest,
  fetchTestInitialResults,
  fetchTests,
  fetchTest,
  fetchTestALaCareRecommendations,
  fetchTestAsync,
  fetchLatestReadyTest,
  attachTest,
  recordConsent,
  submitSample,
  submitHealthHistory,
  fetchAddressInfo,
  fetchTestProblemsSummary,
  submitAddressInfo,
  authorizeReleaseToProvider,
  asyncCreateTestCheckoutURL,
};
