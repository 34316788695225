import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import { uncheckNoneChangeHandler } from "../../utils/answerUtils";

const SexForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const sexualActivityTimingQuestion = useRef(null);
  const sexualActivityQuestion = useRef(null);
  const sexualActivityCondomsQuestion = useRef(null);

  const allQuestions = {
    sexual_activity_timing: sexualActivityTimingQuestion,
    sexual_activity: sexualActivityQuestion,
    sexual_activity_condoms: sexualActivityCondomsQuestion,
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "sexual activity",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Sexual Activity</h3>
        <div className="flex-1 leading-5">
          Sex is one of the most - if not *the* most - impactful behavior that
          can alter the microbiome. Asking questions enables us to contextualize
          your results (because sex alters the vaginal microbiome!). However, we
          know this can be a sensitive topic, so we've added a "prefer not to
          say" option.{" "}
        </div>
      </div>

      <Formik
        initialValues={{
          sexual_activity_timing: healthContext.sexual_activity_timing || "",
          sexual_activity:
            (healthContext.sexual_activity.length &&
              healthContext.sexual_activity) ||
            [],
          sexual_activity_condoms: healthContext.sexual_activity_condoms || "",
        }}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // required questions
          const requiredQuestions = ["sexual_activity_timing"];
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // conditionally required (if sexually active in the past month/2 weeks/5 days)
          if (["TH", "FT", "FI"].includes(values["sexual_activity_timing"])) {
            if (!values["sexual_activity_condoms"]) {
              errors[`sexual_activity_condoms`] = "This is a required question";
              ReactTooltip.show(
                allQuestions[`sexual_activity_condoms`].current
              );
            }
            if (!values["sexual_activity"].length) {
              errors["sexual_activity"] = "This is a required question";
              ReactTooltip.show(allQuestions[`sexual_activity`].current);
            }
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("sexual_activity_timing", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("sexual_activity_timing", errors, apiErrors)}
              data-for="sexual_activity_timing"
              ref={sexualActivityTimingQuestion}
            >
              <ReactTooltip
                id="sexual_activity_timing"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("sexual_activity_timing")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.sexual_activity_timing.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="sexual_activity_timing"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {["TH", "FT", "FI"].includes(values.sexual_activity_timing) ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("sexual_activity_condoms", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "sexual_activity_condoms",
                  errors,
                  apiErrors
                )}
                data-for="sexual_activity_condoms"
                ref={sexualActivityCondomsQuestion}
              >
                <ReactTooltip
                  id="sexual_activity_condoms"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("sexual_activity_condoms")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.sexual_activity_condoms.map(
                    (o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="sexual_activity_condoms"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {["TH", "FT", "FI"].includes(values.sexual_activity_timing) ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("sexual_activity", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("sexual_activity", errors, apiErrors)}
                data-for="sexual_activity"
                ref={sexualActivityQuestion}
              >
                <ReactTooltip
                  id="sexual_activity"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("sexual_activity")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.sexual_activity.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="checkbox"
                        name="sexual_activity"
                        value={o[0]}
                        className={indexCheckboxStyles(i)}
                        onChange={uncheckNoneChangeHandler({
                          handleChange,
                          setFieldValue,
                          fieldName: "sexual_activity",
                          fieldValues: values.sexual_activity,
                          noneCode: "PR",
                        })}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SexForm;
