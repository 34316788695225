import { useNavigate } from "react-router-dom";
import { Consult } from "../../types/care";
import { Test } from "../../types/test";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import {
  getButtonCTATextForConsultation,
  handleCareIntroCTAClick,
} from "./utils";
import { CARE_CARD_SUBHEAD, CARE_DEFAULT_SUBTITLE } from "./constants";
import Markdown from "markdown-to-jsx";
import { TreatmentPathwayCards } from "./treatmentOptions/treatmentPathwayCards";
import { Bullets } from "../common/bullets";
import BlueRectangularButton from "../common/blueRectangularButton";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { Link } from "react-router-dom";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import ProblemsModule from "../tests/problemsModule";

import { CircleCheckmark } from "../common/circleCheckmark";
import classNames from "classnames";
// images

import CareIntroPackagingImg from "../../components/care/images/care-intro-packaging.png";
import { useProblemsSummary } from "../../hooks/care/useProblemsSummary";

const COPY_BY_PRICE = {
  249: {
    title: "Evvy Complete Treatment Program",
    description:
      "Get innovative and integrative care with a personalized prescription treatment program designed for you by an Evvy-affiliated provider. Can include treatments like antibiotics and antifungals, as well as prescription grade products like vaginally-inserted probiotics, boric acid, and more.",
    bullets: [
      "2 months of Rx treatment designed by a provider based on your unique results and health profile.",
      "Personalized treatment calendar & 1:1 onboarding call with a health coach",
      "Tailored to your vaginal microbiome and your preferences",
    ],
  },
  199: {
    title: "Evvy Complete Probiotics Program",
    description:
      "Get innovative and integrative care with a personalized prescription treatment program designed for you by an Evvy-affiliated provider. Can include prescription grade vaginally-inserted probiotics, boric acid, and more.",
    bullets: [
      "7 weeks of Rx treatment (such as prescription grade probiotics and boric acid) designed by a provider based on your unique results and health profile",
      "Personalized treatment calendar & 1:1 onboarding call with a health coach",
      "Tailored to your vaginal microbiome and your preferences",
    ],
  },
  149: {
    title: "Evvy Maintenance Program",
    description:
      "Get ongoing care with a personalized maintenance program designed for you by an Evvy-affiliated provider. Can include prescription grade products like vaginally-inserted probiotics, boric acid, and more.",
    bullets: [
      "3 months of Rx treatment (such as prescription grade probiotics and boric acid) designed by a provider based on your unique results and health profile",
      "Personalized treatment calendar",
      "Continuous, async support included",
    ],
  },
};

export const CareIntroProblems = ({
  consult,
  completedConsults,
  test,
  analyticsEventArgs,
  selectedPathwaySlug,
  setSelectedPathwaySlug,
}: {
  consult: Consult;
  completedConsults: Consult[];
  test: Test;
  analyticsEventArgs: any;
  selectedPathwaySlug: string;
  setSelectedPathwaySlug: (slug: string) => void;
}) => {
  const { testSummary: testProblemsSummary } = useProblemsSummary(test.hash);

  const navigate = useNavigate();
  const currentUser = useLoggedInUser();

  const firstTimeVaginitisCare =
    !currentUser.care.vaginitis.hasCompletedConsult;
  // if they've already paid, then show their selected treatment pathway as their only eligible treatment pathway
  const eligibleTreatmentPathways = consult?.consult_paid
    ? [consult.treatment_pathway]
    : test.eligible_treatment_pathways;
  const hasPastTreatments =
    consult?.status === "CP"
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );
  const showMultipleTreatmentPathways = eligibleTreatmentPathways.length > 1;

  // Determine copy & price states
  const ctaCopy = getButtonCTATextForConsultation(
    consult,
    firstTimeVaginitisCare
  );

  const currentPrice = {
    discountedTotal:
      eligibleTreatmentPathways[0]?.pricing_info.discounted_total,
    fullTotal: eligibleTreatmentPathways[0]?.pricing_info.full_total,
  };

  const singlePathwayCopy =
    COPY_BY_PRICE[currentPrice.discountedTotal as 149 | 199 | 249];

  const careDisclaimerCopy =
    "Evvy is not a provider. Your specific program will be determined at the sole discretion of an Evvy-affiliated provider based on your results, health profile, and clinical intake.";

  return (
    <>
      <div className="flex flex-col px-2">
        {/* Multiple treatment pathways only subhead */}
        <div className={classNames({ hidden: !showMultipleTreatmentPathways })}>
          <h2 className="hidden md:block md:mx-auto text-center mt-4">
            {CARE_CARD_SUBHEAD.default}
          </h2>
          <h3 className="block md:hidden text-center mt-4">
            {CARE_CARD_SUBHEAD.default}
          </h3>
          <div
            className={`mb-4 text-center px-6 font-medium leading-[24px] text-[14px] md:text-[18px]`}
          >
            {CARE_DEFAULT_SUBTITLE}
          </div>
        </div>
        <div className="flex flex-col rounded-xl overflow-hidden md:grid md:grid-cols-2 md:my-8 md:space-x-3">
          <div className={`h-full flex flex-col justify-center`}>
            {showMultipleTreatmentPathways ? (
              <div className="px-0 md:px-8 pt-4 md:pt-0">
                <TreatmentPathwayCards
                  treatmentPathways={eligibleTreatmentPathways}
                  selectedPathwaySlug={selectedPathwaySlug}
                  setSelectedPathwaySlug={setSelectedPathwaySlug}
                  analyticsArgs={{ testHash: test.hash, location: "care" }}
                  previousTreatmentPathway={
                    test.previous_test_care.previous_treatment_pathway
                  }
                />
                {/* disclaimer */}
                <div className="b3 medium pt-4">{careDisclaimerCopy}</div>
              </div>
            ) : (
              <div className="px-1 md:px-8 md:py-0 flex flex-col">
                <div className="flex flex-col gap-2 md:flex-row text-center md:text-left">
                  {/* header */}
                  <div className="text-left">
                    <CareLandingPageHeader
                      headerText={singlePathwayCopy.title}
                    />
                  </div>
                </div>

                <div className="md:hidden">
                  <img
                    src={CareIntroPackagingImg}
                    loading="lazy"
                    alt="Care packaging"
                    className="object-cover"
                  />
                </div>

                {/* price */}
                <div className="hidden md:block text-lg md:text-2xl semibold text-evvy-pine">{`$${currentPrice.discountedTotal}`}</div>

                {/* small description */}
                <div className="text-base mt-4">
                  <Markdown>{singlePathwayCopy.description}</Markdown>
                </div>

                <Bullets
                  bullets={singlePathwayCopy.bullets}
                  className={`mt-4 md:mt-6 mb-0 md:flex md:flex-col grow`}
                />

                {/* disclaimer */}
                <div className="b3 medium pt-3 pb-4">{careDisclaimerCopy}</div>

                {/* price mobile */}
                <div className="md:hidden text-xl semibold text-evvy-pine my-2">{`$${currentPrice.discountedTotal}`}</div>
              </div>
            )}

            <div className={`flex flex-col pb-4 md:px-8 grow mt-2 md:mt-6`}>
              <BlueRectangularButton
                text={ctaCopy}
                onClick={() => {
                  handleCareIntroCTAClick(
                    test,
                    consult,
                    false,
                    navigate,
                    ctaCopy,
                    "care",
                    selectedPathwaySlug
                  );
                }}
                fullWidth
              />
              <div className="b2 mt-4 md:mt-5 mb-3 md:mb-0 text-center caption">
                <div className="flex justify-center mb-2">
                  <div className="flex my-auto justify-center">
                    <CircleCheckmark
                      className="h-4 w-4"
                      circleClassName="stroke-marine stoke-3"
                      checkmarkClassName="stroke-marine"
                    />
                    <span className="ml-2">{"FSA/HSA Eligible"}</span>
                  </div>
                  <div className="flex my-auto justify-center ml-5">
                    <CircleCheckmark
                      className="h-4 w-4"
                      circleClassName="stroke-marine stoke-3"
                      checkmarkClassName="stroke-marine"
                    />
                    <span className="ml-2">{"Free Shipping"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {testProblemsSummary && (
            <div className="md:order-2 relative">
              <ProblemsModule
                testSummary={testProblemsSummary}
                showBackgroundContainer
              />
            </div>
          )}
        </div>
        {(hasPastTreatments || hasPastConsultMessages) && (
          <div className="my-4 text-center">
            {hasPastTreatments && (
              <Link
                to="/care/past-treatments/"
                className="linkedText underline caption medium border-b border-evvy-black cursor-pointer w-max p-pd mt-8"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS,
                  eventArgs: analyticsEventArgs,
                })}
              >
                View past treatment programs
              </Link>
            )}

            {hasPastTreatments && hasPastConsultMessages && (
              <span className="inline-block caption px-2">|</span>
            )}

            {hasPastConsultMessages && (
              <Link
                to="/care/consults/messages/"
                className="linkedText underline caption medium border-b border-evvy-black cursor-pointer w-max p-pd mt-8"
                onClick={analyticsClickHandler({
                  eventName: eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES,
                  eventArgs: analyticsEventArgs,
                })}
              >
                View past messages
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const CareLandingPageHeader = ({ headerText }: { headerText: string }) => {
  return (
    <>
      <h2 className="hidden md:block md:mx-auto">{headerText}</h2>
      <h3 className="block md:hidden mt-4">{headerText}</h3>
    </>
  );
};
