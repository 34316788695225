import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";

import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";

const DiagnosesForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null); // general error (str)
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const diagnosesQuestion = useRef(null);

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "diagnoses",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  // populate initial values
  const questions = [
    ["diagnoses_30day_bv", getQuestionTitle("diagnoses_30day_bv")],
    [
      "diagnoses_30day_yeast_infection",
      getQuestionTitle("diagnoses_30day_yeast_infection"),
    ],
    ["diagnoses_30day_uti", getQuestionTitle("diagnoses_30day_uti")],
    ["diagnoses_30day_av", getQuestionTitle("diagnoses_30day_av")],
    [
      "diagnoses_30day_ureaplasma",
      getQuestionTitle("diagnoses_30day_ureaplasma"),
    ],
    [
      "diagnoses_30day_mycoplasma",
      getQuestionTitle("diagnoses_30day_mycoplasma"),
    ],
    [
      "diagnoses_30day_cytolytic_vaginosis",
      getQuestionTitle("diagnoses_30day_cytolytic_vaginosis"),
    ],
    [
      "diagnoses_30day_vaginal_lactobacillosis",
      getQuestionTitle("diagnoses_30day_vaginal_lactobacillosis"),
    ],
    [
      "diagnoses_30day_chlamydia",
      getQuestionTitle("diagnoses_30day_chlamydia"),
    ],
    [
      "diagnoses_30day_trichomoniasis",
      getQuestionTitle("diagnoses_30day_trichomoniasis"),
    ],
    [
      "diagnoses_30day_gonorrhea",
      getQuestionTitle("diagnoses_30day_gonorrhea"),
    ],
  ];
  var initialValues = {};
  questions.forEach((q) => {
    initialValues[q[0]] = healthContext[q[0]] || "NO"; // default to No
  });

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Diagnoses</h3>
        <div className="flex-1 leading-5">
          Many vaginal infections are directly related to the state of your
          vaginal microbiome. Since some other medical issues can influence the
          microbiome, we want to check in on those too.{" "}
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          var errors = {};
          ReactTooltip.hide(diagnosesQuestion.current);

          // each question is required, but we just have 1 tooltip for all of them
          questions.forEach((q) => {
            if (!values[q[0]].length) {
              errors["diagnoses"] = true;
              ReactTooltip.show(diagnosesQuestion.current); // manually show, without requiring hover
            }
          });

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            if (typeof response === "object") {
              setApiErrors(response);
              ReactTooltip.show(diagnosesQuestion.current);
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("diagnoses", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={
                getError("diagnoses", errors, apiErrors)
                  ? "Please select at least one option for each"
                  : ""
              }
              data-for="diagnoses"
              ref={diagnosesQuestion}
            >
              <ReactTooltip
                id="diagnoses"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("diagnoses_30")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>
                You will be able to share history of infections beyond the past
                30 days later in this form!
              </p>

              <div className="max-w-full table-fixed p-0 overflow-x-auto">
                <table className="table-fixed">
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        className="w-36 sm:w-auto bg-white sticky left-0"
                      ></th>
                      {healthContext._options.diagnoses_30.map((o) => (
                        <th className="w-24 sm:w-auto" key={o[0]}>
                          {o[1]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((q, i) => (
                      <tr key={q[0]}>
                        <td colSpan="2" className="sticky left-0 bg-white">
                          {q[1]}
                        </td>
                        {healthContext._options.diagnoses_30.map((o) => (
                          <td key={o[0]}>
                            <Field
                              type="radio"
                              name={q[0]}
                              value={o[0]}
                              className={`h-5 w-5 rounded-full ${indexRadioStyles(
                                i
                              )}`}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DiagnosesForm;
