import React from "react";
import { HealthContextFields } from "../../types/test";
import { SectionIntro } from "../../utils/healthHistoryV2";

const SectionIntroCard = ({
  sectionIntro,
  healthContext,
}: {
  sectionIntro: SectionIntro;
  healthContext: HealthContextFields;
}) => {
  return (
    <div
      className={
        "w-full bg-cover bg-no-repeat sm:flex flex-auto text-center sm:text-left bg-evvy-white rounded-lg justify-center items-center p-4 sm:p-4"
      }
      style={{
        backgroundImage:
          "url('/images/graphics/education-moment-gradient.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <p className="t4 mb-0 block sm:hidden">
        {sectionIntro.getHeader?.(healthContext)}
      </p>
      <h6 className="text-left w-2/6 mr-12 mb-0 p-2 hidden sm:block">
        {sectionIntro.getHeader?.(healthContext)}
      </h6>
      <p
        className={
          "w-full sm:w-4/6 text-center sm:text-left b2 medium mb-0 mt-1"
        }
      >
        {sectionIntro?.getText(healthContext)}
      </p>
    </div>
  );
};

export default SectionIntroCard;
