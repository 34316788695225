import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// import HealthHistoryContext from "./healthHistory/context";
import AlreadySubmitted from "../../../components/healthContext/alreadySubmittedMessage";

import { sendViewedHealthHistorySection } from "../../../utils/analytics/customEventTracking";

const HistoryInfo = ({ healthContext, test }) => {
  // route params
  const { testHash: hash } = useParams();

  useEffect(() => {
    sendViewedHealthHistorySection({
      section: "profile",
      sectionStatus:
        healthContext && healthContext.past_health_history
          ? "prefilled"
          : "blank",
      testOrder: test.test_order,
      testHash: hash,
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Test | History | Info</title>
      </Helmet>
      {healthContext && healthContext.health_history_submitted_at ? (
        <AlreadySubmitted />
      ) : (
        ""
      )}

      <div className="sm:flex sm:h-60">
        <h2 className="flex-1 mr-4">
          Your Health{" "}
          <span className="bg-highlighter-mint rounded-sm px-1 -mx-1">
            Profile.
          </span>
        </h2>

        <div className="flex-1 font-medium mt-2">
          <p className="text-lg">
            The next set of questions enable our team of researchers and experts
            to find the best resources and recommendations for you.
          </p>
          <p className="text-lg">
            <b>Pro tip:</b> Once you’ve filled this out the first time, you’ll
            only need to verify or update your information for all future tests.
          </p>
        </div>
      </div>

      <div className="border-t border-dashed mt-10 py-10 flex">
        <div className="flex mx-auto w-full">
          <div className="w-full sm:w-auto mx-auto">
            <Link
              to={`/tests/${hash}/history/demographics/`}
              className="w-full sm:w-auto block mx-auto"
            >
              <button className="cursor-pointer tracking-wider w-full py-6 px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
                Great! Let's Go →
              </button>
            </Link>
            {/* show back button if they are able to see the expan upsell screen */}
            {test &&
              test.is_ldt &&
              !test.has_vpcr_test &&
              test.expan_upsell_enabled && (
                <div className="mx-auto flex justify-center my-5">
                  <Link
                    to={`/tests/${hash}/additional/info/`}
                    className="cursor-pointer tracking-wider font-semibold uppercase underline"
                  >
                    ← Back
                  </Link>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryInfo;
