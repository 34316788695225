import React, { useEffect, useMemo, useState } from "react";
import {
  HealthContextFields,
  HealthContextOptions,
  HealthContextRelatedDiagnosesOptions,
} from "../../../types/test";
import { Field } from "formik";
import {
  indexCheckboxStyles,
  multiselectFollowupRadioStyles,
  multiselectFollowupStyles,
} from "../../../utils/colors";
import { getQuestionTitle } from "../../../utils/questions";
import { UNSELECTED_OPTION } from "../../../utils/healthHistory";
import QuestionTitle from "./questionTitle";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import { SectionIntro } from "../../../types/test";

const MultiselectQuestionMultidimension = ({
  question,
  healthContext,
  handleChange,
  setFieldValue,
  options,
  optionsType,
  values,
  sectionIntro,
  setUnsubmittedOptions,
}: {
  question: string;
  healthContext: HealthContextFields;
  handleChange: () => void;
  setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void;
  options?: string[];
  optionsType?: string;
  values: {
    [key: string]: any;
  };
  sectionIntro?: SectionIntro;
  setUnsubmittedOptions?: (value: Array<string>) => void;
}) => {
  const [isRelatedDiagnosesModelQuestion, setIsRelatedDiagnosesModelQuestion] =
    useState(false);
  const relatedDiagnosesOptions =
    healthContext._related_diagnosis_options as HealthContextRelatedDiagnosesOptions;
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isMultiselectFollowupQuestion = optionsType === "multiselect";
  const [otherSelectedOption, setOtherSelectedOption] = useState<string>("");
  const [noneSelectedOption, setNoneSelectedOption] = useState<string>("");
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (selectedOptions?.some((value: string) => value.includes("_other"))) {
      const otherOption = selectedOptions.find((value: string) =>
        value.includes("_other")
      );
      setOtherSelectedOption(otherOption || "");
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (
      relatedDiagnosesOptions &&
      Object.keys(relatedDiagnosesOptions).includes(question)
    ) {
      setIsRelatedDiagnosesModelQuestion(true);
    } else setIsRelatedDiagnosesModelQuestion(false);
  }, [question, relatedDiagnosesOptions]);

  const currentOptions = useMemo(() => {
    if (isRelatedDiagnosesModelQuestion) {
      return relatedDiagnosesOptions?.[
        question as keyof HealthContextRelatedDiagnosesOptions
      ];
    }
    return healthContext._options[question as keyof HealthContextOptions];
  }, [
    healthContext._options,
    question,
    relatedDiagnosesOptions,
    isRelatedDiagnosesModelQuestion,
  ]);

  const noAnswer = question ? UNSELECTED_OPTION[question] : "";
  const followupOptions = currentOptions?.filter(
    (option) => option[1] !== noAnswer
  );

  // Toggle open the initial selected options based on the existing health context values
  useEffect(() => {
    if (isMultiselectFollowupQuestion) {
      setSelectedOptions(
        Object.keys(values).filter(
          (field) =>
            !(
              values[field as keyof HealthContextFields] as Array<string>
            ).includes(noAnswer) &&
            (values[field as keyof HealthContextFields] as Array<string>)
              .length !== 0
        )
      );
    } else {
      if (noneSelectedOption === "") {
        const selected = Object.keys(values).filter((field) => {
          const value = values[field as keyof HealthContextFields];
          if (Array.isArray(value)) {
            return value.length !== 0 && !value.includes(noAnswer);
          } else {
            return value !== noAnswer && !!value;
          }
        });
        setSelectedOptions([...selected, ...currentSelectedOptions]);
      } else if (noneSelectedOption !== "") {
        setSelectedOptions([noneSelectedOption]);
        for (const option of options || []) {
          if (!option.includes("_none")) {
            values[option] = noAnswer;
          }
        }
      }
    }
  }, [
    values,
    noneSelectedOption,
    isMultiselectFollowupQuestion,
    isRelatedDiagnosesModelQuestion,
    noAnswer,
    question,
  ]);

  const handleCheckboxChange =
    ({
      handleChange,
      setFieldValue,
      option,
    }: {
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      setFieldValue: (
        fieldName: string,
        defaultAnswer: string | string[]
      ) => void;
      option: string;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      if (!e.target.checked) {
        if (option?.includes("_other")) {
          setOtherSelectedOption("");
          setFieldValue(option, "");
        } else if (option?.includes("_none")) {
          setNoneSelectedOption("");
          setFieldValue(option, "");
        } else {
          setNoneSelectedOption("");
          setFieldValue(
            option,
            isMultiselectFollowupQuestion ? [noAnswer] : noAnswer
          );
          setCurrentSelectedOptions(
            currentSelectedOptions.filter((value) => value !== option)
          );
        }
      } else if (e.target.checked) {
        if (option?.includes("_other")) {
          setOtherSelectedOption(option);
          setFieldValue(option, "");
        } else if (option?.includes("_none")) {
          setNoneSelectedOption(option);
          setFieldValue(option, "NO");
          setCurrentSelectedOptions([]);
          setUnsubmittedOptions?.([]);
        } else {
          setNoneSelectedOption("");
          setFieldValue(option, []);
          setCurrentSelectedOptions([...currentSelectedOptions, option]);
          setUnsubmittedOptions?.([...currentSelectedOptions, option]);
        }
      }
    };

  const getFieldValue = (optionField: string) => {
    if (selectedOptions?.includes(optionField)) {
      return undefined;
    } else {
      return values[optionField as keyof HealthContextFields];
    }
  };

  const mainFieldChecked = (option: string) => {
    if (otherSelectedOption === option) {
      return otherSelectedOption;
    }
    // Make sure the "none" option unchecks any of the other fields
    if (option.includes("_none") && values[option] === "NO") {
      for (const option of options || []) {
        if (!option.includes("_none")) {
          if (values[option] !== noAnswer) {
            return false;
          }
        }
      }
      return option;
    }
    return selectedOptions?.includes(option);
  };

  return (
    <>
      <QuestionTitle
        questionTitle={question || ""}
        sectionIntro={sectionIntro}
      />
      <div role="group" aria-labelledby="checkbox-group">
        {followupOptions &&
          options?.map((option, index) => (
            <div key={option}>
              <label className="mb-2 cursor-pointer flex items-center">
                <Field
                  type="checkbox"
                  name={option}
                  value={getFieldValue(option)}
                  className={indexCheckboxStyles(index)}
                  onChange={handleCheckboxChange({
                    handleChange,
                    setFieldValue,
                    option,
                  })}
                  checked={mainFieldChecked(option)}
                />
                <span className="ml-2 leading-5">
                  {getQuestionTitle(option)}
                </span>
              </label>
              {/* Only render the follow-up options when option is selected */}
              {selectedOptions?.includes(option) &&
                !option.includes("none") && (
                  <div className="flex flex-col px-8 pb-6">
                    {followupOptions
                      ?.filter(
                        (followupOption) =>
                          followupOption[0] !== noAnswer &&
                          !option?.includes("_other")
                      )
                      .map((followupOption, index) => (
                        <div className="text-center" key={followupOption[0]}>
                          <Field
                            type={
                              isMultiselectFollowupQuestion
                                ? "checkbox"
                                : "radio"
                            }
                            id={`${option}-${followupOption[0]}`}
                            name={option}
                            value={followupOption[0]}
                            className={multiselectFollowupRadioStyles(index)}
                            onChange={uncheckNoneChangeHandler({
                              handleChange,
                              setFieldValue,
                              fieldName: question,
                              fieldValues: values[question],
                            })}
                            checked={
                              values[option]?.includes(followupOption[0]) ??
                              false
                            }
                          />
                          <label
                            htmlFor={`${option}-${followupOption[0]}`}
                            className={multiselectFollowupStyles(
                              index === 0,
                              question === "prescription_treatment"
                                ? index === followupOptions.length - 1
                                : index === followupOptions.length - 2
                            )}
                          >
                            {followupOption[1]}
                          </label>
                        </div>
                      ))}
                  </div>
                )}
            </div>
          ))}
      </div>
      {otherSelectedOption && question !== "prescription_treatment" && (
        <div className="mt-4">
          <QuestionTitle questionTitle={`${question}_other`} />
          <Field
            name={otherSelectedOption}
            as="input"
            className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
            placeholder="Your Answer"
            autoComplete="off"
            value={values[otherSelectedOption] || ""}
          />
        </div>
      )}
    </>
  );
};

export default MultiselectQuestionMultidimension;
