import {
  HealthContextFields,
  HealthContextRelatedDiagnosesOptions,
  SectionIntro,
  TupleArray,
} from "../../../types/test";
import { Field } from "formik";
import { indexRadioStyles, indexCheckboxStyles } from "../../../utils/colors";
import QuestionTitle from "./questionTitle";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import { useEffect, useMemo, useState } from "react";
import TextFieldQuestion from "./textField";
import { Question } from "../../../utils/healthHistory";

const SingleQuestion = ({
  healthContext,
  question,
  currentQuestion,
  healthContextOptions,
  questionType,
  handleChange,
  setFieldValue,
  values,
  sectionIntro,
}: {
  healthContext: HealthContextFields;
  question: string;
  healthContextOptions?: TupleArray<string>;
  questionType: "singleselect" | "multiselect";
  handleChange: () => void;
  setFieldValue: () => void;
  values: {
    [key: string]: any;
  };
  currentQuestion?: Question;
  sectionIntro?: SectionIntro;
}) => {
  const relatedDiagnosesOptions = healthContext._related_diagnosis_options;
  const [isRelatedDiagnosesModelQuestion, setIsRelatedDiagnosesModelQuestion] =
    useState(false);
  useEffect(() => {
    if (question.includes("cancer_treatment")) {
      setIsRelatedDiagnosesModelQuestion(true);
      return;
    }
    if (question.includes("cancer_in_remission")) {
      setIsRelatedDiagnosesModelQuestion(true);
      return;
    }
    if (
      relatedDiagnosesOptions &&
      Object.keys(relatedDiagnosesOptions)?.includes(question)
    ) {
      setIsRelatedDiagnosesModelQuestion(true);
    } else setIsRelatedDiagnosesModelQuestion(false);
  }, [question, relatedDiagnosesOptions]);

  const options = useMemo(() => {
    if (isRelatedDiagnosesModelQuestion) {
      if (question.includes("cancer_treatment")) {
        return relatedDiagnosesOptions?.["cancer_treatment"];
      }
      if (question.includes("cancer_in_remission")) {
        return relatedDiagnosesOptions?.["cancer_in_remission"];
      }
      return relatedDiagnosesOptions?.[
        question as keyof HealthContextRelatedDiagnosesOptions
      ];
    } else {
      return healthContextOptions;
    }
  }, [isRelatedDiagnosesModelQuestion, healthContextOptions, question]);

  const otherSelected = useMemo(() => {
    return (
      values?.[question] &&
      (values[question]?.includes("other") || values[question]?.includes("OT"))
    );
  }, [question, values]);

  const showFollowupsForTreament = useMemo(() => {
    if (question === "other_vaginal_health_products") {
      const filteredValues = values[question]?.filter(
        (val: string): boolean => val !== "other" && val !== "none"
      );
      return filteredValues;
    }
  }, [question, values]);

  return (
    <>
      <QuestionTitle
        questionTitle={question}
        sectionIntro={sectionIntro}
        optional={currentQuestion?.optional}
      />
      {questionType === "multiselect" && <p>Please select all that apply</p>}
      <div role="group" aria-labelledby="checkbox-group">
        {options?.map((option, index) => (
          <div
            key={option[0]}
            className="mb-2 cursor-pointer flex items-center"
          >
            <label className="cursor-pointer flex items-center mb-1">
              <Field
                type={questionType === "singleselect" ? "radio" : "checkbox"}
                name={question}
                value={option[0]}
                className={
                  questionType === "singleselect"
                    ? indexRadioStyles(index)
                    : indexCheckboxStyles(index)
                }
                onChange={
                  questionType === "multiselect"
                    ? uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: question,
                        fieldValues: values[question],
                      })
                    : handleChange
                }
              />
              <span className="ml-2 leading-5">{option[1]}</span>
            </label>
          </div>
        ))}
      </div>
      {otherSelected && (
        <div className="mt-8">
          <QuestionTitle questionTitle={`${question}_other`} />
          <Field
            name={`${question}_other`}
            as="input"
            className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
            placeholder="Your Answer"
            autoComplete="off"
            value={values[`${question}_other`] || ""}
          />
        </div>
      )}
      {showFollowupsForTreament?.map((product: string, index: number) => (
        <div className="mt-8" key={index}>
          <TextFieldQuestion
            questionTitle={`treatments__${product}__brand_or_product`}
            type="text"
            value={values[`treatments__${product}__brand_or_product`] || ""}
          />
        </div>
      ))}
    </>
  );
};

export default SingleQuestion;
