import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";

const AnythingElseForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const anythingElseQuestion = useRef(null);

  const allQuestions = {
    health_context_anything_else: anythingElseQuestion,
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "anything else",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Anything Else?</h3>
        <div className="flex-1"></div>
      </div>

      <Formik
        initialValues={{
          health_context_anything_else:
            healthContext.health_context_anything_else || "",
        }}
        validate={(values) => {
          var errors = {};
          // no requirements on this screen
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) =>
                ReactTooltip.show(allQuestions[key].current)
              );
            } else {
              setError(response || "Error saving health history");
            }
          });
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div className="mt-12 p-4">
              <h4 className="t1">
                {getQuestionTitle("health_context_anything_else")}
              </h4>
              <p>
                (For example, what you feel like triggers your symptoms /
                infections, any other medications / treatments you've tried,
                what you feel like has helped you, or anything else that can
                help our experts help you!)
              </p>
              <div>
                <Field
                  name="health_context_anything_else"
                  as="textarea"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AnythingElseForm;
