/**
 * A small component for plan step labels
 * @param {number} number number to be at center of circle (e.g. 1 for step 1)
 * @param {boolean} outlined determines whether circle should be filled or outlined (filled by default, active step is filled and inactive steps are outlined in modal)
 * @returns jsx of a circular number
 */

import React from "react";

const TimelineNumber = ({
  number,
  outlined = false,
  color = "bg-evvy-pine",
}) => (
  <div
    className={`flex items-center justify-center b1 semibold rounded-full flex-shrink-0
  ${
    outlined
      ? "text-evvy-pine bg-evvy-white border border-evvy-pine"
      : `text-evvy-white ${color}`
  } `}
    style={{ width: "40px", height: "40px" }}
  >
    {number}
  </div>
);

export default TimelineNumber;
