import { HealthContextFields, Test } from "../../../types/test";
import PageWrapper from "../page";
import { useParams, useNavigate } from "react-router-dom";
import {
  HEALTH_HISTORY_PAGES,
  getHealthHistoryURL,
  getNextPage,
  getPreviousPage,
} from "../../../utils/healthHistoryV2";
import { testsService } from "../../../services/tests";
import Header from "../questions/header";

const HealthHistoryForm = ({
  healthContext,
  test,
  updateHealthContext,
}: {
  healthContext: HealthContextFields;
  test: Test;
  updateHealthContext: (
    hash: string,
    data: any,
    onSubmit: any,
    onError: any
  ) => void;
}) => {
  const navigate = useNavigate();
  const { testHash: hash, slug } = useParams();
  const page = HEALTH_HISTORY_PAGES.find((page) => page.slug === slug);

  if (!page) {
    return <h1>null</h1>;
  }

  const handleUpdateHealthContext = (data: any, onError: any) => {
    if (hash) {
      updateHealthContext(
        hash,
        data,
        () => {
          const nextPage = getNextPage({
            currentPage: page,
            healthContextValues: healthContext,
          });
          if (nextPage) {
            const url = getHealthHistoryURL(hash, nextPage?.slug);
            navigate(url);
          } else {
            testsService.submitHealthHistory(
              test.hash,
              () => {
                navigate(`/tests/${hash}/done/`);
                window.scrollTo(0, 0);
              },
              (error: any, response: any) => {
                alert(response);
              }
            );
          }
        },
        onError
      );
    }
  };

  const submitCurrentPage = ({
    data,
    extraDataItems,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    onError: any;
  }) => {
    if (hash) {
      handleUpdateHealthContext(data, onError);
    }
  };

  return (
    <div className="bg-evvy-cream min-h-screen">
      <Header currentSection={""} previousPage={{ section: "" }} hash={hash} />
      <div className="max-w-full sm:max-w-4xl py-8 mx-auto px-8 sm:px-0">
        {page && (
          <PageWrapper
            page={page}
            healthContext={healthContext}
            submitPage={submitCurrentPage}
            questions={page.questions}
          />
        )}
      </div>
    </div>
  );
};

export default HealthHistoryForm;
