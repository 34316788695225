import {
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
  SectionIntro,
  Test,
} from "../types/test";
import { ProductCitation } from "../types/care";

type QuestionType =
  | "multiselect_dimensional"
  | "multiselect"
  | "singleselect"
  | "text"
  | "number"
  | "date"
  | "number_scale"
  | "multidates";

export type EducationalMomentType = {
  getText: (healthContextValues?: HealthContextFields) => string | undefined;
  getHeader?: (healthContextValues?: HealthContextFields) => string | undefined;
  citations?: ProductCitation[];
  style?: "headerSmall";
};

export type Question = {
  question: string;
  answerType: QuestionType;
  sectionIntro?: SectionIntro;
  educationalMoment?: EducationalMomentType;
  options?: string[]; // used for multidimensional questions
  multiselectOptionType?: QuestionType; // used for multidimensional questions
  optional?: boolean;
};

type QuestionBranchingLogic = {
  currentSection: string;
  currentQuestion?: string;
  // whether this question should be shown or not
  showCondition: (params: {
    healthContextValues: HealthContextFields;
    ldtEnabled?: boolean;
    relatedDiagnosesValues?: HealthContextRelatedDiagnosesFields[];
    hasPreviousTestWithoutCare?: boolean;
    hasPreviousTestWithCare?: boolean;
    hdyhauSurvey?: any;
  }) => boolean;
};

export const getHealthHistoryURL = (
  hash?: string,
  section?: string,
  question?: string
) => {
  const formattedSection = section?.replace(/_/g, "-");
  const formattedQuestion = question?.replace(/_/g, "-");
  return question
    ? `/tests/${hash}/health-history/${formattedSection}/${formattedQuestion}`
    : `/tests/${hash}/health-history/${formattedSection}`;
};

const answerIsNone = (
  values: HealthContextFields,
  question: keyof HealthContextFields,
  noneCode: string
) => {
  return (
    (values[question] &&
      values[question].length > 0 &&
      values[question].includes(noneCode)) ||
    values[question] === noneCode
  );
};

const checkIfDiagnosisExists = ({
  condition,
  relatedDiagnosesValues,
}: {
  condition: string;
  relatedDiagnosesValues?: HealthContextRelatedDiagnosesFields[];
}) => {
  return relatedDiagnosesValues?.some(
    (diagnosis) => diagnosis.condition === condition
  );
};

const getDiagnosisField = ({
  condition,
  relatedDiagnosesValues,
}: {
  condition: string;
  relatedDiagnosesValues?: HealthContextRelatedDiagnosesFields[];
}) => {
  return relatedDiagnosesValues?.find(
    (diagnosisObject) => diagnosisObject.condition === condition
  );
};

// Takes a shared question and removes the condition from it
// e.g. "breast_cancer_treatment" -> "cancer_treatment"
export const getGenericQuestionName = (question: string) => {
  return question.split("_").slice(1).join("_");
};

export const ALL_CANCERS = ["breast", "cervical", "endo", "ovarian", "other"];
const getCancerFollowUpQuestions = () => {
  const cancerFollowUps: QuestionBranchingLogic[] = [];
  ALL_CANCERS.map((cancerType) => {
    const followUps: QuestionBranchingLogic[] = [
      {
        currentSection: "related_diagnoses",
        currentQuestion: `${cancerType}_cancer_treatment`,
        showCondition: ({ relatedDiagnosesValues }) =>
          checkIfDiagnosisExists({
            relatedDiagnosesValues,
            condition: `cancer-${cancerType}`,
          }) || false,
      },
      {
        currentSection: "related_diagnoses",
        currentQuestion: `${cancerType}_cancer_last_surgery_date`,
        showCondition: ({ relatedDiagnosesValues }) => {
          const cancerTreatments = getDiagnosisField({
            relatedDiagnosesValues,
            condition: `cancer-${cancerType}`,
          })?.cancer_treatment;
          return cancerTreatments?.includes("surgery") || false;
        },
      },
      {
        currentSection: "related_diagnoses",
        currentQuestion: `${cancerType}_cancer_last_treatment_date`,
        showCondition: ({ relatedDiagnosesValues }) => {
          const cancerTreatments = getDiagnosisField({
            relatedDiagnosesValues,
            condition: `cancer-${cancerType}`,
          })?.cancer_treatment;
          return (
            cancerTreatments?.includes("surgery") ||
            cancerTreatments?.includes("chemotherapy") ||
            cancerTreatments?.includes("radiation") ||
            false
          );
        },
      },
      {
        currentSection: "related_diagnoses",
        currentQuestion: `${cancerType}_cancer_in_remission`,
        showCondition: ({ relatedDiagnosesValues }) => {
          const diagnosis_year = getDiagnosisField({
            relatedDiagnosesValues,
            condition: `cancer-${cancerType}`,
          })?.diagnosis_year;
          return (
            (diagnosis_year && diagnosis_year + 5 < new Date().getFullYear()) ||
            false
          );
        },
      },
    ];
    cancerFollowUps.push(...followUps);
  });
  return cancerFollowUps;
};

export const getAllQuestionOrderLogic = (): QuestionBranchingLogic[] => {
  const cancerFollowUps = getCancerFollowUpQuestions();
  return [
    {
      currentSection: "intro",
      currentQuestion: "",
      showCondition: () => true,
    },
    {
      currentSection: "verify",
      currentQuestion: "",
      showCondition: () => true,
    },
    {
      currentSection: "personal-information",
      currentQuestion: "",
      showCondition: () => true,
    },
    {
      currentSection: "symptoms",
      currentQuestion: "symptoms_has_current",
      showCondition: () => true,
    },
    {
      currentSection: "symptoms",
      currentQuestion: "symptoms_best_description",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.symptoms_has_current === "YE",
    },
    {
      currentSection: "symptoms",
      currentQuestion: "symptoms_reason_for_test",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.symptoms_has_current !== "YE",
    },
    {
      currentSection: "symptoms",
      currentQuestion: "symptoms_additional",
      showCondition: () => true,
    },
    {
      currentSection: "symptoms",
      currentQuestion: "symptoms_obvious_trigger",
      showCondition: ({ healthContextValues }) =>
        SYMPTOMS_QUESTIONS["symptoms_additional"].options?.some((option) => {
          return (
            healthContextValues[option as keyof HealthContextFields] &&
            healthContextValues[option as keyof HealthContextFields] !== "NO"
          );
        }) && healthContextValues.symptoms_has_current === "YE"
          ? true
          : false,
    },
    {
      currentSection: "discharge",
      currentQuestion: "vaginal_discharge",
      showCondition: () => true,
    },
    {
      currentSection: "discharge",
      currentQuestion: "symptoms_vaginal_discharge_color",
      showCondition: () => true,
    },
    {
      currentSection: "discharge",
      currentQuestion: "symptoms_vaginal_smell",
      showCondition: () => true,
    },
    {
      currentSection: "discharge",
      currentQuestion: "symptoms_vaginal_discharge_consistency",
      showCondition: () => true,
    },
    {
      currentSection: "diagnoses",
      currentQuestion: "diagnoses_30",
      showCondition: () => true,
    },
    {
      currentSection: "diagnoses",
      currentQuestion: "diagnoses_30day_bv_method",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.diagnoses_30day_bv === "YE",
    },
    {
      currentSection: "diagnoses",
      currentQuestion: "diagnoses_30day_yeast_infection_method",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.diagnoses_30day_yeast_infection === "YE",
    },
    {
      currentSection: "treatments",
      currentQuestion: "prescription_treatment",
      showCondition: () => true,
    },
    {
      currentSection: "treatments",
      currentQuestion: "other_vaginal_health_products",
      showCondition: () => true,
    },
    {
      currentSection: "treatments",
      currentQuestion: "treatment_response",
      showCondition: ({ healthContextValues }) =>
        !answerIsNone(
          healthContextValues,
          "other_vaginal_health_products",
          "none"
        ) ||
        healthContextValues.prescription_treatment_antibiotics_clinda !== "" ||
        healthContextValues.prescription_treatment_antibiotics_metro !== "" ||
        healthContextValues.prescription_treatment_antibiotics_clinda !== "" ||
        healthContextValues.prescription_treatment_antibiotics_not_listed !==
          "" ||
        healthContextValues.prescription_treatment_antifungal !== "" ||
        healthContextValues.prescription_treatment_hormone_therapy !== "" ||
        false,
    },
    {
      currentSection: "sexual_activity",
      currentQuestion: "sexual_activity_timing",
      showCondition: () => true,
    },
    {
      currentSection: "sexual_activity",
      currentQuestion: "sexual_activity_condoms",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.sexual_activity_timing == "TH" ||
        healthContextValues.sexual_activity_timing == "FI",
    },
    {
      currentSection: "sexual_activity",
      currentQuestion: "sexual_activity_partners",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.sexual_activity_timing !== "PR" &&
        healthContextValues.sexual_activity_timing !== "NE" &&
        healthContextValues.sexual_activity_timing !== "OM",
    },
    {
      currentSection: "sexual_activity",
      currentQuestion: "recent_sexual_activity",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.sexual_activity_timing !== "PR" &&
        healthContextValues.sexual_activity_timing !== "NE" &&
        healthContextValues.sexual_activity_timing !== "OM",
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_recent_period",
      showCondition: () => true,
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_last_period_date",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.basics_recent_period === "YE",
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_hoping_to_learn",
      showCondition: ({
        hasPreviousTestWithoutCare,
        hasPreviousTestWithCare,
      }) => hasPreviousTestWithCare || hasPreviousTestWithoutCare || false,
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_main_reason_for_test",
      showCondition: () => true,
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_follow_recommendations",
      showCondition: ({ hasPreviousTestWithoutCare }) =>
        hasPreviousTestWithoutCare || false,
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_follow_recommendations_details",
      showCondition: ({ healthContextValues, hasPreviousTestWithoutCare }) =>
        (healthContextValues.basics_follow_recommendations === "YE" &&
          hasPreviousTestWithoutCare) ||
        false,
    },
    {
      currentSection: "basics",
      currentQuestion: "basics_retest_symptoms_changed",
      showCondition: ({ healthContextValues, hasPreviousTestWithoutCare }) =>
        (healthContextValues.basics_follow_recommendations === "YE" &&
          hasPreviousTestWithoutCare) ||
        false,
    },
    {
      currentSection: "basics",
      currentQuestion: "post_care_symptom_change",
      showCondition: ({ hasPreviousTestWithCare }) =>
        hasPreviousTestWithCare || false,
    },
    {
      currentSection: "basics",
      currentQuestion: "post_care_improved_symptoms",
      showCondition: ({ healthContextValues, hasPreviousTestWithCare }) =>
        ((healthContextValues.post_care_symptom_change === "SB" ||
          healthContextValues.post_care_symptom_change === "WB") &&
          hasPreviousTestWithCare) ||
        false,
    },
    {
      currentSection: "basics",
      currentQuestion: "post_care_worsened_symptoms",
      showCondition: ({ healthContextValues, hasPreviousTestWithCare }) =>
        ((healthContextValues.post_care_symptom_change === "SW" ||
          healthContextValues.post_care_symptom_change === "WW") &&
          hasPreviousTestWithCare) ||
        false,
    },
    {
      currentSection: "basics",
      currentQuestion: "post_care_side_effects",
      showCondition: ({ hasPreviousTestWithCare }) =>
        hasPreviousTestWithCare || false,
    },
    {
      currentSection: "basics",
      currentQuestion: "post_care_followed_protocol",
      showCondition: ({ hasPreviousTestWithCare }) =>
        hasPreviousTestWithCare || false,
    },
    {
      currentSection: "sample_confirm",
      showCondition: () => true,
    },
    {
      currentSection: "profile",
      currentQuestion: "",
      showCondition: () => true,
    },
    {
      currentSection: "additional",
      currentQuestion: "",
      showCondition: ({ ldtEnabled }) => ldtEnabled || false,
    },
    {
      currentSection: "profile",
      currentQuestion: "",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_height",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_weight",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_race",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_hispanic_or_latino",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_born_with_vagina",
      showCondition: () => true,
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_gender_affirming_care",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.demographics_born_with_vagina === "NO",
    },
    {
      currentSection: "demographics",
      currentQuestion: "demographics_gender_identity",
      showCondition: () => true,
    },
    {
      currentSection: "lifestyle",
      currentQuestion: "lifestyle_smoking",
      showCondition: () => true,
    },
    {
      currentSection: "lifestyle",
      currentQuestion: "lifestyle_diet",
      showCondition: () => true,
    },
    {
      currentSection: "lifestyle",
      currentQuestion: "lifestyle_exercise",
      showCondition: () => true,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "diagnoses",
      showCondition: () => true,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "related_diagnoses",
      showCondition: () => true,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "diagnosis_year",
      showCondition: ({ relatedDiagnosesValues }) =>
        (relatedDiagnosesValues &&
          relatedDiagnosesValues?.length > 0 &&
          relatedDiagnosesValues[0]?.condition !== "none") ||
        false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "autoimmune_conditions",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "autoimmune",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "infertility_treatments",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "infertility",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "infertility_cause_identified",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "infertility",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "infertility_cause",
      showCondition: ({ relatedDiagnosesValues }) =>
        getDiagnosisField({
          relatedDiagnosesValues,
          condition: "infertility",
        })?.infertility_cause_identified === "yes" || false,
    },
    ...cancerFollowUps,
    {
      currentSection: "related_diagnoses",
      currentQuestion: "diabetes_type",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "diabetes",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "diabetes_hba1c",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "diabetes",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "diabetes_last_hba1c_date",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "diabetes",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "herpes_outbreak_location",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "herpes",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "hpv_last_screening_date",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "hpv",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "cin_type",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "cin",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "cin_last_screening_date",
      showCondition: ({ relatedDiagnosesValues }) =>
        checkIfDiagnosisExists({
          relatedDiagnosesValues,
          condition: "cin",
        }) || false,
    },
    {
      currentSection: "related_diagnoses",
      currentQuestion: "related_diagnoses_immunocompromised",
      showCondition: () => true,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_status",
      showCondition: () => true,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_breastfeeding",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.pregnancy_status?.includes("PP") || false,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_postpartum_timing",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.pregnancy_status?.includes("PP") || false,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_month",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.pregnancy_status?.includes("CU") || false,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_previously",
      showCondition: () => true,
    },
    {
      currentSection: "pregnancy",
      currentQuestion: "pregnancy_experienced",
      showCondition: () => true,
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_menopause",
      showCondition: () => true,
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_menopause_symptoms",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause !== "NO",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_menopause_hormones",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause !== "NO",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_menopause_hysterectomy",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause === "EA",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_menopause",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause_hysterectomy === "EA",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_period_counts",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.basics_recent_period === "YE",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_period_products",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.basics_recent_period === "YE",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "contraception_types_used",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause !== "EA" &&
        healthContextValues.hormonal_menopause !== "YE" &&
        healthContextValues.pregnancy_status !== "CU",
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "contraception_brand",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.contraception_types_used?.includes("PI") ||
        healthContextValues.contraception_types_used?.includes("PM") ||
        healthContextValues.contraception_types_used?.includes("IH") ||
        healthContextValues.contraception_types_used?.includes("OT") ||
        healthContextValues.contraception_types_used?.includes("IC") ||
        healthContextValues.contraception_types_used?.includes("IN") ||
        healthContextValues.contraception_types_used?.includes("IM") ||
        healthContextValues.contraception_types_used?.includes("PA") ||
        healthContextValues.contraception_types_used?.includes("TO") ||
        healthContextValues.contraception_types_used?.includes("RI") ||
        false,
    },
    {
      currentSection: "hormonal_health",
      currentQuestion: "hormonal_symptoms",
      showCondition: ({ healthContextValues }) =>
        healthContextValues.hormonal_menopause === "NO" &&
        healthContextValues.pregnancy_status !== "CU",
    },
    {
      currentSection: "quality_of_life",
      currentQuestion: "related_diagnoses_quality_of_life",
      showCondition: () => true,
    },
    {
      currentSection: "quality_of_life",
      currentQuestion: "quality_of_life_most_affected",
      showCondition: ({ healthContextValues }) =>
        (healthContextValues.related_diagnoses_quality_of_life &&
          healthContextValues.related_diagnoses_quality_of_life >= 6) ||
        false,
    },
    {
      currentSection: "hdyhau",
      currentQuestion: "how_did_you_hear_about_evvy",
      showCondition: ({ hdyhauSurvey }) => !hdyhauSurvey?.uid,
    },
  ];
};

// For the multi-dimensional questions, which option should represent the unselected state
export const UNSELECTED_OPTION: { [question: string]: string } = {
  symptoms_additional: "NO",
  diagnoses_30: "NO",
  diagnoses: "NE",
  infertility_treatments: "not-attempted",
  treatment_response: "",
  prescription_treatment: "",
};

export const MULTIDIMENSION_QUESTION_DEFAULTS: {
  [question: string]: string;
} = {
  symptoms_additional: "MI",
  diagnoses_30: "TH",
  diagnoses: "P2",
  infertility_treatments: "yes-successful",
  treatment_response: "same",
  prescription_treatment: "vaginal-symptom",
};

export type QuestionMap = { [question: string]: Question };

const SYMPTOMS_QUESTIONS: QuestionMap = {
  symptoms_has_current: {
    question: "symptoms_has_current",
    answerType: "singleselect",
    sectionIntro: {
      text: "We know how frustrating vaginal symptoms can be. Help us understand the context around yours so we can problem solve together.",
    },
  },
  symptoms_best_description: {
    question: "symptoms_best_description",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: (values) => {
        if (values?.symptoms_has_current === "YE") {
          return "You're not alone";
        }
      },
      getText: (values) => {
        if (values?.symptoms_has_current === "YE") {
          return "7 in 10 Evvy testers also have active symptoms they're trying to solve.";
        }
      },
    },
  },
  symptoms_reason_for_test: {
    question: "symptoms_reason_for_test",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: (values) => {
        if (values?.symptoms_has_current === "NO") {
          return "Way to go";
        }
      },
      getText: (values) => {
        if (values?.symptoms_has_current === "NO") {
          return "Great job being proactive with your vaginal health! Keeping an eye on your vaginal microbiome over time can ensure you're in a healthy state that helps to prevent future infections.";
        }
      },
      style: "headerSmall",
    },
  },
  symptoms_additional: {
    question: "symptoms_additional",
    answerType: "multiselect_dimensional",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "The term 'vulva' refers to external skin and labia, and the term 'vagina' refers only to the internal canal.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/censoring-vagina",
        },
      ],
    },
    options: [
      "symptoms_additional_none",
      "symptoms_additional_dryness",
      "symptoms_additional_excessive_discharge",
      "symptoms_additional_odorous_discharge",
      "symptoms_additional_vulva_pain",
      "symptoms_additional_vulvar_burning",
      "symptoms_additional_external_itchiness",
      "symptoms_additional_vaginal_burning_internal",
      "symptoms_additional_internal_itchiness",
      "symptoms_additional_pain_while_peeing",
      "symptoms_additional_frequent_urination",
      "symptoms_additional_pain_with_sex",
      "symptoms_additional_vaginal_pain_or_swelling",
    ],
    multiselectOptionType: "singleselect",
  },
  symptoms_obvious_trigger: {
    question: "symptoms_obvious_trigger",
    answerType: "multiselect",
  },
};

const DISCHARGE_QUESTIONS: QuestionMap = {
  vaginal_discharge: {
    question: "vaginal_discharge",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Discharge is normal",
      getText: () =>
        "Discharge plays a crucial role in keeping the vagina healthy and free from infections. It's also a critical clue to what's going on down there!",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/discharge-color-meaning",
        },
      ],
    },
  },
  symptoms_vaginal_discharge_color: {
    question: "symptoms_vaginal_discharge_color",
    answerType: "singleselect",
  },
  symptoms_vaginal_smell: {
    question: "symptoms_vaginal_smell",
    answerType: "multiselect",
  },
  symptoms_vaginal_discharge_consistency: {
    question: "symptoms_vaginal_discharge_consistency",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Unusual is the norm",
      getText: (values) => {
        if (
          values?.symptoms_vaginal_smell &&
          !values?.symptoms_vaginal_smell.includes("NO")
        ) {
          return "Over half of Evvy test takers also report an unusual smell.";
        }
      },
    },
  },
};

const DIAGNOSES_QUESTIONS: QuestionMap = {
  diagnoses_30: {
    question: "diagnoses_30",
    answerType: "multiselect_dimensional",
    options: [
      "diagnoses_30day_none",
      "diagnoses_30day_bv",
      "diagnoses_30day_yeast_infection",
      "diagnoses_30day_uti",
      "diagnoses_30day_av",
      "diagnoses_30day_ureaplasma",
      "diagnoses_30day_mycoplasma",
      "diagnoses_30day_cytolytic_vaginosis",
      "diagnoses_30day_chlamydia",
      "diagnoses_30day_trichomoniasis",
      "diagnoses_30day_gonorrhea",
    ],
    multiselectOptionType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "Imbalances in the vaginal microbiome can manifest as yeast infections, bacterial vaginosis (BV), cytolytic vaginosis (CV), aerobic vaginitis (AV), and more.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/vaginal-microbiome",
        },
      ],
    },
  },
  diagnoses_30day_bv_method: {
    question: "diagnoses_30day_bv_method",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Why we built Evvy",
      getText: () =>
        "Bacterial vaginosis symptoms will recur in up to 50% of people within 6 months of treatment. Understanding your vaginal microbiome can help get to the root of the issue.",
      citations: [
        {
          title: "Vodstricil 2021",
          url: "https://bmcmedicine.biomedcentral.com/articles/10.1186/s12916-021-02077-3",
        },
      ],
    },
  },
  diagnoses_30day_yeast_infection_method: {
    question: "diagnoses_30day_yeast_infection_method",
    answerType: "singleselect",
  },
};

const TREATMENTS_QUESTIONS: QuestionMap = {
  prescription_treatment: {
    question: "prescription_treatment",
    answerType: "multiselect_dimensional",
    options: [
      "prescription_treatment_none",
      "prescription_treatment_antibiotics_metro",
      "prescription_treatment_antibiotics_clinda",
      "prescription_treatment_antibiotics_not_listed",
      "prescription_treatment_antifungal",
      "prescription_treatment_hormone_therapy",
    ],
    multiselectOptionType: "singleselect",
    sectionIntro: {
      text: "Let us know what treatments you've tried previously and whether they worked for you.",
    },
  },
  other_vaginal_health_products: {
    question: "other_vaginal_health_products",
    answerType: "multiselect",
  },
  treatment_response: {
    question: "treatment_response",
    answerType: "multiselect_dimensional",
    options: [
      "treatment_response_none",
      "treatment_response_antibiotics_metro",
      "treatment_response_antibiotics_clinda",
      "treatment_response_antibiotics_other",
      "treatment_response_antifungal",
      "treatment_response_hormone_therapy",
      "treatment_response_douching",
      "treatment_response_feminine_hygiene",
      "treatment_response_probiotics",
      "treatment_response_boric_acid",
    ],
  },
};

const SEXUAL_ACTIVITY_QUESTIONS: QuestionMap = {
  sexual_activity_timing: {
    question: "sexual_activity_timing",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () => "Sex can have a big impact on your vaginal microbiome.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/sex-vaginal-ph-microbiome",
        },
      ],
    },
  },
  sexual_activity_condoms: {
    question: "sexual_activity_condoms",
    answerType: "singleselect",
  },
  contraception_types_used: {
    question: "contraception_types_used",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "Sex is one of the most commonly reported triggers for Bacterial Vaginosis (BV) and BV bacteria can be transmitted during sex.",
      citations: [
        {
          title: "Muzny 2022",
          url: "https://academic.oup.com/cid/article/74/Supplement_2/S144/6567952?login=true",
        },
      ],
    },
  },
  contraception_types_used_other: {
    question: "contraception_types_used_other",
    answerType: "text",
  },
  contraception_brand: { question: "contraception_brand", answerType: "text" },
  sexual_activity_partners: {
    question: "sexual_activity_partners",
    answerType: "singleselect",
  },
  recent_sexual_activity: {
    question: "recent_sexual_activity",
    answerType: "multiselect",
  },
};

const BASICS_QUESTIONS_MAP: QuestionMap = {
  basics_recent_period: {
    question: "basics_recent_period",
    answerType: "singleselect",
  },
  basics_last_period_date: {
    question: "basics_last_period_date",
    answerType: "date",
  },
  basics_main_reason_for_test: {
    question: "basics_main_reason_for_test",
    answerType: "text",
  },
  // Retester only, re-test only or care
  basics_hoping_to_learn: {
    question: "basics_hoping_to_learn",
    answerType: "text",
  },
  // Retester, did NOT go through care
  basics_follow_recommendations: {
    question: "basics_follow_recommendations",
    answerType: "singleselect",
  },
  basics_follow_recommendations_details: {
    question: "basics_follow_recommendations_details",
    answerType: "text",
  },
  basics_retest_symptoms_changed: {
    question: "basics_retest_symptoms_changed",
    answerType: "singleselect",
  },
  // Retester, DID go through care
  post_care_symptom_change: {
    question: "post_care_symptom_change",
    answerType: "singleselect",
  },
  post_care_improved_symptoms: {
    question: "post_care_improved_symptoms",
    answerType: "multiselect",
  },
  post_care_worsened_symptoms: {
    question: "post_care_worsened_symptoms",
    answerType: "multiselect",
  },
  post_care_side_effects: {
    question: "post_care_side_effects",
    answerType: "multiselect",
  },
  post_care_followed_protocol: {
    question: "post_care_followed_protocol",
    answerType: "number_scale",
  },
  // Only show for first time users
  basics_retest_symptoms: {
    question: "basics_retest_symptoms",
    answerType: "singleselect",
  },
};

const DEMOGRAPHICS_QUESTIONS_MAP: QuestionMap = {
  demographics_height: {
    question: "demographics_height",
    answerType: "text",
    sectionIntro: {
      header: "DID YOU KNOW?",
      text: "Age and ethnicity can play a significant role in the differences in the microbiome.",
      citations: [
        {
          title: "Muhleisen 2016",
          url: "https://www.sciencedirect.com/science/article/pii/S0378512216301244",
        },
        {
          title: "Fettweis 2019",
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4206474/",
        },
      ],
    },
  },
  demographics_weight: {
    question: "demographics_weight",
    answerType: "number",
  },
  demographics_race: {
    question: "demographics_race",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "Why we built Evvy",
      getText: () =>
        "Studies have shown there is a connection between the vaginal microbiome and race, but we don't fully understand it yet.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/race-vaginal-microbiome",
        },
      ],
    },
  },
  demographics_hispanic_or_latino: {
    question: "demographics_hispanic_or_latino",
    answerType: "singleselect",
  },
  demographics_born_with_vagina: {
    question: "demographics_born_with_vagina",
    answerType: "singleselect",
  },
  demographics_gender_affirming_care: {
    question: "demographics_gender_affirming_care",
    answerType: "singleselect",
  },
  demographics_gender_identity: {
    question: "demographics_gender_identity",
    answerType: "singleselect",
  },
};

const LIFESTYLE_QUESTIONS_MAP: QuestionMap = {
  lifestyle_smoking: {
    question: "lifestyle_smoking",
    answerType: "singleselect",
    sectionIntro: {
      text: "By understanding your lifestyle, we can point to personalized opportunities to improve your vaginal health.",
    },
  },
  lifestyle_diet: {
    question: "lifestyle_diet",
    answerType: "multiselect",
  },
  lifestyle_exercise: {
    question: "lifestyle_exercise",
    answerType: "singleselect",
  },
};

const RELATED_DIAGNOSES_MAP: QuestionMap = {
  diagnoses: {
    question: "diagnoses",
    answerType: "multiselect_dimensional",
    options: [
      "diagnoses_none",
      "diagnoses_bv",
      "diagnoses_yeast_infection",
      "diagnoses_uti",
      "diagnoses_av",
      "diagnoses_ureaplasma",
      "diagnoses_mycoplasma",
      "diagnoses_cytolytic_vaginosis",
      "diagnoses_chlamydia",
      "diagnoses_trichomoniasis",
      "diagnoses_gonorrhea",
    ],
    multiselectOptionType: "singleselect",
    sectionIntro: {
      text: "Vaginal health doesn't happen in a vacuum. By understanding your other diagnoses, we can piece together how these might be affecting your vaginal microbiome.",
    },
  },
  related_diagnoses: {
    question: "related_diagnoses",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "You are (definitely) not alone",
      getText: (values) => {
        if (
          values?.diagnoses_bv &&
          values?.diagnoses_bv?.length > 0 &&
          !values?.diagnoses_bv?.includes("NE")
        ) {
          return "Almost 1 in 3 people with vaginas get bacterial vaginosis each year and around 70% of Evvy testers have had BV at some point.";
        } else if (
          values?.diagnoses_yeast_infection &&
          values?.diagnoses_yeast_infection?.length > 0 &&
          !values?.diagnoses_yeast_infection?.includes("NE")
        ) {
          return "Around 70% of Evvy testers have had a yeast infection at some point.";
        } else if (
          values?.diagnoses_uti &&
          values?.diagnoses_uti?.length > 0 &&
          !values?.diagnoses_uti?.includes("NE")
        ) {
          return "Around 60% of Evvy testers have had a UTI at some point.";
        }
      },
    },
  },
  diagnosis_year: {
    question: "diagnosis_year",
    answerType: "multidates",
  },
  autoimmune_conditions: {
    question: "autoimmune_conditions",
    answerType: "text",
  },
  infertility_treatments: {
    question: "infertility_treatments",
    answerType: "multiselect_dimensional",
    options: [
      "fertility_treatment_none",
      "fertility_treatment_intrauterine_insemination",
      "fertility_treatment_in_vitro_fertilization",
      "fertility_treatment_hormone_therapy",
      "fertility_treatment_ovulation_induction",
      "fertility_treatment_surgical_interventions",
      "fertility_treatment_alternative_therapies",
      "fertility_treatment_other",
    ],
    multiselectOptionType: "singleselect",
    educationalMoment: {
      getHeader: () => "Thank you for sharing",
      getText: () =>
        "We ask this question because we want to close the research gap that exists today around fertility outcomes.",
      citations: [
        {
          title: "The Evvy blog",
          url: "https://www.evvy.com/blog/fertility-vaginal-ph",
        },
      ],
    },
  },
  infertility_cause_identified: {
    question: "infertility_cause_identified",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        'Some sources say 30% of infertility is "unexplained" — we are working on ways the vaginal microbiome might be able to explain some of these cases',
      citations: [
        {
          title: "Raperport 2023",
          url: "https://obgyn.onlinelibrary.wiley.com/doi/10.1111/1471-0528.17697",
        },
      ],
    },
  },
  infertility_cause: {
    question: "infertility_cause",
    answerType: "text",
  },
  breast_cancer_treatment: {
    question: "breast_cancer_treatment",
    answerType: "multiselect",
  },
  breast_cancer_in_remission: {
    question: "breast_cancer_in_remission",
    answerType: "singleselect",
  },
  breast_cancer_last_surgery_date: {
    question: "breast_cancer_last_surgery_date",
    answerType: "date",
  },
  breast_cancer_last_treatment_date: {
    question: "breast_cancer_last_treatment_date",
    answerType: "date",
  },
  cervical_cancer_treatment: {
    question: "cervical_cancer_treatment",
    answerType: "multiselect",
  },
  cervical_cancer_in_remission: {
    question: "cervical_cancer_in_remission",
    answerType: "singleselect",
  },
  cervical_cancer_last_surgery_date: {
    question: "cervical_cancer_last_surgery_date",
    answerType: "date",
  },
  cervical_cancer_last_treatment_date: {
    question: "cervical_cancer_last_treatment_date",
    answerType: "date",
  },
  endo_cancer_treatment: {
    question: "endo_cancer_treatment",
    answerType: "multiselect",
  },
  endo_cancer_in_remission: {
    question: "endo_cancer_in_remission",
    answerType: "singleselect",
  },
  endo_cancer_last_surgery_date: {
    question: "endo_cancer_last_surgery_date",
    answerType: "date",
  },
  endo_cancer_last_treatment_date: {
    question: "endo_cancer_last_treatment_date",
    answerType: "date",
  },
  ovarian_cancer_treatment: {
    question: "ovarian_cancer_treatment",
    answerType: "multiselect",
  },
  ovarian_cancer_in_remission: {
    question: "ovarian_cancer_in_remission",
    answerType: "singleselect",
  },
  ovarian_cancer_last_surgery_date: {
    question: "ovarian_cancer_last_surgery_date",
    answerType: "date",
  },
  ovarian_cancer_last_treatment_date: {
    question: "ovarian_cancer_last_treatment_date",
    answerType: "date",
  },
  other_cancer_treatment: {
    question: "other_cancer_treatment",
    answerType: "multiselect",
  },
  other_cancer_in_remission: {
    question: "other_cancer_in_remission",
    answerType: "singleselect",
  },
  other_cancer_last_surgery_date: {
    question: "other_cancer_last_surgery_date",
    answerType: "date",
  },
  other_cancer_last_treatment_date: {
    question: "other_cancer_last_treatment_date",
    answerType: "date",
  },

  diabetes_type: {
    question: "diabetes_type",
    answerType: "singleselect",
  },
  diabetes_hba1c: {
    question: "diabetes_hba1c",
    answerType: "number",
    optional: true,
  },
  diabetes_last_hba1c_date: {
    question: "diabetes_last_hba1c_date",
    answerType: "date",
    optional: true,
  },
  herpes_outbreak_location: {
    question: "herpes_outbreak_location",
    answerType: "multiselect",
  },
  hpv_last_screening_date: {
    question: "hpv_last_screening_date",
    answerType: "date",
    educationalMoment: {
      getHeader: () => "You're not alone",
      getText: () =>
        "Around 8 in 10 of sexually active people will get a genital HPV infection at some point.",
      citations: [
        {
          title: "The Evvy blog",
          url: "https://www.evvy.com/blog/what-is-hpv",
        },
      ],
    },
    optional: true,
  },
  cin_type: {
    question: "cin_type",
    answerType: "singleselect",
  },
  cin_last_screening_date: {
    question: "cin_last_screening_date",
    answerType: "date",
    optional: true,
  },
  related_diagnoses_immunocompromised: {
    question: "related_diagnoses_immunocompromised",
    answerType: "singleselect",
  },
};

const PREGNANCY_QUESTIONS_MAP: QuestionMap = {
  pregnancy_status: {
    question: "pregnancy_status",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "Hormonal and physical changes during pregnancy can impact the vaginal microbiome, and research shows that the vaginal microbiome may play a role in pregnancy outcomes.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/vagina-during-pregnancy",
        },
      ],
    },
  },
  pregnancy_breastfeeding: {
    question: "pregnancy_breastfeeding",
    answerType: "singleselect",
  },
  pregnancy_postpartum_timing: {
    question: "pregnancy_postpartum_timing",
    answerType: "singleselect",
  },
  pregnancy_month: {
    question: "pregnancy_month",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "The vaginal microbiome shifts alongside physical and hormonal shifts during pregnancy and postpartum.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/vagina-during-pregnancy",
        },
      ],
    },
  },
  pregnancy_previously: {
    question: "pregnancy_previously",
    answerType: "singleselect",
  },
  pregnancy_experienced: {
    question: "pregnancy_experienced",
    answerType: "multiselect",
  },
};

const HORMONAL_HEALTH_QUESTIONS_MAP: QuestionMap = {
  hormonal_menopause: {
    question: "hormonal_menopause",
    answerType: "singleselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "Your hormones are playing a critical role in your vaginal health. Your current hormonal status and symptoms are an important piece of the puzzle.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/how-to-tell-whether-your-vaginal-infection-is-due-to-hormones",
        },
      ],
    },
  },
  hormonal_menopause_symptoms: {
    question: "hormonal_menopause_symptoms",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "Did you know?",
      getText: () =>
        "Hormonal changes during menopause, specifically a decrease in estrogen, have an impact on the vaginal microbiome.",
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/vaginal-estrogen",
        },
      ],
    },
  },
  hormonal_menopause_hormones: {
    question: "hormonal_menopause_hormones",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "You're not alone",
      getText: (values) => {
        if (
          values?.hormonal_menopause_symptoms?.includes("urinary") ||
          values?.hormonal_menopause_symptoms?.includes("vaginal")
        ) {
          return "Vaginal symptoms and UTIs are unfortunately more common in menopause. Fortunately, we know this change is often tied to the vaginal microbiome — you're in the right place!";
        }
      },
      citations: [
        {
          title: "The Evvy Blog",
          url: "https://www.evvy.com/blog/does-menopause-increase-the-risk-of-utis",
        },
      ],
    },
  },
  hormonal_menopause_hysterectomy: {
    question: "hormonal_menopause_hysterectomy",
    answerType: "singleselect",
  },
  hormonal_period_counts: {
    question: "hormonal_period_counts",
    answerType: "singleselect",
  },
  hormonal_period_products: {
    question: "hormonal_period_products",
    answerType: "multiselect",
  },
  contraception_types_used: {
    question: "contraception_types_used",
    answerType: "multiselect",
  },
  contraception_brand: {
    question: "contraception_brand",
    answerType: "text",
  },
  hormonal_symptoms: {
    question: "hormonal_symptoms",
    answerType: "multiselect",
  },
};

const QUALITY_OF_LIFE_QUESTIONS_MAP: QuestionMap = {
  related_diagnoses_quality_of_life: {
    question: "related_diagnoses_quality_of_life",
    answerType: "number_scale",
    sectionIntro: {
      text: "Vaginal health can be so much more than what's up, down there. We know that vaginal symptoms can have a big impact on your quality of life and mental and physical wellbeing — we've been there.",
    },
    educationalMoment: {
      getHeader: () => "Us, too",
      getText: (values) => {
        if (values && !answerIsNone(values, "hormonal_symptoms", "NO")) {
          return "Around 85% of Evvy testers are experiencing at least one hormone related symptom.";
        }
      },
    },
  },
  quality_of_life_most_affected: {
    question: "quality_of_life_most_affected",
    answerType: "multiselect",
    educationalMoment: {
      getHeader: () => "We're with you",
      getText: (values) => {
        if (
          values?.related_diagnoses_quality_of_life &&
          values?.related_diagnoses_quality_of_life >= 6
        ) {
          return "We started Evvy because we deserve better.";
        }
      },
      citations: [
        {
          title: "The Evvy blog",
          url: "https://www.evvy.com/blog/gender-health-gap-founder-story",
        },
        {
          title: "Thomas-White 2023 (Evvy's white paper!)",
          url: "https://journals.sagepub.com/doi/10.1177/17455057231216537",
        },
      ],
    },
  },
};

const HDYHAU_QUESTION_MAP: QuestionMap = {
  how_did_you_hear_about_evvy: {
    question: "how_did_you_hear_about_evvy",
    answerType: "singleselect",
  },
};

export const SECTION_TITLES: { [section: string]: string } = {
  symptoms: "Symptoms",
  discharge: "Discharge",
  diagnoses: "Diagnoses",
  treatments: "Treatments",
  sexual_activity: "Sexual Activity",
  basics: "Basics",
  demographics: "Demographics",
  lifestyle: "Lifestyle",
  related_diagnoses: "Related Diagnoses",
  pregnancy: "Pregnancy",
  hormonal_health: "Hormonal Health",
  quality_of_life: "Quality of Life",
};

export const CONTEXT_SECTIONS: { [section: string]: QuestionMap } = {
  symptoms: SYMPTOMS_QUESTIONS,
  discharge: DISCHARGE_QUESTIONS,
  diagnoses: DIAGNOSES_QUESTIONS,
  treatments: TREATMENTS_QUESTIONS,
  sexual_activity: SEXUAL_ACTIVITY_QUESTIONS,
  basics: BASICS_QUESTIONS_MAP,
  sample: {},
  profile: {},
  demographics: DEMOGRAPHICS_QUESTIONS_MAP,
  lifestyle: LIFESTYLE_QUESTIONS_MAP,
  related_diagnoses: RELATED_DIAGNOSES_MAP,
  pregnancy: PREGNANCY_QUESTIONS_MAP,
  hormonal_health: HORMONAL_HEALTH_QUESTIONS_MAP,
  quality_of_life: QUALITY_OF_LIFE_QUESTIONS_MAP,
  hdyhau: HDYHAU_QUESTION_MAP,
};

export const HEALTH_CONTEXT_SECTIONS = [
  "symptoms",
  "discharge",
  "diagnoses",
  "treatments",
  "sexual_activity",
  "basics",
];

export const HEALTH_PROFILE_SECTIONS = [
  "demographics",
  "lifestyle",
  "related_diagnoses",
  "pregnancy",
  "hormonal_health",
  "quality_of_life",
];

export type Page = {
  section: string;
  question?: string;
  educationalMoment?: string;
};

export const getNextQuestionWithLogic = ({
  section,
  currentQuestion,
  healthContextValues,
  ldtEnabled,
  test,
  hdyhauSurvey,
}: {
  section: string;
  currentQuestion: Question;
  healthContextValues: HealthContextFields;
  ldtEnabled?: boolean;
  test?: Test;
  hdyhauSurvey?: any;
}): Page | undefined => {
  const hasPreviousTestWithoutCare =
    test?.has_other_active_test &&
    !test?.previous_test_care.previous_test_completed_care;
  const hasPreviousTestWithCare =
    test?.previous_test_care.previous_test_completed_care;
  const relatedDiagnosesValues =
    healthContextValues.healthcontextrelateddiagnoses_set;

  const questionsOrderLogic = getAllQuestionOrderLogic();
  // using questionsOrderLogic to determine the next question
  // get index of current question in the array
  const currentQuestionLogic = questionsOrderLogic.find(
    (question) =>
      question.currentSection === section &&
      question.currentQuestion === currentQuestion.question
  );
  if (!currentQuestionLogic) {
    return undefined;
  }
  // now get the next question in the array, and keep iterating until showCondition is true
  const currentQuestionIndex =
    questionsOrderLogic.indexOf(currentQuestionLogic);

  // handling for the last question
  if (currentQuestionIndex === questionsOrderLogic.length - 1) {
    return undefined;
  }
  for (let i = currentQuestionIndex + 1; i < questionsOrderLogic.length; i++) {
    const nextQuestionLogic = questionsOrderLogic[i];
    if (
      nextQuestionLogic.showCondition({
        healthContextValues,
        ldtEnabled,
        relatedDiagnosesValues,
        hasPreviousTestWithoutCare,
        hasPreviousTestWithCare,
        hdyhauSurvey,
      })
    ) {
      return {
        section: nextQuestionLogic.currentSection,
        question: nextQuestionLogic.currentQuestion,
      };
    }
  }
};

export const getPrevQuestionWithLogic = ({
  section,
  currentQuestion,
  healthContextValues,
  ldtEnabled,
  test,
}: {
  section: string;
  currentQuestion?: Question;
  healthContextValues: HealthContextFields;
  ldtEnabled?: boolean;
  test?: Test;
}): Page | undefined => {
  const hasPreviousTestWithCare =
    test?.previous_test_care.previous_test_completed_care;
  const hasPreviousTestWithoutCare =
    test?.has_other_active_test && !hasPreviousTestWithCare;
  const relatedDiagnosesValues =
    healthContextValues.healthcontextrelateddiagnoses_set;
  const questionsOrderLogic = getAllQuestionOrderLogic();

  // using questionsOrderLogic to determine the previous question
  // get index of current question in the array
  const currentQuestionLogic = questionsOrderLogic.find(
    (question) =>
      question.currentSection === section &&
      question.currentQuestion === currentQuestion?.question
  );
  if (!currentQuestionLogic) {
    return undefined;
  }
  // now get the previous question in the array, and keep iterating until showCondition is true
  const currentQuestionIndex =
    questionsOrderLogic.indexOf(currentQuestionLogic);

  for (let i = currentQuestionIndex - 1; i >= 0; i--) {
    const prevQuestionLogic = questionsOrderLogic[i];
    if (
      prevQuestionLogic.showCondition({
        healthContextValues,
        ldtEnabled,
        relatedDiagnosesValues,
        hasPreviousTestWithoutCare,
        hasPreviousTestWithCare,
      })
    ) {
      return {
        section: prevQuestionLogic.currentSection,
        question: prevQuestionLogic.currentQuestion,
      };
    }
  }
};
