import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { authService } from "../services/auth";
import { eventNames } from "../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../utils/analytics/helpers";
import { useIdentity } from "../hooks/useIdentity";
import { useCompletedConsults } from "../hooks/useCompletedConsults";
import classNames from "classnames";

const Header = ({ noBottomPadding, noRoundedBottom, bottomBanner }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isImpersonation, setIsImpersonation] = useState(false);
  const { isProvider } = useIdentity();
  const completedConsults = useCompletedConsults();

  const location = useLocation();

  const getCleanPathName = (pathname) => {
    // returns a short name for the current path, displayed in mobile header
    const routeNames = {
      "/tests/": "My Tests",
      "/compare/": "Compare",
      "/plan/": "My Plan",
      "/account/": "Account",
      "/care/": "Care", // this one has many sub-routes nested under this url
    };
    var cleanPathName = "";
    Object.keys(routeNames).forEach((k) => {
      if (pathname.startsWith(k)) {
        cleanPathName = routeNames[k];
      }
    });
    return cleanPathName;
  };

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const firstLetter =
    currentUser &&
    (currentUser.identity.first_name[0] || currentUser.identity.email[0]);
  const wholeName =
    currentUser &&
    (currentUser.identity.first_name || currentUser.identity.email);

  const hasCompletedSTIConsult =
    completedConsults?.filter(
      (consult) => consult.type === "sti" && consult.status === "CP"
    )?.length > 0;

  const resultsReady =
    currentUser && currentUser.results && currentUser.results.has_ready_test;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const impersonate = queryParams.get("__impersonate");
    setIsImpersonation(impersonate);
  }, []);

  return (
    <div
      className={`relative z-10 ${
        noBottomPadding ? "lg:px-6 px-3 lg:pt-6 pt-3" : "lg:p-6 p-3"
      }`}
    >
      {isImpersonation && (
        <p className="text-center text-red-600">{`YOU ARE IMPERSONATING ${isImpersonation}`}</p>
      )}
      <div
        className={`relative z-10 bg-evvy-black text-evvy-white ${
          noRoundedBottom ? "rounded-t-xl" : "rounded-xl"
        } p-5 max-w-7xl mx-auto grid grid-cols-3 md:grid-cols-4 gap-4`}
      >
        <div className="ml-4 mr-auto">
          <Link
            to="/"
            onClick={analyticsClickHandler({
              eventName: eventNames.CLICKED_TOP_NAV,
              eventArgs: { navItem: "Home" },
            })}
          >
            <img
              className="h-8 sm:h-10"
              src="/images/evvy-logo-text-white.svg"
              loading="lazy"
              alt="Evvy Text Logo"
            />
          </Link>
        </div>

        <div className="col-span-2 mx-auto space-x-8 items-center hidden md:flex">
          {isProvider ? (
            <>
              <Link
                to="/provider/order"
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/provider/order")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "Provider Order Test" },
                })}
              >
                Order A New Test
              </Link>
              <Link
                to="/provider/tests"
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/provider/tests")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "Provider Tests" },
                })}
              >
                Tests
              </Link>
              <Link
                to="/provider/resources"
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/provider/resources")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "Provider Resources" },
                })}
              >
                Resources
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/tests/"
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/tests/")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "My Tests" },
                })}
              >
                My Tests
              </Link>

              <Link
                to="/plan/"
                disabled={!resultsReady}
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/plan/")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "My Plan" },
                })}
              >
                My Plan
              </Link>

              <Link
                to="/compare/"
                disabled={!resultsReady}
                className={`uppercase py-2 text-sm font-semibold tracking-wider border-b-2 hover:border-evvy-blue ${
                  location.pathname.startsWith("/compare/")
                    ? "border-evvy-blue"
                    : "border-transparent"
                }`}
                onClick={analyticsClickHandler({
                  eventName: eventNames.CLICKED_TOP_NAV,
                  eventArgs: { navItem: "Compare" },
                })}
              >
                Compare
              </Link>

              {currentUser && (
                <div
                  className={classNames(
                    "py-2 border-b-2",
                    {
                      "border-evvy-blue":
                        location.pathname.startsWith("/care/"),
                      "border-transparent": !location.pathname.startsWith("/care/"),
                      'hover:border-evvy-blue': resultsReady || hasCompletedSTIConsult,
                    }
                  )}
                >
                  <Link
                    to={
                      hasCompletedSTIConsult
                        ? "/care/past-treatments"
                        : "/care/"
                    }
                    disabled={!resultsReady && !hasCompletedSTIConsult}
                    className={`uppercase text-sm font-semibold tracking-wider`}
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CLICKED_TOP_NAV,
                      eventArgs: { navItem: "Care" },
                    })}
                  >
                    Care
                  </Link>
                  {currentUser && currentUser?.notifications?.care && (
                    <span class="inline-block w-2 h-2 align-top bg-dv-orange rounded-full"></span>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        <div className="ml-auto mr-4 hidden md:flex items-center">
          {currentUser ? (
            <Link
              to={isProvider ? "/provider/account" : "/account/"}
              onClick={analyticsClickHandler({
                eventName: eventNames.CLICKED_TOP_NAV,
                eventArgs: { navItem: "Account" },
              })}
            >
              <div className="p-2 rounded-full bg-evvy-blue text-white group">
                <span className="px-1 uppercase block group-hover:hidden w-6 h-6 text-center leading-6">
                  {firstLetter}
                </span>
                <span className="px-6 hidden group-hover:inline h-6 leading-6">
                  {wholeName}
                </span>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>

        {/* mobile hamburger */}
        <div className="ml-auto mr-4 col-span-2 flex md:hidden items-center">
          <button
            type="button"
            className="inline-flex items-center justify-center p-0 rounded-md text-evvy-white focus:outline-none focus:ring-evvy-blue"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {!menuOpen ? (
              <div className="t4" onClick={() => setMenuOpen(true)}>
                {getCleanPathName(location.pathname)}
                {currentUser && currentUser?.notifications?.care && (
                  <span class="inline-block w-2 h-2 align-top bg-dv-orange rounded-full"></span>
                )}
                <svg
                  className="inline-block ml-2"
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M1.31336 0.696838L5.15429 4.53846L5.98511 5.36942L6.81593 4.53846L10.6441 0.696838L11.8072 1.84741L5.98511 7.65778L0.175781 1.84741L1.31336 0.696838Z"
                    fill="#6CA7FF"
                  />
                </svg>
              </div>
            ) : (
              <svg
                onClick={() => setMenuOpen(false)}
                className="block h-8 w-8 text-evvy-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>

        {menuOpen ? (
          <div className="fixed z-40 md:hidden" role="dialog" aria-modal="true">
            <div
              className="hidden sm:block sm:fixed sm:inset-0 sm:bg-slate-600 sm:bg-opacity-75 cursor-pointer"
              aria-hidden="true"
              onClick={() => setMenuOpen(false)}
            ></div>

            <nav
              className="fixed z-40 top-0 left-0 w-full bg-evvy-black text-evvy-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg"
              aria-label="Global"
            >
              <div className="flex items-center justify-between px-3 py-2">
                <div className="p-3">
                  <img
                    className="h-8 w-auto"
                    src="/images/evvy-logo-text-white.svg"
                    alt="Evvy logo"
                  />
                </div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                  onClick={() => setMenuOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="block h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                {!isProvider && (
                  <>
                    <Link
                      to="/tests/"
                      className="block rounded-md py-2 px-3 text-base font-medium text-slate-100 hover:bg-slate-600"
                      onClick={analyticsClickHandler({
                        eventName: eventNames.CLICKED_TOP_NAV,
                        eventArgs: { navItem: "My Tests" },
                        clickHandler: () => setMenuOpen(false),
                      })}
                    >
                      My Tests
                    </Link>
                    <Link
                      to="/plan/"
                      disabled={!resultsReady}
                      className="block rounded-md py-2 px-3 text-base font-medium text-slate-100 hover:bg-slate-600"
                      onClick={analyticsClickHandler({
                        eventName: eventNames.CLICKED_TOP_NAV,
                        eventArgs: { navItem: "My Plan" },
                        clickHandler: () => setMenuOpen(false),
                      })}
                    >
                      My Plan
                    </Link>

                    <Link
                      to="/care/"
                      disabled={!resultsReady}
                      className="block rounded-md py-2 px-3 text-base font-medium text-slate-100 hover:bg-slate-600"
                      onClick={analyticsClickHandler({
                        eventName: eventNames.CLICKED_TOP_NAV,
                        eventArgs: { navItem: "Care" },
                        clickHandler: () => setMenuOpen(false),
                      })}
                    >
                      Care
                      {currentUser && currentUser?.notifications?.care && (
                        <span class="inline-block w-2 h-2 align-top bg-dv-orange rounded-full"></span>
                      )}
                    </Link>
                  </>
                )}
              </div>

              <div className="border-t border-slate-200 pt-4 pb-3">
                {currentUser ? (
                  <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                    <div className="flex-shrink-0">
                      <div className="p-2 rounded-full bg-evvy-blue text-white">
                        <span className="px-1 uppercase block w-6 h-6 leading-6 text-center">
                          {firstLetter}
                        </span>
                      </div>
                    </div>
                    <div className="ml-3 min-w-0 flex-1">
                      <div className="text-base font-medium text-slate-100 truncate">
                        {currentUser.identity.first_name}{" "}
                        {currentUser.identity.last_name}
                      </div>
                      <div className="font-medium text-slate-100 truncate">
                        {currentUser.identity.email}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                  <Link
                    to={isProvider ? "/provider/account" : "/account/"}
                    className="block rounded-md py-2 px-3 text-base font-medium text-slate-100 hover:bg-slate-600"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.CLICKED_TOP_NAV,
                      eventArgs: { navItem: "Account" },
                      clickHandler: () => setMenuOpen(false),
                    })}
                  >
                    Account
                  </Link>

                  <div
                    className="block cursor-pointer rounded-md py-2 px-3 text-base font-medium text-slate-100 hover:bg-slate-600"
                    onClick={() => {
                      setMenuOpen(false);
                      authService.logout();
                    }}
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </nav>
          </div>
        ) : (
          ""
        )}
      </div>
      {bottomBanner && <div className="max-w-7xl mx-auto">{bottomBanner}</div>}
    </div>
  );
};

export default Header;
