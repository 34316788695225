import React from "react";
import { Link } from "react-router-dom";
import { Page, getHealthHistoryURL } from "../../../utils/healthHistory";
import classNames from "classnames";

const HEALTH_CONTEXT_SECTION = "HEALTH_CONTEXT";
const HEALTH_PROFILE_SECTION = "HEALTH_PROFILE";
const TAKE_SAMPLE_SECTION = "TAKE_SAMPLE";
const INTRO_SECTION = "INTRO";

const Header = ({
  onClickBack,
  currentSection,
  previousPage,
  hash,
}: {
  onClickBack?: () => void;
  currentSection?: string;
  previousPage?: Page;
  hash?: string;
}) => {
  const isPreviousPageHome = !previousPage;
  const getSection = (section: string) => {
    const introScreens = ["intro", "verify", "personal-information"];
    const healthContext = [
      "symptoms",
      "discharge",
      "treatments",
      "sexual_activity",
      "basics",
      "diagnoses",
    ];
    const takeSample = ["sample-overview", "sample-confirm", "additional"];
    const healthProfile = [
      "profile",
      "demographics",
      "lifestyle",
      "related_diagnoses",
      "pregnancy",
      "hormonal_health",
      "quality_of_life",
    ];
    if (healthContext.includes(section)) {
      return HEALTH_CONTEXT_SECTION;
    } else if (healthProfile.includes(section)) {
      return HEALTH_PROFILE_SECTION;
    } else if (takeSample.includes(section)) {
      return TAKE_SAMPLE_SECTION;
    } else if (introScreens.includes(section)) {
      return INTRO_SECTION;
    }
  };

  const showNav = currentSection !== "intro";
  const currentHealthHistoryStep = currentSection && getSection(currentSection);

  return (
    <React.Fragment>
      <nav
        className={`bg-evvy-cream border-b border-evvy-black border-opacity-20 w-full top-0 px-6 md:px-20 py-4 sm:py-3 flex justify-between align-center z-20 pointer-events-none`}
      >
        <Link
          to={
            isPreviousPageHome
              ? `/tests/`
              : getHealthHistoryURL(
                  hash,
                  previousPage?.section,
                  previousPage?.question
                )
          }
          onClick={onClickBack ? onClickBack : undefined}
        >
          <button
            className={`p-2 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
            aria-label="Back to home"
          >
            {isPreviousPageHome ? (
              <span className="px-2 h-8 inline uppercase tracking-wider font-semibold">
                <span className="pr-2">
                  <h4 className="font-bold leading-6 margin-0 inline">←</h4>
                </span>{" "}
                Back to home
              </span>
            ) : (
              <span className="uppercase w-7 h-7 text-center">
                <h4 className="font-bold leading-6">←</h4>
              </span>
            )}
          </button>
        </Link>

        {/* Nav for desktop */}
        {showNav ? (
          <div
            className={`mx-auto w-full hidden sm:flex ${
              currentSection === "verify" ? "max-w-4xl" : "max-w-2xl"
            } items-center`}
          >
            {currentSection === "verify" && (
              <div className="b2 semibold hidden md:flex">Test ID: {hash}</div>
            )}
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "HEALTH_CONTEXT"
                    ? "border-crazy-purple bg-crazy-purple text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  1
                </div>
              </div>
              <div>HEALTH CONTEXT</div>
            </div>
            <div className="border-evvy-black border-b flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "TAKE_SAMPLE"
                    ? "border-coral bg-coral text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  2
                </div>
              </div>
              <div>TAKE YOUR SAMPLE</div>
            </div>
            <div className="flex-1 flex items-center justify-center color-evvy-black p-2 t5 text-center uppercase tracking-wider pointer-events-none font-semibold border-t-4 border-transparent">
              <div
                className={`border rounded-full text-center mr-2 ${
                  currentHealthHistoryStep === "HEALTH_PROFILE"
                    ? "border-dv-pond bg-dv-pond text-white"
                    : "border-black"
                }`}
              >
                <div className="w-8 h-8 leading-6 flex items-center justify-center t5 semibold rounded-full">
                  3
                </div>
              </div>
              <div>HEALTH PROFILE</div>
            </div>
          </div>
        ) : null}
        <Link to="/" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10"
            src="/images/evvy-logo-text.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
      </nav>

      {/* nav for mobile */}
      {showNav && (
        <div
          className={classNames(
            "sm:hidden flex py-4 px-2 font-semibold uppercase tracking-wider",
            {
              "border-evvy-black border-b border-opacity-20":
                currentHealthHistoryStep !== "INTRO",
            }
          )}
        >
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "HEALTH_CONTEXT" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-crazy-purple bg-crazy-purple text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">1</div>
            </div>
            <div>HEALTH CONTEXT</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "TAKE_SAMPLE" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-coral bg-coral text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">2</div>
            </div>
            <div>TAKE YOUR SAMPLE</div>
          </div>
          <div
            className={`flex items-center mx-auto ${
              currentHealthHistoryStep === "HEALTH_PROFILE" ? "block" : "hidden"
            }`}
          >
            <div className="border rounded-full border-marine bg-marine text-white text-center mr-2">
              <div className="w-6 h-6 block leading-6">3</div>
            </div>
            <div>HEALTH PROFILE</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Header;
