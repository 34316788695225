import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import RectangularButton from "../common/rectangularButton";
import BacteriaIcon from "./icons/bacteria-icon.svg";
import PillsIcon from "./icons/pills-icon.svg";
import BottleIcon from "./icons/bottle-icon.svg";
import {
  AntibioticsTooltip,
  RemoveAntibioticsDisclaimer,
} from "../care/careIntroDefault";
import { TreatmentPathwayCards } from "../care/treatmentOptions/treatmentPathwayCards";

// library
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import Markdown from "markdown-to-jsx";
import { getButtonCTATextForConsultation } from "../care/utils";
import { Test } from "../../types/test";
import { sendRemoveAntibioticsClicked } from "../../utils/analytics/customEventTracking";

import { handleCareIntroCTAClick } from "../care/utils";

// Constants
import { CARE_CARD_LEARN_MORE, CARE_CARD_IMAGE_SUBTEXT } from "./constants";
import {
  CARE_DEFAULT_VALUE_PROP_BULLETS,
  CARE_CARD_SUBHEAD,
  CARE_SHIPPING_HSA_COPY,
} from "../care/constants";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

const CARE_CARD_HEADER = {
  maintenance: "Maintain Microbiome Health",
  treatment: "Put your plan into action",
};

const PROBIOTICS_ONLY_VALUE_PROPS = [
  {
    icon: BacteriaIcon,
    text: "Our probiotics are prescription-only and pharmaceutical-grade, designed with the most effective Lactobacillus strains identified in recent research.",
  },
  {
    icon: PillsIcon,
    text: "These strains are also compounded with supplements proven to support the regrowth of protective bacteria — in one easy capsule.",
  },
  {
    icon: BottleIcon,
    text: "Includes both oral capsules and vaginal suppositories to ensure fast and effective delivery of treatment, only available through Evvy.",
  },
];

const MAINTENANCE_VALUE_PROPS = [
  {
    icon: BacteriaIcon,
    text: "Includes a 3-month supply of oral and vaginal probiotics, and 2 full courses of boric acid suppositories to use when you feel symptoms coming on.",
  },
  {
    icon: PillsIcon,
    text: "Our oral and vaginal probiotics are prescription-only and pharmaceutical-grade, designed with the most effective Lactobacillus strains identified in recent research.",
  },
  {
    icon: BottleIcon,
    text: "These strains are also compounded with supplements proven to support the regrowth of protective bacteria — in one easy capsule.",
  },
];

const CARE_DEFAULT_VALUE_PROPS = [
  {
    icon: BacteriaIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[0],
  },
  {
    icon: PillsIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[1],
  },
  {
    icon: BottleIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[2],
  },
];

// Care component shown on the Plan page.
const CareCard = ({
  consult,
  analyticsEventArgs,
  test,
  removeAntibiotics,
  setRemoveAntibiotics,
  selectedPathwaySlug,
  setSelectedPathwaySlug,
}: {
  consult: any;
  analyticsEventArgs: any;
  test: Test;
  removeAntibiotics: boolean;
  setRemoveAntibiotics: (removeAntibiotics: boolean) => void;
  selectedPathwaySlug: string;
  setSelectedPathwaySlug: (pathway: string) => void;
}) => {
  const currentUser = useLoggedInUser();
  const firstTimeVaginitisCare =
    !currentUser.care.vaginitis.hasCompletedConsult;

  const ctaCopy = getButtonCTATextForConsultation(
    consult,
    firstTimeVaginitisCare
  );

  const disclaimerModalSeen =
    localStorage.getItem("removeAntibioticsDisclaimerSeen") === "true";
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const location = "plan";
  const navigate = useNavigate();

  // if they've already paid, then show their selected treatment pathway as their only eligible treatment pathway
  const eligibleTreatmentPathways = consult?.consult_paid
    ? [consult.treatment_pathway]
    : test.eligible_treatment_pathways;

  const showMultipleTreatmentPathways = eligibleTreatmentPathways.length > 1;
  const hasMaintenancePathway = eligibleTreatmentPathways.some(
    (pathway) => pathway.type === "maintenance"
  );

  const hasProbioticsOnly = eligibleTreatmentPathways.some(
    (pathway) => pathway.slug === "probiotics-only"
  );

  // TODO: rip out remove antibiotics code. This logic no longer works with new estrogen pathways
  const minPrice = removeAntibiotics
    ? 199
    : Math.min(
        ...eligibleTreatmentPathways.map(
          (pathway) => pathway.pricing_info.discounted_total
        )
      );

  const numMonths = hasMaintenancePathway ? 3 : 2;

  const priceCopyMD = `${
    hasMaintenancePathway ? "Maintenance" : "Treatment"
  } programs start at **$${Math.floor(
    minPrice / numMonths
  )} per month** for ${numMonths} mo.`;
  const careDisclaimerCopy = `Your specific ${eligibleTreatmentPathways[0].type} program is determined by a provider.`;
  const careCardHeader =
    !hasMaintenancePathway || showMultipleTreatmentPathways
      ? CARE_CARD_HEADER.treatment
      : CARE_CARD_HEADER.maintenance;

  const careCardSubhead = showMultipleTreatmentPathways
    ? CARE_CARD_SUBHEAD.default
    : hasMaintenancePathway
    ? CARE_CARD_SUBHEAD.maintenance
    : hasProbioticsOnly
    ? CARE_CARD_SUBHEAD.probioticsOnly
    : CARE_CARD_SUBHEAD.default;

  const valueProps = hasMaintenancePathway
    ? MAINTENANCE_VALUE_PROPS
    : hasProbioticsOnly
    ? PROBIOTICS_ONLY_VALUE_PROPS
    : CARE_DEFAULT_VALUE_PROPS;

  return (
    <>
      {disclaimerModalOpen && (
        <RemoveAntibioticsDisclaimer
          location={location}
          testHash={test.hash}
          closeModal={() => setDisclaimerModalOpen(false)}
          onDisclaimerAccepted={() => {
            sendRemoveAntibioticsClicked({
              removeAntibiotics: true,
              testHash: test.hash,
              location,
            });
            setRemoveAntibiotics(true);
            setDisclaimerModalOpen(false);
          }}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl m-auto md:py-16">
        <div className="relative md:block hidden my-auto">
          <img
            src={"/images/graphics/care-packaging-tile-square.png"}
            loading="lazy"
            alt="Care Packaging"
            className="rounded-2xl hidden md:block"
          />
          <p className="py-3 px-4 text-center top-0 left-0 right-auto bottom-auto my-auto absolute bg-highlighter-yellow font-semibold text-sm tracking-wider uppercase rounded-tl-2xl rounded-br-2xl w-fit ">
            {CARE_CARD_IMAGE_SUBTEXT}
          </p>
        </div>
        <div className="flex flex-col p-4 md:ml-16 my-auto md:px-8">
          <div className="space-x-2 mx-auto md:mx-0">
            <span className="bg-highlighter-yellow rounded-full py-1 px-2.5 font-semibold text-sm tracking-wider uppercase my-auto">
              New!
            </span>
            <span className="font-semibold text-sm tracking-wider uppercase my-auto ">
              {careCardHeader}
            </span>
          </div>
          <h3 className="md:block hidden m-0 my-4">{careCardSubhead}</h3>
          <h3 className="md:hidden m-0 text-center my-4">{careCardSubhead}</h3>
          <div className="relative md:hidden my-4">
            <img
              src={"/images/graphics/care-packaging-tile.png"}
              loading="lazy"
              alt="Care Packaging"
              className="rounded-2xl w-full"
            />
            <p className="py-3 px-4 text-center my-auto absolute bottom-0 left-0 w-full bg-highlighter-yellow rounded-b-2xl font-semibold text-sm tracking-wider uppercase">
              {CARE_CARD_IMAGE_SUBTEXT}
            </p>
          </div>
          {showMultipleTreatmentPathways ? (
            <TreatmentPathwayCards
              treatmentPathways={eligibleTreatmentPathways}
              selectedPathwaySlug={selectedPathwaySlug}
              setSelectedPathwaySlug={setSelectedPathwaySlug}
              analyticsArgs={{ testHash: test.hash, location: "plan" }}
              previousTreatmentPathway={
                test.previous_test_care.previous_treatment_pathway
              }
            />
          ) : (
            <>
              {valueProps.map((valueProp, index) => (
                <>
                  <div className="flex mt-4">
                    <img
                      src={valueProp.icon}
                      alt={`Icon ${index}`}
                      key={index}
                      className="w-10 h-10 ml-2 mr-6"
                    />
                    <p className="b2 my-auto">{valueProp.text}</p>
                  </div>
                  <hr className="opacity-70 m-0 mt-4" />
                </>
              ))}{" "}
            </>
          )}
          <div className="mt-5 sm:mt-8">
            <RectangularButton
              text={ctaCopy}
              bgColorClass="bg-evvy-blue"
              textColorClass="text-evvy-black"
              onClick={analyticsClickHandler({
                eventName: eventNames.PLAN_CLICKED_START_CONSULT,
                eventArgs: {
                  ...analyticsEventArgs,
                  ctaCopy: ctaCopy,
                  location: "plan-care-card",
                },
                clickHandler: () =>
                  handleCareIntroCTAClick(
                    test,
                    consult,
                    removeAntibiotics,
                    navigate,
                    ctaCopy,
                    location,
                    selectedPathwaySlug
                  ),
              })}
              fullWidth
              verticalPadding="py-6"
            />
            <div className="b2 mt-4 text-center md:text-left caption">
              <Markdown>
                {`${
                  !showMultipleTreatmentPathways ? priceCopyMD : ""
                } ${CARE_SHIPPING_HSA_COPY} ${careDisclaimerCopy}`}
              </Markdown>
              {` `}
              <Link
                to="/care/"
                className="inline border-b border-evvy-black cursor-pointer"
                onClick={analyticsClickHandler({
                  eventName: eventNames.PLAN_CLICKED_LEARN_MORE_CARE,
                  eventArgs: {
                    ...analyticsEventArgs,
                    location: "plan-care-card",
                    ctaText: ctaCopy,
                  },
                })}
              >
                {CARE_CARD_LEARN_MORE}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareCard;
