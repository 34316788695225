import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
  uncheckNoneChangeHandler,
} from "../../utils/answerUtils";

const PregnancyForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const pregnancyStatusQuestion = useRef(null);
  const pregnancyBreastfeedingQuestion = useRef(null);
  const pregnancyPostpartumTimingQuestion = useRef(null);
  const pregnancyMonthQuestion = useRef(null);
  const pregnancyPreviouslyQuestion = useRef(null);
  const pregnancyExperiencedQuestion = useRef(null);
  const pregnancyExperiencedOtherQuestion = useRef(null);

  const allQuestions = {
    pregnancy_status: pregnancyStatusQuestion,
    pregnancy_breastfeeding: pregnancyBreastfeedingQuestion,
    pregnancy_postpartum_timing: pregnancyPostpartumTimingQuestion,
    pregnancy_month: pregnancyMonthQuestion,
    pregnancy_previously: pregnancyPreviouslyQuestion,
    pregnancy_experienced: pregnancyExperiencedQuestion,
    pregnancy_experienced_other: pregnancyExperiencedOtherQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const populateInitialValues = () => {
    return {
      pregnancy_status: healthContext.pregnancy_status,
      pregnancy_breastfeeding: healthContext.pregnancy_breastfeeding, // your current breastfeeding status might not be the same as past
      pregnancy_postpartum_timing: healthContext.pregnancy_postpartum_timing, // your current postpartum timing will not be the same as past health history test timing
      pregnancy_month: healthContext.pregnancy_month, // your current pregnancy month will not be same as past health history test month
      pregnancy_previously: prefillAnswer({
        questionName: "pregnancy_previously",
        healthContext,
      }),
      pregnancy_experienced: prefillMultiSelectAnswer({
        questionName: "pregnancy_experienced",
        healthContext,
      }),
      pregnancy_experienced_other: prefillAnswer({
        questionName: "pregnancy_experienced_other",
        healthContext,
      }),
    };
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "pregnancy",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Pregnancy</h3>
        <div className="flex-1 leading-5">
          Pregnancy brings on changes in hormones, and changes in hormones lead
          to changes in the vaginal microbiome. The vaginal microbiome is also
          linked to risk of pregnancy-related complications, so knowing whether
          or not you have experienced these is crucial!
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );
          ReactTooltip.rebuild();
          // required questions
          const requiredQuestions = ["pregnancy_previously"];
          if (values.pregnancy_status.includes("CU")) {
            requiredQuestions.push("pregnancy_month");
          }
          if (values.pregnancy_status.includes("PP")) {
            requiredQuestions.push("pregnancy_postpartum_timing");
            requiredQuestions.push("pregnancy_breastfeeding");
          }
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          const requiredMultiselectQuestions = ["pregnancy_status"];
          if (values.pregnancy_previously === "YE") {
            requiredMultiselectQuestions.push("pregnancy_experienced");
          }
          requiredMultiselectQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required "other" context
          const requiredOtherQuestions = ["pregnancy_experienced"];
          requiredOtherQuestions.forEach((key) => {
            if (values[key].includes("OT") && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
            }
          });

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("pregnancy_status", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("pregnancy_status", errors, apiErrors)}
              data-for="pregnancy_status"
              ref={pregnancyStatusQuestion}
            >
              <ReactTooltip
                id="pregnancy_status"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("pregnancy_status")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.pregnancy_status.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="checkbox"
                      name="pregnancy_status"
                      value={o[0]}
                      className={indexCheckboxStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.pregnancy_status.includes("PP") ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("pregnancy_breastfeeding", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "pregnancy_breastfeeding",
                  errors,
                  apiErrors
                )}
                data-for="pregnancy_breastfeeding"
                ref={pregnancyBreastfeedingQuestion}
              >
                <ReactTooltip
                  id="pregnancy_breastfeeding"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("pregnancy_breastfeeding")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.pregnancy_breastfeeding.map((o) => (
                    <label className="block mb-3 cursor-pointer" key={o[0]}>
                      <Field
                        type="radio"
                        name="pregnancy_breastfeeding"
                        value={o[0]}
                        className={indexRadioStyles(null)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {values.pregnancy_status.includes("PP") ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("pregnancy_postpartum_timing", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "pregnancy_postpartum_timing",
                  errors,
                  apiErrors
                )}
                data-for="pregnancy_postpartum_timing"
                ref={pregnancyPostpartumTimingQuestion}
              >
                <ReactTooltip
                  id="pregnancy_postpartum_timing"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("pregnancy_postpartum_timing")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.pregnancy_postpartum_timing.map(
                    (o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="pregnancy_postpartum_timing"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {values.pregnancy_status.includes("CU") ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("pregnancy_month", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("pregnancy_month", errors, apiErrors)}
                data-for="pregnancy_month"
                ref={pregnancyMonthQuestion}
              >
                <ReactTooltip
                  id="pregnancy_month"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("pregnancy_month")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.pregnancy_month.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="pregnancy_month"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("pregnancy_previously", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("pregnancy_previously", errors, apiErrors)}
              data-for="pregnancy_previously"
              ref={pregnancyPreviouslyQuestion}
            >
              <ReactTooltip
                id="pregnancy_previously"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("pregnancy_previously")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.pregnancy_previously.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="pregnancy_previously"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.pregnancy_previously === "YE" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("pregnancy_experienced", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("pregnancy_experienced", errors, apiErrors)}
                data-for="pregnancy_experienced"
                ref={pregnancyExperiencedQuestion}
              >
                <ReactTooltip
                  id="pregnancy_experienced"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("pregnancy_experienced")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.pregnancy_experienced.map((o, i) => (
                    <label
                      className="block mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="checkbox"
                        name="pregnancy_experienced"
                        value={o[0]}
                        className={indexCheckboxStyles(i)}
                        onChange={uncheckNoneChangeHandler({
                          handleChange,
                          setFieldValue,
                          fieldName: "pregnancy_experienced",
                          fieldValues: values.pregnancy_experienced,
                          noneCode: "NA",
                        })}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {values.pregnancy_experienced.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("pregnancy_experienced_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "pregnancy_experienced_other",
                  errors,
                  apiErrors
                )}
                data-for="pregnancy_experienced_other"
                ref={pregnancyExperiencedOtherQuestion}
              >
                <ReactTooltip
                  id="pregnancy_experienced_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("pregnancy_experienced_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="pregnancy_experienced_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PregnancyForm;
