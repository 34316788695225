import classNames from "classnames";

const indexColor = (index) => {
  const colorMap = {
    0: "evvy-pine",
    1: "evvy-silverfish",
    2: "coral",
    3: "crazy-purple",
    4: "marine",
    5: "evvy-blue",
    6: "evvy-black",
    7: "highlighter-purple",
    8: "highlighter-mint",
    9: "highlighter-salmon",
    10: "evvy-pine",
    11: "evvy-silverfish",
    12: "coral",
    13: "crazy-purple",
    14: "marine",
    15: "evvy-blue",
  };
  const numColors = Object.keys(colorMap).length;
  const modIndex = index % numColors;
  return colorMap[modIndex];
};

// multiselect checkboxes
const indexCheckboxStyles = (index) => {
  const isMobile = window && window.innerWidth < 600 ? true : false;
  const color = indexColor(index);
  return `border-none w-6 h-6 rounded-sm shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-${color} opacity-100 bg-evvy-cream active:bg-evvy-cream checked:bg-${color} active:bg-${color} focus:bg-${color} ${
    !isMobile ? `hover:bg-${color}` : ""
  }`;
};

// for radio buttons. takes optional index to color it, otherwise blue
const indexRadioStyles = (index) => {
  var color = "evvy-blue";
  if (index !== null) {
    color = indexColor(index);
  }
  return `border-none w-6 h-6 rounded-full shadow-none cursor-pointer focus:ring-0 focus:outline-0 text-${color} opacity-100 bg-evvy-cream active:bg-evvy-cream checked:bg-${color} active:bg-${color} hover:bg-${color} focus:bg-${color}`;
};

const multiselectFollowupRadioStyles = (index) => {
  var color = "evvy-blue";
  if (index !== null) {
    color = indexColor(index);
  }
  return `hidden peer required`;
};

const multiselectFollowupStyles = (firstChild, lastChild) => {
  return classNames(
    `inline-flex items-center justify-center w-full p-5 bg-evvy-cream peer-checked:bg-highlighter-mint cursor-pointer dark:hover:text-evvy-black dark:peer-checked:bg-highlighter-mint peer-checked:text-evvy-black hover:text-evvy-black hover:bg-gray-100 dark:text-gray-700 dark:bg-evvy-cream dark:hover:bg-highlighter-mint my-px`,
    {
      "rounded-t-lg": firstChild,
      "rounded-b-lg": lastChild,
    }
  );
};

export {
  indexColor,
  indexCheckboxStyles,
  indexRadioStyles,
  multiselectFollowupStyles,
  multiselectFollowupRadioStyles,
};
