import Markdown from "markdown-to-jsx";
import CloseX from "../common/closeX";
import Modal from "../common/modal";
import { researchCopy } from "../../pages/plan_unfurled/constants";
import { Consult, Prescription } from "../../types/care";
import { ProductCitation } from "../../types/care";
import usePrescriptions from "../../hooks/care/usePrescriptions";

const PrescriptionDetailBlock: React.FC<{
  type: string;
  content: string[];
  title?: string;
}> = ({ type, content, title }) => {
  if (!content || !content.length) {
    return null;
  }

  if (type === "block")
    return (
      <div className="mt-12">
        {title && <div className="t3 text-evvy-pine mb-4">{title}</div>}
        <div className="space-y-4">
          {content?.map((text: string) => (
            <div
              key={text}
              className="leading-5 pb-4 border-b border-dashed last:border-b-0"
            >
              <Markdown>{text}</Markdown>
            </div>
          ))}
        </div>
      </div>
    );
  if (type === "warning")
    return (
      <div className="mt-2 bg-red-100 p-4 rounded-md">
        <div className="text-red-700 medium">
          {content?.map((warning: string) => (
            <div key={warning} className="leading-5 pb-1">
              {warning}
            </div>
          ))}
        </div>
      </div>
    );
  return null;
};

type PrescriptionModalProps = {
  consultId: Consult["uid"] | undefined;
  prescriptionSlug: Prescription["slug"];
  onClose: () => void;
};

const PrescriptionModal: React.FC<PrescriptionModalProps> = ({
  consultId,
  prescriptionSlug,
  onClose,
}) => {
  const { prescriptionFromSlug } = usePrescriptions(consultId);
  const prescription = prescriptionFromSlug(prescriptionSlug);

  if (!prescription) return null;

  const splitAndFilter = (str: string | undefined) =>
    (str || "").split("|").filter((i: string) => i);

  const instructions = splitAndFilter(prescription.instructions);
  const reasons = splitAndFilter(prescription.reason_for_prescribing);
  const sideEffects = splitAndFilter(prescription.side_effects);
  const warnings = splitAndFilter(prescription.warning);
  const doseTexts = splitAndFilter(prescription.dose_text);

  const prescriptionDetails = [
    {
      type: "block",
      key: "Instructions",
      title: "How to use",
      content: instructions,
    },
    { type: "warning", key: "Warning", content: warnings },
    {
      type: "block",
      key: "SideEffects",
      title: "Possible side effects",
      content: sideEffects,
    },
    {
      type: "block",
      key: "Reasons",
      title: "Reason for prescribing",
      content: reasons,
    },
  ];

  return (
    <Modal
      widthClass="w-full sm:w-10/12 md:w-9/12 lg:w-8/12 xl:w-6/12"
      closeModal={onClose}
    >
      <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
        <div className="flex justify-end items-center">
          <CloseX handleClose={onClose} textColorClass="text-evvy-black" />
        </div>

        <div className="flex items-center my-4">
          {prescription.image_url && (
            <div className="flex-shrink-0 bg-mud rounded-md h-16 w-16 p-2 pb-0 mr-4">
              <img src={prescription.image_url} className="h-full w-full" />
            </div>
          )}
          <div>
            <div className="t1">
              {prescription?.title?.split("|").map((title: string) => (
                <div key={title}>{title}</div>
              ))}
            </div>
          </div>
        </div>

        <hr className="mt-8" />

        {prescription.description && (
          <div className="t1 medium mt-8 leading-5">
            <Markdown>{prescription.description}</Markdown>
          </div>
        )}

        <div className="sm:flex sm:space-x-12 mt-12">
          <div className="flex-1">
            <div className="t3 text-evvy-pine mb-4">Your Dose</div>
            <div className="leading-5">
              {doseTexts.map((doseText: string) => (
                <div key={doseText} className="pb-1">
                  {doseText}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 mt-12 sm:mt-0">
            <div className="t3 text-evvy-pine mb-4">Your Quantity</div>
            <div className="leading-5">{prescription.quantity_text}</div>
          </div>
        </div>

        {prescriptionDetails.map((detail) => (
          <PrescriptionDetailBlock
            type={detail.type}
            title={detail.title}
            content={detail.content}
          />
        ))}

        <div className="sm:flex sm:space-x-12 mt-12">
          {prescription.research_rating?.title && (
            <div className="flex-1">
              <div className="t3 text-evvy-pine mb-4">Research</div>
              <div className="py-3 my-4 border-b border-t border-evvy-pine/15">
                <p className="text-evvy-pine mb-1">
                  <span className="medium">Status: </span>
                  <span>{prescription.research_rating?.title}</span>
                </p>
                <div className="caption">
                  {prescription.research_rating?.description}
                </div>
              </div>
              <div className="caption">{researchCopy.RESEARCH}</div>
            </div>
          )}
          {prescription.productcitation_set.length && (
            <div className="flex-1 mt-12 sm:mt-0">
              <div className="t3 text-evvy-pine mb-4">Our sources</div>
              <div className="space-y-2">
                {prescription?.productcitation_set?.map(
                  (citation: ProductCitation) => (
                    <div key={citation?.title}>
                      <a
                        href={citation?.url}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center medium"
                      >
                        <div className="border-b border-black">
                          {citation?.title}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PrescriptionModal;
