import { FormikErrors } from "formik";
import { HealthContextFields } from "../../../types/test";

const FormFooter = ({
  handleSubmit,
  errors,
  isLastPage,
  isSubmitting,
  disabled,
}: {
  handleSubmit: () => void;
  errors: FormikErrors<any>;
  isLastPage?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className="border-t border-evvy-black bg-evvy-cream w-screen p-6 fixed bottom-0 left-0 flex justify-center">
      {errors && Object.keys(errors).length > 0 && (
        <div className="bg-coral p-2 px-3 font-medium rounded-md mb-8">
          Please fix the errors above to continue
        </div>
      )}
      <div className="block sm:flex items-center">
        <div className="flex-auto text-center">
          <button
            className="cursor-pointer tracking-wider py-6 px-10 sm:px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none"
            onClick={handleSubmit}
            disabled={isSubmitting || disabled}
            type="button"
          >
            {isLastPage ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
