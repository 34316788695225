import { CollapsibleDropdown } from "../common/collapsibleDropdown";
import { CollapsibleDropdownGroup } from "../common/collapsibleDropdownGroup";

import { Bullets } from "../common/bullets";

import CareFullTreatmentDiagram from "./images/care-full-treatment-diagram.png";
import {
  sendExpandWhatYouGetWithEvvy,
  sendExpandWhyOurTreatmentsWork,
} from "../../utils/analytics/customEventTracking";
import { Test } from "../../types/test";
import { Consult, TreatmentPathway } from "../../types/care";

const CARE_VALUE_PROPS_PROBIOITCS_ONLY_COPY = {
  title:
    "Innovative prescription probiotics designed for your unique microbiome",
  whatYouGet: {
    header: "What you get with Evvy",
    bullets: [
      "If prescribed, get 30 capsules of prescription-grade oral probiotics and 20 suppositories of prescription-grade vaginal probiotics",
      "Depending on your symptoms, your provider may also prescribe a soothing ointment",
      "Dairy-free options available",
    ],
  },
  whyOurTreatmentsWork: {
    header: "Why our treatments work",
    bullets: [
      "Our probiotics are prescription-only and pharmaceutical-grade, designed with the most effective Lactobacillus strains identified in recent research.",
      "These strains are also compounded with supplements like lactoferrin and Vitamin D, proven to support the regrowth of protective bacteria — in one easy capsule.",
      "Includes both oral and vaginal capsules to ensure fast and effective delivery of treatment, only available through Evvy.",
    ],
  },
};

const CARE_VALUE_PROPS_MAINTENANCE_COPY = {
  title: "Innovative maintenance program designed for your unique microbiome",
  whatYouGet: {
    header: "What you get with Evvy",
    bullets: [
      "If prescribed, get 45 capsules of prescription-grade oral probiotics, 24 suppositories of prescription-grade vaginal probiotics, and 14 suppositories of boric acid",
      "Depending on your symptoms, your provider may also prescribe a soothing ointment",
      "Dairy-free options available",
    ],
  },
  whyOurTreatmentsWork: {
    header: "Why our treatments work",
    bullets: [
      "Our probiotics are prescription-only and pharmaceutical-grade, designed with the most effective Lactobacillus strains identified in recent research.",
      "These strains are also compounded with supplements like lactoferrin and Vitamin D, proven to support the regrowth of protective bacteria — in one easy capsule.",
      "Includes both oral and vaginal capsules to ensure fast and effective delivery of treatment, only available through Evvy.",
    ],
  },
};

const CARE_VALUE_PROPS_FULL_PLAN_COPY = {
  title:
    "Innovative prescription treatments designed for your unique microbiome",
  whatYouGet: {
    header: "What you get with Evvy",
    bullets: [
      "A provider will review your results and prescribe a custom treatment program unique to your microbiome",
      "This program may include treatments like boric acid, targeted antibiotics / antifungals, vaginal + oral probiotics and supplements, and/or a soothing cream",
    ],
  },
  whyOurTreatmentsWork: {
    header: "Why it works",
    bullets: [
      "Prescription-only and pharmaceutical-grade treatments that are designed to not only fight disruptive bacteria, but also regrow protective strains",
      "Compounded with supplements like lactoferrin and Vitamin D, proven to further support the regrowth of protective bacteria",
      "Where possible, treatments are administered vaginally, ensuring fast and effective delivery of the right medications",
    ],
  },
};

const ANTIBIOTICS_DISCLAIMER_COPY =
  "You will be able to tell your provider what treatments you are not interested in, in case you prefer to treat without antibiotics!";

export const CareValueProps: React.FC<{
  test: Test;
  consult?: Consult;
  selectedPathwaySlug: string;
}> = ({ test, consult, selectedPathwaySlug }) => {
  const eligibleTreatmentPathways = consult?.consult_paid
    ? [consult.treatment_pathway]
    : test.eligible_treatment_pathways;

  const maintenancePathway = eligibleTreatmentPathways.find(
    (pathway) => pathway.type === "maintenance"
  );

  const maintenanceSelected =
    maintenancePathway && maintenancePathway.slug === selectedPathwaySlug;

  const probioticsOnly =
    eligibleTreatmentPathways[0].slug === "probiotics-only";

  const copy = maintenanceSelected
    ? CARE_VALUE_PROPS_MAINTENANCE_COPY
    : probioticsOnly
    ? CARE_VALUE_PROPS_PROBIOITCS_ONLY_COPY
    : CARE_VALUE_PROPS_FULL_PLAN_COPY;

  return (
    <section className="p-8 bg-evvy-dark-cream">
      <div
        className="grid grid-cols-1 md:grid-cols-3 md:p-8 md:m-auto max-w-7xl space-x-32  min-h-[670px]
"
      >
        <div className="md:mt-20">
          <h4 className="md:hidden mt-4 text-[22px]">{copy.title}</h4>
          <h3 className="hidden md:block md:max-w-lg md:mx-auto">
            {copy.title}
          </h3>
          <img
            src={
              maintenanceSelected
                ? "/images/graphics/care-maintenance-diagram.png"
                : probioticsOnly
                ? "/images/graphics/care-probiotics-diagram.png"
                : CareFullTreatmentDiagram
            }
            alt="Care probiotics diagram"
            className=" md:col-span-2 md:max-w-2xl object-contain md:m-auto md:hidden"
          />

          <CollapsibleDropdownGroup
            collapseOthersOnOpen
            className="mt-8 md:max-w-lg md:mx-auto w-full"
          >
            {[
              <CollapsibleDropdown
                key={1}
                headerText={copy.whatYouGet.header}
                showHorizontalRule
                handleOpen={(open) => {
                  if (open) {
                    sendExpandWhatYouGetWithEvvy({
                      location: "care-value-props",
                      testHash: test.hash,
                      carePrice: test.care_pricing?.discounted_total,
                    });
                  }
                }}
              >
                <Bullets bullets={copy.whatYouGet.bullets} iconType="arrow" />
                {!probioticsOnly && !maintenanceSelected && (
                  <div className="text-sm regular leading-4 ml-5 mb-6 -mt-1">
                    {ANTIBIOTICS_DISCLAIMER_COPY}
                  </div>
                )}
              </CollapsibleDropdown>,
              <CollapsibleDropdown
                key={2}
                headerText={copy.whyOurTreatmentsWork.header}
                showHorizontalRule
                handleOpen={(open) => {
                  if (open) {
                    sendExpandWhyOurTreatmentsWork({
                      location: "care-value-props",
                      testHash: test.hash,
                      carePrice: test.care_pricing?.discounted_total,
                    });
                  }
                }}
              >
                <Bullets
                  bullets={copy.whyOurTreatmentsWork.bullets}
                  iconType="arrow"
                />
              </CollapsibleDropdown>,
            ]}
          </CollapsibleDropdownGroup>
        </div>
        <img
          src={
            maintenanceSelected
              ? "/images/graphics/care-maintenance-diagram.png"
              : probioticsOnly
              ? "/images/graphics/care-probiotics-diagram.png"
              : CareFullTreatmentDiagram
          }
          alt="Care probiotics diagram"
          className="md:col-span-2 md:max-w-2xl object-contain md:m-auto h-full hidden md:block"
        />
      </div>
    </section>
  );
};
