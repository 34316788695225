import React from "react";
import { Link } from "react-router-dom";
import { Test } from "../../../../types/test";
import { getHealthHistoryURL } from "../../../../utils/healthHistory";
import Header from "../../../../components/healthHistoryV2/questions/header";
import TimelineNumber from "../../../../components/plan_unfurled/timelineNumber";

const HealthHistoryConfirmStart = ({ test }: { test: Test }) => {
  return (
    <div
      className="w-full flex flex-col place-items-center min-h-screen"
      style={{
        background:
          "linear-gradient(212deg, #D6D6FF 14.25%, #CEDEFC 63.21%, #D6D6FF 109.88%)",
      }}
    >
      <Header currentSection={"intro"} hash={test.hash} />
      <h3 className="text-center mt-8 px-4">What to expect</h3>
      <div className={"bg-evvy-white md:w-5/12 w-5/6 rounded-lg"}>
        <div className={"bg-evvy-cream text-center rounded-t-lg"}>
          <p className={"p-4 b2 semibold"}>Test ID: {test.hash}</p>
        </div>
        <div className="m-6">
          <div className="text-center mb-12">
            <p className="t5 t5 mb-2  ">THE TESTING PROCESS:</p>
            <p className="b2 semibold">
              Note you must complete all parts to receive results!
            </p>
          </div>
          <div>
            <div className="flex">
              <div className="flex flex-col items-center pr-4">
                <TimelineNumber number={1} color="bg-evvy-pine" />
                <div className="border border-evvy-pine/25 h-full" />
              </div>
              <div className="mb-6">
                <div className={"flex"}>
                  <p className="t5 mb-3">HEALTH CONTEXT • 5 MIN</p>
                </div>
                <p className="b2">
                  Questions about your current goals and/or symptoms that will
                  help us problem solve with you.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center pr-4">
                <TimelineNumber number={2} color="bg-coral" />
                <div className="border border-evvy-pine/25 h-full" />
              </div>
              <div className="mb-6">
                <div className={"flex"}>
                  <p className="t5 mb-3">TAKE YOUR SAMPLE • 1 MIN</p>
                </div>
                <p className="b2">Collecting your sample is easy and quick.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col items-center pr-4">
                <TimelineNumber number={3} color="bg-crazy-purple" />
              </div>
              <div>
                <div className={"flex"}>
                  <p className="t5 mb-3">HEALTH PROFILE • 5 MIN</p>
                </div>
                <p className="b2">
                  Questions that will help us curate the best resources and
                  recommendations for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 flex">
        <Link
          to={getHealthHistoryURL(test.hash, "verify")}
          className="w-full sm:w-auto block mx-auto"
          onClick={() => window.scrollTo(0, 0)}
        >
          <button className="cursor-pointer tracking-wider w-full py-6 px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
            Let's Go →
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HealthHistoryConfirmStart;
