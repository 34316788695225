import React from "react";
import { getQuestionTitle, questionTitles } from "../../../utils/questions";
import { SectionIntro } from "../../../types/test";

const QuestionTitle = ({
  questionTitle,
  sectionIntro,
  optional,
}: {
  questionTitle: string;
  sectionIntro?: SectionIntro;
  optional?: boolean;
}) => {
  let title = questionTitle;
  const v2Array = [
    "symptoms_vaginal_smell",
    "symptoms_vaginal_discharge_color",
    "symptoms_vaginal_discharge_consistency",
    "sexual_activity_condoms",
    "hormonal_symptoms",
  ];
  if (v2Array.includes(questionTitle)) {
    title = questionTitle + "_v2";
  }
  let caption;
  if (Object.keys(questionTitles)?.includes(title + "_caption")) {
    caption = getQuestionTitle(title + "_caption");
  }

  return (
    <div>
      {sectionIntro && (
        <div className="mb-6 pb-6 border-b border-evvy-black border-opacity-20 w-full">
          {sectionIntro?.header && (
            <p className="semibold mb-2 text-evvy-pine">
              {sectionIntro?.header}
            </p>
          )}
          <p className="mb-0"> {sectionIntro.text}</p>
          {sectionIntro.citations && (
            <ol className="mx-0 caption mt-2 mb-0">
              {sectionIntro.citations.map((citation, i) => {
                return (
                  <li className="flex mb-2" key={`learn-citation-${i}`}>
                    <div className="w-4">{i + 1}. </div>
                    <a href={citation.url} target="_blank" rel="noreferrer">
                      <div className="border-b border-evvy-black w-fit b3">
                        {citation.title}
                      </div>
                    </a>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      )}
      <p className="t3 mb-2">
        {getQuestionTitle(title)}
        {optional ? " (optional)" : <span className="text-evvy-blue"> *</span>}
      </p>
      <p className="b4 mt-0">{caption}</p>
    </div>
  );
};

export default QuestionTitle;
