import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { indexRadioStyles, indexCheckboxStyles } from "../../utils/colors";
import { arraysEqual } from "../../utils/general";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";

const BasicsForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const currentSymptomsQuestion = useRef(null);
  const symptomsBestDescriptionQuestion = useRef(null);
  const symptomsTriggerQuestion = useRef(null);
  const newReasonQuestion = useRef(null);
  const recentPeriodQuestion = useRef(null);
  const periodDateQuestion = useRef(null);
  const hopingToLearnQuestion = useRef(null); // 2nd+ test only
  const followRecommendationsQuestion = useRef(null); // 2nd+ test only
  const followRecommendationsDetailsQuestion = useRef(null); // 2nd+ test only
  const changesSinceLastQuestion = useRef(null); // 2nd+ test only
  const changesSinceLastDetailsQuestion = useRef(null); // 2nd+ test only
  // post-care test only
  const postCareSymptomChangeQuestion = useRef(null);
  const postCareImprovedSymptomsQuestion = useRef(null);
  const postCareImprovedSymptomsOtherQuestion = useRef(null);
  const postCareSideEffectsQuestion = useRef(null);
  const postCareSideEffectsOtherQuestion = useRef(null);
  const postCareFollowedProtocolQuestion = useRef(null);

  const allQuestions = {
    symptoms_has_current: currentSymptomsQuestion,
    symptoms_best_description: symptomsBestDescriptionQuestion,
    symptoms_reason_for_test: newReasonQuestion,
    symptoms_obvious_trigger: symptomsTriggerQuestion,
    basics_recent_period: recentPeriodQuestion,
    basics_last_period_date: periodDateQuestion,
    basics_hoping_to_learn: hopingToLearnQuestion,
    basics_follow_recommendations: followRecommendationsQuestion,
    basics_follow_recommendations_details: followRecommendationsDetailsQuestion,
    basics_changes_since_last: changesSinceLastQuestion,
    basics_changes_since_last_details: changesSinceLastDetailsQuestion,
    post_care_symptom_change: postCareSymptomChangeQuestion,
    post_care_improved_symptoms: postCareImprovedSymptomsQuestion,
    post_care_improved_symptoms_other: postCareImprovedSymptomsOtherQuestion,
    post_care_side_effects: postCareSideEffectsQuestion,
    post_care_side_effects_other: postCareSideEffectsOtherQuestion,
    post_care_followed_protocol: postCareFollowedProtocolQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "the basics",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">The Basics</h3>
        <div className="flex-1"></div>
      </div>

      <Formik
        initialValues={{
          symptoms_has_current: healthContext.symptoms_has_current || "",
          symptoms_best_description:
            healthContext.symptoms_best_description || "",
          symptoms_reason_for_test:
            healthContext.symptoms_reason_for_test || [],
          symptoms_obvious_trigger:
            healthContext.symptoms_obvious_trigger || [],
          basics_recent_period: healthContext.basics_recent_period || "",
          basics_last_period_date: healthContext.basics_last_period_date || "",
          basics_hoping_to_learn: healthContext.basics_hoping_to_learn || "",
          basics_follow_recommendations:
            healthContext.basics_follow_recommendations || "",
          basics_follow_recommendations_details:
            healthContext.basics_follow_recommendations_details || "",
          basics_changes_since_last:
            healthContext.basics_changes_since_last || [],
          basics_changes_since_last_details:
            healthContext.basics_changes_since_last_details || "",
          post_care_symptom_change:
            healthContext.post_care_symptom_change || "",
          post_care_improved_symptoms:
            healthContext.post_care_improved_symptoms || [],
          post_care_improved_symptoms_other:
            healthContext.post_care_improved_symptoms_other || "",
          post_care_side_effects: healthContext.post_care_side_effects || [],
          post_care_side_effects_other:
            healthContext.post_care_side_effects_other || "",
          post_care_followed_protocol: healthContext.post_care_followed_protocol
            ? String(healthContext.post_care_followed_protocol)
            : undefined,
        }}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          var requiredQuestions = [
            "basics_recent_period",
            "symptoms_has_current",
          ];
          var requiredMultiselects = [];
          var requiredOtherQuestions = [
            "post_care_improved_symptoms",
            "post_care_side_effects",
          ];

          // if answer yes to symptoms question, then best description question is required
          if (values.symptoms_has_current === "YE") {
            requiredQuestions.push("symptoms_best_description");
            requiredMultiselects.push("symptoms_obvious_trigger");
          }

          // if answers no to symptoms question, then new symptoms reason for test multiselect question is required
          if (values.symptoms_has_current === "NO") {
            requiredMultiselects.push("symptoms_reason_for_test");
          }

          // if it's second time around test but not post care, some more required questions
          if (
            test.has_other_active_test &&
            !test.previous_test_care.previous_test_completed_care
          ) {
            requiredQuestions.push("basics_follow_recommendations");
            requiredMultiselects.push("basics_changes_since_last");
          } else if (test.previous_test_care.previous_test_completed_care) {
            requiredQuestions.push("basics_hoping_to_learn");
            requiredQuestions.push("post_care_symptom_change");
            requiredMultiselects.push("post_care_improved_symptoms");
            requiredMultiselects.push("post_care_side_effects");
            requiredQuestions.push("post_care_followed_protocol");
          }

          // required questions
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          requiredMultiselects.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current);
            }
          });

          // required "other" context if "Other" is selected
          requiredOtherQuestions.forEach((key) => {
            if (values[key].includes("OT") && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current);
            }
          });

          // required "details" context for following recommendations (explain if said yes)
          if (
            values["basics_follow_recommendations"] === "YE" &&
            !values[`basics_follow_recommendations_details`]
          ) {
            errors[`basics_follow_recommendations_details`] =
              "This is a required question";
            ReactTooltip.show(
              allQuestions[`basics_follow_recommendations_details`].current
            );
          }
          // required "details" context for recent changes (anything but "nothing changed")
          if (
            values["basics_changes_since_last"].length &&
            !arraysEqual(values[`basics_changes_since_last`], ["NO"]) &&
            !values[`basics_changes_since_last_details`]
          ) {
            errors[`basics_changes_since_last_details`] =
              "This is a required question";
            ReactTooltip.show(
              allQuestions[`basics_changes_since_last_details`].current
            );
          }

          // conditional required question
          if (
            values.basics_recent_period === "YE" &&
            !values.basics_last_period_date
          ) {
            errors["basics_last_period_date"] = "This is a required question";
            ReactTooltip.show(periodDateQuestion.current); // manually show, without requiring hover
          }

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);

          // if they are not submitting a last period, don't send that field
          if (values.basics_recent_period === "NO") {
            // delete values.basics_last_period_date;
            values.basics_last_period_date = null;
          }

          submitCurrentPage(values, (response) => {
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) =>
                ReactTooltip.show(allQuestions[key].current)
              );
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <ReactTooltip
              effect="solid"
              place="left"
              type="error"
              backgroundColor="#FFA684"
              textColor="#11161A"
              className="rounded-xs py-20"
            />

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("symptoms_has_current", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("symptoms_has_current", errors, apiErrors)}
              data-for="symptoms_has_current"
              ref={currentSymptomsQuestion}
            >
              <ReactTooltip
                id="symptoms_has_current"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("symptoms_has_current")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.symptoms_has_current.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="symptoms_has_current"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.symptoms_has_current &&
              (values.symptoms_has_current === "YE" ? (
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("symptoms_best_description", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "symptoms_best_description",
                    errors,
                    apiErrors
                  )}
                  data-for="symptoms_best_description"
                  ref={symptomsBestDescriptionQuestion}
                >
                  <ReactTooltip
                    id="symptoms_best_description"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("symptoms_best_description")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.symptoms_best_description.map(
                      (o, i) => (
                        <label
                          className="block mb-3 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="radio"
                            name="symptoms_best_description"
                            value={o[0]}
                            className={indexRadioStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("symptoms_reason_for_test", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "symptoms_reason_for_test",
                    errors,
                    apiErrors
                  )}
                  data-for="symptoms_reason_for_test"
                  ref={newReasonQuestion}
                >
                  <ReactTooltip
                    id="symptoms_reason_for_test"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("symptoms_reason_for_test")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.symptoms_reason_for_test.map(
                      (o, i) => (
                        <label
                          className="block mb-2 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="checkbox"
                            name="symptoms_reason_for_test"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>
              ))}
            {values.symptoms_has_current === "YE" && (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("symptoms_obvious_trigger", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "symptoms_obvious_trigger",
                  errors,
                  apiErrors
                )}
                data-for="symptoms_obvious_trigger"
                ref={symptomsTriggerQuestion}
              >
                <ReactTooltip
                  id="symptoms_obvious_trigger"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("symptoms_obvious_trigger")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {healthContext._options.symptoms_obvious_trigger.map(
                    (o, i) => (
                      <label
                        className="block mb-2 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="symptoms_obvious_trigger"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    )
                  )}
                </div>
              </div>
            )}
            {/* Additional questions if its the second time around */}
            {test.has_other_active_test && (
              <>
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("basics_hoping_to_learn", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "basics_hoping_to_learn",
                    errors,
                    apiErrors
                  )}
                  data-for="basics_hoping_to_learn"
                  ref={hopingToLearnQuestion}
                >
                  <ReactTooltip
                    id="basics_hoping_to_learn"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("basics_hoping_to_learn")}
                  </h4>
                  <div>
                    <Field
                      name="basics_hoping_to_learn"
                      as="textarea"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            )}
            {test.has_other_active_test &&
            !test.previous_test_care.previous_test_completed_care ? (
              <React.Fragment>
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("basics_follow_recommendations", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "basics_follow_recommendations",
                    errors,
                    apiErrors
                  )}
                  data-for="basics_follow_recommendations"
                  ref={followRecommendationsQuestion}
                >
                  <ReactTooltip
                    id="basics_follow_recommendations"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("basics_follow_recommendations")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.basics_follow_recommendations.map(
                      (o, i) => (
                        <label
                          className="block mb-3 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="radio"
                            name="basics_follow_recommendations"
                            value={o[0]}
                            className={indexRadioStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {/* show question unless someone says no the the above */}
                {values.basics_follow_recommendations == "YE" ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError(
                        "basics_follow_recommendations_details",
                        errors,
                        apiErrors
                      )
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "basics_follow_recommendations_details",
                      errors,
                      apiErrors
                    )}
                    data-for="basics_follow_recommendations_details"
                    ref={followRecommendationsDetailsQuestion}
                  >
                    <ReactTooltip
                      id="basics_follow_recommendations_details"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle(
                        "basics_follow_recommendations_details"
                      )}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="basics_follow_recommendations_details"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("basics_changes_since_last", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "basics_changes_since_last",
                    errors,
                    apiErrors
                  )}
                  data-for="basics_changes_since_last"
                  ref={changesSinceLastQuestion}
                >
                  <ReactTooltip
                    id="basics_changes_since_last"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("basics_changes_since_last")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>
                    Please select all that apply. FYI you will be able to update
                    your answers for diagnoses, birth control, condom usage,
                    treatments, menopause/pregnancy status, etc. if any of those
                    changed later in this form!
                  </p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.basics_changes_since_last.map(
                      (o, i) => (
                        <label className="block mb-2 cursor-pointer" key={o[0]}>
                          <Field
                            type="checkbox"
                            name="basics_changes_since_last"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                            onChange={(e) => {
                              handleChange(e);
                              // uncheck all other boxes if checks None
                              if (e.target.value === "NO" && e.target.checked) {
                                setFieldValue("basics_changes_since_last", [
                                  "NO",
                                ]);
                              }
                            }}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {values.basics_changes_since_last.length &&
                !arraysEqual(values.basics_changes_since_last, ["NO"]) ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError(
                        "basics_changes_since_last_details",
                        errors,
                        apiErrors
                      )
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "basics_changes_since_last_details",
                      errors,
                      apiErrors
                    )}
                    data-for="basics_changes_since_last_details"
                    ref={changesSinceLastDetailsQuestion}
                  >
                    <ReactTooltip
                      id="basics_changes_since_last_details"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("basics_changes_since_last_details")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="basics_changes_since_last_details"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              ""
            )}

            {/* Additional questions if its the second time around and they completed care */}
            {test.previous_test_care.previous_test_completed_care && (
              <>
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("post_care_symptom_change", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "post_care_symptom_change",
                    errors,
                    apiErrors
                  )}
                  data-for="post_care_symptom_change"
                  ref={postCareSymptomChangeQuestion}
                >
                  <ReactTooltip
                    id="post_care_symptom_change"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("post_care_symptom_change")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.post_care_symptom_change.map(
                      (o, i) => (
                        <label
                          className="block mb-3 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="radio"
                            name="post_care_symptom_change"
                            value={o[0]}
                            className={indexRadioStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>
                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("post_care_improved_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "post_care_improved_symptoms",
                    errors,
                    apiErrors
                  )}
                  data-for="post_care_improved_symptoms"
                  ref={postCareImprovedSymptomsQuestion}
                >
                  <ReactTooltip
                    id="post_care_improved_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("post_care_improved_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.post_care_improved_symptoms.map(
                      (o, i) => (
                        <label
                          className="block mb-2 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="checkbox"
                            name="post_care_improved_symptoms"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {values.post_care_improved_symptoms.includes("OT") && (
                  <div
                    className={`p-4 border rounded-md ${
                      getError(
                        "post_care_improved_symptoms_other",
                        errors,
                        apiErrors
                      )
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "post_care_improved_symptoms_other",
                      errors,
                      apiErrors
                    )}
                    data-for="post_care_improved_symptoms_other"
                    ref={postCareImprovedSymptomsOtherQuestion}
                  >
                    <ReactTooltip
                      id="post_care_improved_symptoms_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("post_care_improved_symptoms_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="post_care_improved_symptoms_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                )}

                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("post_care_side_effects", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "post_care_side_effects",
                    errors,
                    apiErrors
                  )}
                  data-for="post_care_side_effects"
                  ref={postCareSideEffectsQuestion}
                >
                  <ReactTooltip
                    id="post_care_side_effects"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("post_care_side_effects")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {healthContext._options.post_care_side_effects.map(
                      (o, i) => (
                        <label
                          className="block mb-2 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="checkbox"
                            name="post_care_side_effects"
                            value={o[0]}
                            className={indexCheckboxStyles(i)}
                          />
                          <span className="ml-2">{o[1]}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                {values.post_care_side_effects.includes("OT") && (
                  <div
                    className={`p-4 border rounded-md ${
                      getError(
                        "post_care_side_effects_other",
                        errors,
                        apiErrors
                      )
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "post_care_side_effects_other",
                      errors,
                      apiErrors
                    )}
                    data-for="post_care_side_effects_other"
                    ref={postCareSideEffectsOtherQuestion}
                  >
                    <ReactTooltip
                      id="post_care_side_effects_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("post_care_side_effects_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="post_care_side_effects_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                )}

                <div
                  className={`mt-12 p-4 border rounded-md ${
                    getError("post_care_followed_protocol", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError(
                    "post_care_followed_protocol",
                    errors,
                    apiErrors
                  )}
                  data-for="post_care_followed_protocol"
                  ref={postCareFollowedProtocolQuestion}
                >
                  <ReactTooltip
                    id="post_care_followed_protocol"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("post_care_followed_protocol")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p className="block sm:hidden">
                    <div>1: Not very closely</div>
                    <div>5: Very closely</div>
                  </p>
                  <div className="flex items-center flex-wrap">
                    <div className="mr-4 hidden sm:block">Not very closely</div>
                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="flex flex-grow"
                    >
                      {[...Array(6).keys()]
                        .filter((k) => k !== 0)
                        .map(
                          (
                            o,
                            i // range from 1 - 10
                          ) => (
                            <label
                              className="mx-0 md:mx-2 cursor-pointer text-center flex-1"
                              key={String(o)}
                            >
                              <div className="mb-1">{o}</div>
                              <Field
                                type="radio"
                                name="post_care_followed_protocol"
                                value={String(o)}
                                className={indexRadioStyles(i)}
                              />
                            </label>
                          )
                        )}
                    </div>
                    <div className="ml-4 hidden sm:block">Very closely</div>
                  </div>
                </div>
              </>
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("basics_recent_period", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("basics_recent_period", errors, apiErrors)}
              data-for="basics_recent_period"
              ref={recentPeriodQuestion}
            >
              <ReactTooltip
                id="basics_recent_period"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("basics_recent_period")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.basics_recent_period.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="radio"
                      name="basics_recent_period"
                      value={o[0]}
                      className={indexRadioStyles(i)}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* show question unless someone says no the the above */}
            {values.basics_recent_period == "YE" ? (
              <div
                className={`mt-12 p-4 border rounded-md ${
                  getError("basics_last_period_date", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "basics_last_period_date",
                  errors,
                  apiErrors
                )}
                ref={periodDateQuestion}
              >
                <h4 className="t1">
                  {getQuestionTitle("basics_last_period_date")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <p>It's okay to guess if you're not exactly sure!</p>
                <Field
                  name="basics_last_period_date"
                  type="date"
                  min={
                    new Date(Date.now() - 60 * 24 * 3600 * 1000)
                      .toISOString()
                      .split("T")[0]
                  } // 60 days ago
                  max={new Date().toISOString().split("T")[0]} // today
                  className="border-0 border-b border-evvy-black w-full outline-none focus:ring-0 focus:border-evvy-black"
                />
              </div>
            ) : (
              ""
            )}

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BasicsForm;
