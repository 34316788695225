import React from "react";
import QuestionTitle from "./questionTitle";
import { Field } from "formik";
import { indexRadioStyles } from "../../../utils/colors";
import { SectionIntro } from "../../../types/test";

const NumberScale = ({
  questionTitle,
  sectionIntro,
}: {
  questionTitle: string;
  sectionIntro?: SectionIntro;
}) => {
  let scale = 10;
  let scaleTextLow = "Not at all";
  let scaleTextHigh = "Huge impact, I alter aspects of my life around it";
  if (questionTitle === "post_care_followed_protocol") {
    scale = 5;
    scaleTextLow = "Not at all";
    scaleTextHigh = "Followed closely";
  }
  return (
    <div>
      <QuestionTitle
        questionTitle={questionTitle || ""}
        sectionIntro={sectionIntro}
      />
      <div>
        <p className="block sm:hidden">
          <div>1: {scaleTextLow}</div>
          <div>
            {scale}: {scaleTextHigh}
          </div>
        </p>
        <div className="flex items-center flex-wrap">
          <div className="mr-4 hidden sm:block">{scaleTextLow}</div>
          <div
            role="group"
            aria-labelledby="checkbox-group"
            className="flex flex-grow"
          >
            {[...Array(scale + 1).keys()]
              .filter((k) => k !== 0)
              .map(
                (
                  o,
                  i // range from 1 - 10
                ) => (
                  <label
                    className="mx-0 md:mx-2 cursor-pointer text-center flex-1"
                    key={String(o)}
                  >
                    <div className="mb-1">{o}</div>
                    <Field
                      type="radio"
                      name={questionTitle}
                      value={String(o)}
                      className={indexRadioStyles(i)}
                    />
                  </label>
                )
              )}
          </div>
          <div className="ml-4 hidden sm:block w-36">{scaleTextHigh}</div>
        </div>
      </div>
    </div>
  );
};

export default NumberScale;
