import React from "react";
import Header from "../../../../components/healthHistoryV2/questions/header";
import { Page, getHealthHistoryURL } from "../../../../utils/healthHistory";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProgressBar from "../../../../components/healthHistoryV2/progressBar";
import LightBlueGreenGradient from "../../../../components/common/images/light-blue-green-gradient.png";

const ProfileIntro = () => {
  const { testHash: hash } = useParams();
  const previousPage: Page = {
    section: "sample-confirm",
  };
  return (
    <div
      className="w-full bg-cover bg-no-repeat min-h-screen"
      style={{
        backgroundImage: `url(${LightBlueGreenGradient})`,
      }}
    >
      <Header
        currentSection={"profile"}
        previousPage={previousPage}
        hash={hash}
      />
      <ProgressBar currentSection={"profile"} />
      <Helmet>
        <title>Test | History | Info</title>
      </Helmet>
      <div className="max-w-full sm:max-w-4xl py-8 mx-auto px-8 sm:px-0 ">
        <h3 className="text-center">Your Health Profile</h3>
        <div className="bg-white p-8 rounded-lg mx-auto w-11/12 md:w-9/12">
          <p className="text-lg pt-2 font-semibold">
            You’re almost there! Just a few more questions so that our team of
            researchers and experts can curate the best resources and
            recommendations for you.
          </p>
          <p className="text-md pt-2">
            <b>Note:</b> Most questions have been prefilled with your answers
            from a previous test. Please read carefully and update any answers
            that may have changed.
          </p>
        </div>
      </div>
      <div className="flex">
        <Link
          to={getHealthHistoryURL(hash, "demographics", "demographics_height")}
          className="sm:w-auto block mx-auto"
          onClick={() => window.scrollTo(0, 0)}
        >
          <button className="cursor-pointer tracking-wider w-full py-6 px-20 font-semibold rounded-xs shadow-sm uppercase bg-evvy-blue focus:outline-none">
            Great! Let's Go →
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProfileIntro;
