import { Font } from "@react-pdf/renderer";

// font imports
import Inter from "../../styles/fonts/inter/Inter-Regular.ttf";
import InterThin from "../../styles/fonts/inter/Inter-Thin.ttf";
import InterLight from "../../styles/fonts/inter/Inter-Light.ttf";
import InterMedium from "../../styles/fonts/inter/Inter-Medium.ttf";
import InterSemiBold from "../../styles/fonts/inter/Inter-SemiBold.ttf";
import InterBold from "../../styles/fonts/inter/Inter-Bold.ttf";
import InterExtraBold from "../../styles/fonts/inter/Inter-ExtraBold.ttf";
import InterBlack from "../../styles/fonts/inter/Inter-Black.ttf";
import InterItalic from "../../styles/fonts/inter/Inter-Italic.otf";

// Register fonts so that they can be used in pdfStyles below
Font.register({
  src: Inter,
  family: "Inter",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: Inter,
    },
    {
      src: InterItalic,
      fontStyle: "italic",
    },
    {
      src: InterThin,
      fontWeight: "thin",
    },
    {
      src: InterLight,
      fontWeight: "light",
    },
    {
      src: InterMedium,
      fontWeight: "medium",
    },
    {
      src: InterSemiBold,
      fontWeight: "semibold",
    },
    {
      src: InterBold,
      fontWeight: "bold",
    },
    {
      src: InterExtraBold,
      fontWeight: "extrabold",
    },
    {
      src: InterBlack,
      fontWeight: "black",
    },
  ],
});

const pageHorizontalPadding = 55;

const pdfStyles = [
  {
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: pageHorizontalPadding,
    },
    fs_italic: {
      fontFamily: "Inter",
      fontStyle: "italic",
    },
    fw_normal: {
      fontFamily: "Inter",
      // fontWeight: '300'
    },
    fw_thin: {
      fontFamily: "Inter",
      fontWeight: 100,
      // fontWeight: 'thin'
    },
    fw_light: {
      fontFamily: "Inter",
      fontWeight: 200,
      // fontWeight: 'light'
    },
    fw_medium: {
      fontFamily: "Inter",
      fontWeight: 400,
      // fontWeight: 'medium'
    },
    fw_semi_bold: {
      fontFamily: "Inter",
      fontWeight: 500,
      // fontWeight: 'semibold'
    },
    fw_bold: {
      fontFamily: "Inter",
      fontWeight: 600,
      // fontWeight: 'bold'
    },
    fw_extra_bold: {
      fontFamily: "Inter",
      fontWeight: 700,
      // fontWeight: 'extrabold'
    },
    fw_black: {
      fontFamily: "Inter",
      fontWeight: 800,
      // fontWeight: 'black'
    },
    fs_body: {
      fontSize: 13,
    },
    fs_h2: {
      fontSize: 15,
    },
    fs_h3: {
      fontSize: 14,
    },
    fs_h4: {
      fontSize: 11,
    },
    fs_h5: {
      fontSize: 8,
    },
    fs_9: {
      fontSize: 9,
    },
    fs_util_1: {
      fontSize: 24,
    },
    lh_lg: {
      lineHeight: 1.25,
    },
    lh_md: {
      lineHeight: 0.7,
    },
    lh_s: {
      lineHeight: 0.2,
    },
    pt_xxl: {
      paddingTop: 50,
    },
    pt_xl: {
      paddingTop: 20,
    },
    pt_l: {
      paddingTop: 15,
    },
    pt_m: {
      paddingTop: 10,
    },
    pt_s: {
      paddingTop: 5,
    },
    pt_xs: {
      paddingTop: 4,
    },
    pt_xxs: {
      paddingTop: 3.5,
    },
    pb_xxl: {
      paddingBottom: 30,
    },
    pb_xl: {
      paddingBottom: 20,
    },
    pb_l: {
      paddingBottom: 15,
    },
    pb_m: {
      paddingBottom: 10,
    },
    pb_s: {
      paddingBottom: 5,
    },
    pb_xs: {
      paddingBottom: 0,
    },
    pl_s: {
      paddingLeft: 5,
    },
    pr_s: {
      paddingRight: 5,
    },
    ph_md: {
      paddingHorizontal: 7,
    },
    ta_c: {
      textAlign: "center",
    },
    ta_l: {
      textAlign: "left",
    },
    ta_r: {
      textAlign: "right",
    },
    ta_j: {
      textAlign: "justify",
    },
    ta_btw: {
      justifyContent: "space-between",
    },
    ta_strt: {
      justifyContent: "flex-start",
    },
    d_flex: {
      display: "flex",
    },
    flex_row: {
      flexDirection: "row",
    },
    border_t: {
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderTopWidth: 2,
    },
    border_b: {
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderBottomWidth: 2,
    },
    align_c: {
      alignItems: "center",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: "auto",
      paddingBottom: 30,
      width: "15%",
    },
    pageNumber: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 0,
      color: "grey",
    },
    disclaimer: {
      position: "absolute",
      bottom: 38,
      color: "grey",
      fontSize: 8,
      textAlign: "left",
      left: 0,
      right: 0,
      paddingHorizontal: pageHorizontalPadding,
    },
    htmlBlocks: {
      fontFamily: "Inter",
      textAlign: "justify",
      fontSize: 13,
      lineHeight: 1.25,
      paddingBottom: 10,
      paddingTop: 5,
      color: "#5f5f5f",
      margin: 0,
    },
  },
];

export default pdfStyles;
