import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import UpdateReminderMsg from "./updateReminderMessage";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import { getQuestionTitle } from "../../utils/questions";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import {
  prefillAnswer,
  prefillMultiSelectAnswer,
  uncheckNoneChangeHandler,
} from "../../utils/answerUtils";

const RelatedDiagnosesForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, healthContext, test } = subrouteProps;

  // for tooltips
  const diagnosesQuestion = useRef(null);
  const relatedDiagnosesQuestion = useRef(null);
  const relatedDiagnosesOtherQuestion = useRef(null);
  const immunocompromisedQuestion = useRef(null);

  const allQuestions = {
    diagnoses: diagnosesQuestion,
    related_diagnoses: relatedDiagnosesQuestion,
    related_diagnoses_other: relatedDiagnosesOtherQuestion,
    related_diagnoses_immunocompromised: immunocompromisedQuestion,
  };
  const diagnosesQuestions = [
    ["diagnoses_bv", getQuestionTitle("diagnoses_bv")],
    [
      "diagnoses_yeast_infection",
      getQuestionTitle("diagnoses_yeast_infection"),
    ],
    ["diagnoses_uti", getQuestionTitle("diagnoses_uti")],
    ["diagnoses_av", getQuestionTitle("diagnoses_av")],
    ["diagnoses_ureaplasma", getQuestionTitle("diagnoses_ureaplasma")],
    ["diagnoses_mycoplasma", getQuestionTitle("diagnoses_mycoplasma")],
    [
      "diagnoses_cytolytic_vaginosis",
      getQuestionTitle("diagnoses_cytolytic_vaginosis"),
    ],
    [
      "diagnoses_vaginal_lactobacillosis",
      getQuestionTitle("diagnoses_vaginal_lactobacillosis"),
    ],
    ["diagnoses_chlamydia", getQuestionTitle("diagnoses_chlamydia")],
    ["diagnoses_trichomoniasis", getQuestionTitle("diagnoses_trichomoniasis")],
    ["diagnoses_gonorrhea", getQuestionTitle("diagnoses_gonorrhea")],
  ];
  const populateInitialValues = () => {
    var initialValues = {
      related_diagnoses: prefillMultiSelectAnswer({
        questionName: "related_diagnoses",
        healthContext,
      }),
      related_diagnoses_other: prefillAnswer({
        questionName: "related_diagnoses_other",
        healthContext,
      }),
      related_diagnoses_immunocompromised:
        healthContext.related_diagnoses_immunocompromised,
    };
    diagnosesQuestions.forEach((q) => {
      // default it to past health history if exists, otherwise default to Never
      initialValues[q[0]] = prefillMultiSelectAnswer({
        questionName: q[0],
        healthContext,
        answerOptions: healthContext._options.diagnoses,
        defaultAns: ["NE"],
      });
    });
    return initialValues;
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "profile",
      questionGroup: "related diagnoses",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Related Diagnoses</h3>
        <div className="flex-1 leading-5">
          Research is starting to highlight that other medical issues can
          influence the microbiome, so we want to make sure to check in on those
          too while personalizing your results. If this isn't something you
          experience, just click "Never Diagnosed" or "None"!
        </div>
      </div>

      {healthContext.past_health_history ? <UpdateReminderMsg /> : ""}

      <Formik
        initialValues={populateInitialValues()}
        validate={(values) => {
          var errors = {};
          ReactTooltip.hide(diagnosesQuestion.current);
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          // each question is required, but we just have 1 tooltip for all of them
          diagnosesQuestions.forEach((q) => {
            if (!values[q[0]].length) {
              errors["diagnoses"] = true;
              ReactTooltip.show(diagnosesQuestion.current); // manually show, without requiring hover
            }
          });

          // required questions
          const requiredQuestions = ["related_diagnoses_immunocompromised"];
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required multiselect questions (multi select, needs at least 1)
          const requiredMultiselectQuestions = ["related_diagnoses"];
          requiredMultiselectQuestions.forEach((key) => {
            if (!values[key].length) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });

          // required "other" context
          const requiredOtherQuestions = ["related_diagnoses"];
          requiredOtherQuestions.forEach((key) => {
            if (values[key].includes("OT") && !values[`${key}_other`]) {
              errors[`${key}_other`] = "This is a required question";
              ReactTooltip.show(allQuestions[`${key}_other`].current); // manually show, without requiring hover
            }
          });

          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);
          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((k) => {
                ReactTooltip.show(allQuestions[k].current);
              });
            } else {
              setError(response || "Error saving health context");
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("diagnoses", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={
                getError("diagnoses", errors, apiErrors)
                  ? "Please select at least one option for each"
                  : ""
              }
              data-for="diagnoses"
              ref={diagnosesQuestion}
            >
              <ReactTooltip
                id="diagnoses"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />

              <h4 className="t1">
                {getQuestionTitle("diagnoses")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>

              <div className="max-w-full table-fixed p-0 overflow-x-auto">
                <table className="table-fixed">
                  <thead>
                    <tr>
                      <th colSpan="2" className="w-36 sm:w-auto"></th>
                      {healthContext._options.diagnoses.map((o) => (
                        <th className="w-36 sm:w-auto" key={o[0]}>
                          {o[1]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosesQuestions.map((q, i) => (
                      <tr key={q[0]}>
                        <td colSpan="2">{q[1]}</td>
                        {healthContext._options.diagnoses.map((o) => (
                          <td key={o[0]}>
                            <Field
                              type="checkbox"
                              name={q[0]}
                              value={o[0]}
                              className={`h-5 w-5 ${indexCheckboxStyles(i)}`}
                              onChange={uncheckNoneChangeHandler({
                                handleChange,
                                setFieldValue,
                                fieldName: q[0],
                                fieldValues: values[q[0]],
                                noneCode: "NE",
                              })}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError("related_diagnoses", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("related_diagnoses", errors, apiErrors)}
              data-for="related_diagnoses"
              ref={relatedDiagnosesQuestion}
            >
              <ReactTooltip
                id="related_diagnoses"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("related_diagnoses")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <p>Please select all that apply</p>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.related_diagnoses.map((o, i) => (
                  <label
                    className="block mb-3 cursor-pointer flex items-center"
                    key={o[0]}
                  >
                    <Field
                      type="checkbox"
                      name="related_diagnoses"
                      value={o[0]}
                      className={indexCheckboxStyles(i)}
                      onChange={uncheckNoneChangeHandler({
                        handleChange,
                        setFieldValue,
                        fieldName: "related_diagnoses",
                        fieldValues: values.related_diagnoses,
                      })}
                    />
                    <span className="ml-2">{o[1]}</span>
                  </label>
                ))}
              </div>
            </div>

            {values.related_diagnoses.includes("OT") ? (
              <div
                className={`p-4 border rounded-md ${
                  getError("related_diagnoses_other", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError(
                  "related_diagnoses_other",
                  errors,
                  apiErrors
                )}
                data-for="related_diagnoses_other"
                ref={relatedDiagnosesOtherQuestion}
              >
                <ReactTooltip
                  id="related_diagnoses_other"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {getQuestionTitle("related_diagnoses_other")}{" "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  name="related_diagnoses_other"
                  as="input"
                  className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                  placeholder="Your Answer"
                  autoComplete="off"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className={`mt-12 p-4 border rounded-md ${
                getError(
                  "related_diagnoses_immunocompromised",
                  errors,
                  apiErrors
                )
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError(
                "related_diagnoses_immunocompromised",
                errors,
                apiErrors
              )}
              data-for="related_diagnoses_immunocompromised"
              ref={immunocompromisedQuestion}
            >
              <ReactTooltip
                id="related_diagnoses_immunocompromised"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {getQuestionTitle("related_diagnoses_immunocompromised")}{" "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <div role="group" aria-labelledby="checkbox-group">
                {healthContext._options.related_diagnoses_immunocompromised.map(
                  (o) => (
                    <label className="block mb-3 cursor-pointer" key={o[0]}>
                      <Field
                        type="radio"
                        name="related_diagnoses_immunocompromised"
                        value={o[0]}
                        className={indexRadioStyles(null)}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  )
                )}
              </div>
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RelatedDiagnosesForm;
