import { Font } from "@react-pdf/renderer";

// font imports
import Inter from "../../styles/fonts/inter/Inter-Regular.ttf";
import InterThin from "../../styles/fonts/inter/Inter-Thin.ttf";
import InterLight from "../../styles/fonts/inter/Inter-Light.ttf";
import InterMedium from "../../styles/fonts/inter/Inter-Medium.ttf";
import InterSemiBold from "../../styles/fonts/inter/Inter-SemiBold.ttf";
import InterBold from "../../styles/fonts/inter/Inter-Bold.ttf";
import InterExtraBold from "../../styles/fonts/inter/Inter-ExtraBold.ttf";
import InterBlack from "../../styles/fonts/inter/Inter-Black.ttf";
import InterItalic from "../../styles/fonts/inter/Inter-Italic.otf";
import InterMediumItalic from "../../styles/fonts/inter/Inter-MediumItalic.otf";
import InterSemiBoldItalic from "../../styles/fonts/inter/Inter-SemiBoldItalic.otf";
import Reckless from "../../styles/fonts/reckless/RecklessNeue-Book.woff";
import RecklessItalic from "../../styles/fonts/reckless/RecklessNeue-BookItalic.woff";

// constants used in pdfStyles below
const borderColor = "#bfbfbf";
const fontColor = "#000000";
const periodTextColor = "#BA3838";
const backgroundColor = "#FFFFFF"; // used to be cream #F3F1EE
const fontSize = 9;
const smallFontSize = 8;
const lineHeight = "1.2em";
const wideLetterSpacing = "0.4px";

// Register fonts so that they can be used in pdfStyles below
Font.register({
  src: Inter,
  family: "Inter",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: Inter,
      // fontWeight: 'normal',
    },
    {
      src: InterItalic,
      fontStyle: "italic",
    },
    {
      src: InterThin,
      fontWeight: "thin",
    },
    {
      src: InterLight,
      fontWeight: "light",
    },
    {
      src: InterMedium,
      fontWeight: "medium",
    },
    {
      src: InterMediumItalic,
      fontWeight: "medium",
      fontStyle: "italic",
    },
    {
      src: InterSemiBold,
      fontWeight: "semibold",
    },
    {
      src: InterSemiBoldItalic,
      fontWeight: "semibold",
      fontStyle: "italic",
    },
    {
      src: InterBold,
      fontWeight: "bold",
    },
    {
      src: InterExtraBold,
      fontWeight: "extrabold",
    },
    {
      src: InterBlack,
      fontWeight: "black",
    },
  ],
});

Font.register({
  src: Reckless,
  family: "RecklessNeue",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: Reckless,
      // fontWeight: 'normal',
    },
    {
      src: RecklessItalic,
      fontStyle: "italic",
    },
  ],
});

const pdfStyles = {
  page: {
    backgroundColor: "white",
    fontSize: fontSize,
    fontFamily: "Inter",
    fontWeight: "normal",
  },
  planPage: {
    paddingBottom: 40 /* ensures enough space for footer */,
  },
  header: {
    padding: "20px",
    fontSize: "20px",
    backgroundColor: backgroundColor,
    fontFamily: "RecklessNeue",
    borderBottom: `1px solid ${borderColor}`,
    marginBottom: 20,
  },
  htmlBlocks: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: fontSize,
  },
  name: {
    fontStyle: "italic",
  },
  context: {
    background: "gray",
    padding: "10px 20px",
    display: "flex",
    flexDirection: "row",
  },
  intro: {
    width: "65%",
    paddingRight: "40px",
  },
  introTitle: {
    textTransform: "uppercase",
    fontWeight: "medium",
    marginBottom: "5px",
    letterSpacing: wideLetterSpacing,
    // fontSize: smallFontSize,
  },
  introDescription: {
    lineHeight: lineHeight,
  },
  tip: {
    flexDirection: "row",
    marginBottom: 10,
    width: "260px",
  },
  tipIconContainer: {
    height: 10,
    width: 10,
    marginRight: 5,
  },
  tipIconHeart: {
    width: 8,
    margin: "2px auto 0",
  },
  tipIconBlood: {
    width: 10,
    margin: "0 auto",
  },
  tipTitle: {
    fontWeight: "medium",
    marginBottom: 3,
  },
  tipInfo: {},
  phases: {
    width: "35%",
    paddingLeft: "10px",
  },
  phase: {
    display: "flex",
    flexDirection: "row",
    // alignItems: 'center',
  },
  phaseNumber: {
    padding: "5px 10px",
    backgroundColor: backgroundColor,
    borderRadius: "2px",
    marginBottom: "6px",
    textTransform: "uppercase",
    letterSpacing: wideLetterSpacing,
    fontWeight: "medium",
    flexShrink: 0,
    fontSize: smallFontSize,
  },
  phaseGoal: {
    padding: "5px 10px",
  },
  prescriptionsTable: {
    display: "table",
    width: "auto",
    margin: "10px 20px 10px 20px",
    // borderRadius: '5px',
    // border: `1px solid ${borderColor}`,
  },
  tableCell: {
    padding: "10px",
    width: "33%",
  },
  tableRow: {
    flexDirection: "row",
    borderRight: `1px solid ${borderColor}`,
    borderLeft: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
  },
  lastPrescription: {},
  prescriptionsTableHeader: {
    backgroundColor: backgroundColor,
    flexDirection: "row",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    border: `1px solid ${borderColor}`,
  },
  prescriptionsTableHeaderCell: {
    textTransform: "uppercase",
    fontWeight: "semibold",
    fontSize: smallFontSize,
    letterSpacing: wideLetterSpacing,
  },
  nameColumn: {
    width: "30%",
    flexDirection: "row",
  },
  nameImageContainer: {
    width: 30,
    height: 30,
    marginRight: 10,
    borderRadius: "3px",
    backgroundColor: "#BFBAB1",
    flexShrink: "0",
  },
  nameImage: {
    margin: "5px 2.5px 0 2.5px",
    width: 25,
    height: 25,
  },
  nameTitle: {
    fontWeight: "semibold",
    marginBottom: "5px",
    width: "100px", // need to set width explicitly here to get line breaks on overflow
  },
  nameDose: {
    width: "100px", // need to set width explicitly here to get line breaks on overflow
  },
  descriptionColumn: {
    width: "35%",
    fontWeight: "medium",
  },
  descriptionTagContainer: {
    flexDirection: "row",
  },
  descriptionTag: {
    padding: "5px 10px",
    backgroundColor: "#F3F1EE",
    borderRadius: "2px",
    marginBottom: "5px",
    textTransform: "uppercase",
    letterSpacing: wideLetterSpacing,
    display: "inline-block",
    fontWeight: "medium",
    flexShrink: 1,
    fontSize: smallFontSize,
  },
  howColumn: {
    width: "35%",
  },
  howWarning: {
    color: "#BA3838",
    backgroundColor: "#F8E0E0",
    padding: "5px 10px",
    borderRadius: "2px",
    marginBottom: "5px",
    // textTransform: 'uppercase',
    // letterSpacing: wideLetterSpacing,
    // fontSize: smallFontSize,
    fontWeight: "medium",
  },
  textRow: {
    marginBottom: 3,
    lineHeight: lineHeight,
  },
  week: {
    flexDirection: "row",
    padding: 20,
    marginBottom: 10,
  },
  weekDescription: {
    width: "40%",
    paddingRight: "40px",
  },
  weekTitle: {
    fontSize: 16,
    marginBottom: 20,
    flexDirection: "row",
    fontWeight: "semibold",
  },
  weekTitleHighlight: {
    fontWeight: "semibold",
  },
  weekTitleDull: {
    color: "#00000050",
    marginLeft: 5,
  },
  weekDescriptionMessage: {
    hyphens: "none",
  },
  weekSchedule: {
    width: "60%",
  },
  day: {
    flexDirection: "row",
    borderBottom: `1px solid ${borderColor}`,
    marginBottom: 10,
    paddingBottom: 10,
    fontWeight: "medium",
    fontSize: smallFontSize,
  },
  lastDay: {
    borderBottom: `none`,
    marginBottom: 5,
  },
  dayPeriod: {
    color: periodTextColor,
  },
  dayTitle: {
    width: "20%",
    textTransform: "uppercase",
    letterSpacing: wideLetterSpacing,
    fontSize: smallFontSize,
  },
  dayNumber: {
    marginBottom: "3px",
  },
  dayPrescriptions: {
    width: "80%",
    flexDirection: "row",
  },
  dayPrescription: {
    width: "33%",
    marginRight: "10px",
    padding: "5px 8px",
    backgroundColor: "#F3F1EE",
    borderRadius: "2px",
    // marginBottom: '5px',
    display: "inline",
    width: "auto",
    fontWeight: "medium",
    flexShrink: 0,
    fontSize: smallFontSize,
  },
  backgroundSalmon: {
    backgroundColor: "#FFA684",
  },
  backgroundEvvyBlue: {
    backgroundColor: "#6CA7FF80" /* 80% opacity */,
  },
  backgroundCrazyPurple: {
    backgroundColor: "#C29ED5",
  },
  backgroundMint: {
    backgroundColor: "#BFE3D9",
  },
  backgroundApple: {
    backgroundColor: "#731C00",
  },
  cta: {
    marginBottom: 10,
    marginTop: 20,
  },
  ctaTitle: {
    fontSize: 12,
    // marginBottom: 5,
    fontWeight: "semibold",
    flexDirection: "row",
  },
  ctaBody: {
    lineHeight: lineHeight,
    marginTop: 5,
  },
  checkmarkIcon: {
    height: 12,
    width: 12,
    marginRight: 6,
  },
  calendarFooter: {
    padding: 20,
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%",
    fontWeight: "medium",
  },
  footerLogo: {
    height: "18px",
    width: "40px",
    marginRight: "auto",
  },
  footerEmail: {
    marginLeft: "auto",
    marginRight: "auto",
    textDecoration: "none",
    color: "black",
  },
  footerPage: {
    marginLeft: "auto",
    fontWeight: "medium",
  },
  calendarHeader: {
    display: "block",
    margin: "20px 20px 1px 20px", // weird thing with bottom margin. setting to
    paddingBottom: "10px",
    fontSize: "12px",
    borderBottom: `1px solid ${borderColor}`,
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  questions: {
    paddingLeft: "20px",
  },
};

export default pdfStyles;
