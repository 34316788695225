import { useCallback, useEffect, useState } from "react";
import {
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
  Test,
} from "../../../types/test";
import QuestionWrapper from "../questions/question";
import { useNavigate, useParams } from "react-router-dom";
import {
  CONTEXT_SECTIONS,
  Page,
  SECTION_TITLES,
  getHealthHistoryURL,
  getNextQuestionWithLogic,
  getPrevQuestionWithLogic,
} from "../../../utils/healthHistory";
import Header from "../questions/header";
import ProgressBar from "../progressBar";
import { testsService } from "../../../services/tests";
import { healthContextService } from "../../../services/healthContext";

const HealthHistoryContextForm = ({
  healthContext,
  test,
  updateHealthContext,
  ldtEnabled,
  hdyhauSurvey,
  submitHdyhauSurvey,
}: {
  healthContext: HealthContextFields;
  test: Test;
  updateHealthContext: (
    hash: string,
    data: any,
    onSubmit: any,
    onError: any
  ) => void;
  ldtEnabled: boolean;
  hdyhauSurvey?: any;
  submitHdyhauSurvey?: any;
}) => {
  let { testHash: hash, section, page } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousPage, setPreviousPage] = useState<Page>();
  const navigate = useNavigate();

  const formatUrlToQuestion = (url?: string) => {
    return url?.replace(/-/g, "_");
  };

  section = formatUrlToQuestion(section);
  page = formatUrlToQuestion(page);

  const currentSection = CONTEXT_SECTIONS[section || ""];
  const currentQuestion = currentSection?.[page || ""];
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    const hasNextPage = getNextQuestionWithLogic({
      section: section || "",
      currentQuestion,
      healthContextValues: healthContext,
    });
    if (
      !hasNextPage ||
      (hasNextPage.section === "hdyhau" && hdyhauSurvey.uid)
    ) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
  }, [currentQuestion, healthContext, section]);

  const fetchPreviousPage = useCallback(() => {
    setPreviousPage(
      getPrevQuestionWithLogic({
        section: section || "",
        currentQuestion,
        healthContextValues: healthContext,
        ldtEnabled,
        test,
      })
    );
  }, [section, currentQuestion, healthContext, ldtEnabled, test]);

  useEffect(() => {
    fetchPreviousPage();
  }, [section, currentQuestion, healthContext, fetchPreviousPage]);

  const educationalMomentText =
    currentQuestion?.educationalMoment?.getText?.(healthContext);

  const handleUpdateHealthContext = (data: any, onError: any) => {
    if (hash) {
      updateHealthContext(
        hash,
        data,
        () => {
          const nextPage = getNextQuestionWithLogic({
            section: section || "",
            healthContextValues: healthContext,
            currentQuestion,
            ldtEnabled,
            test,
            hdyhauSurvey,
          });
          if (nextPage) {
            const url = getHealthHistoryURL(
              hash,
              nextPage?.section,
              nextPage?.question
            );
            navigate(url);
          } else {
            testsService.submitHealthHistory(
              test.hash,
              () => {
                navigate(`/tests/${hash}/done/`);
                setIsSubmitting(false);
                window.scrollTo(0, 0);
              },
              (error: any, response: any) => {
                alert(response);
                setIsSubmitting(false);
              }
            );
          }
        },
        onError
      );
    }
  };

  // Some questions are stored as multiselects on the backend, but we have changed them to single selects on the frontend.
  const formatSingleSelectToMultiselect = (data: any) => {
    if (!Array.isArray(data.pregnancy_status) && data.pregnancy_status) {
      data.pregnancy_status = [data.pregnancy_status];
    }
    if (currentQuestion.question === "diagnoses") {
      currentQuestion.options?.map((option) => {
        if (!option.includes("_none")) {
          if (!Array.isArray(data[option]) && data[option]) {
            data[option] = [data[option]];
          }
        }
      });
    }
    return data;
  };

  const submitCurrentPage = ({
    data,
    extraDataItems,
    relatedDiagnoses,
    onError,
  }: {
    data: any;
    extraDataItems: any;
    relatedDiagnoses: {
      updated_conditions: string[];
      field_updates: string[];
    };
    onError: any;
  }) => {
    data = formatSingleSelectToMultiselect(data);
    if (hash) {
      if (extraDataItems) {
        healthContextService.createExtraData(
          hash,
          {
            items: extraDataItems,
          },
          () => {
            if (data) {
              handleUpdateHealthContext(data, onError);
            }
          }
        );
      } else if (relatedDiagnoses) {
        return healthContextService.updateRelatedDiagnoses(
          hash,
          {
            updated_conditions: relatedDiagnoses.updated_conditions,
            field_updates: relatedDiagnoses.field_updates,
            // If creating only and no updates, conditions that are blank should be deleted.
            delete_fields: !relatedDiagnoses.field_updates,
          },
          () => {
            if (data) {
              handleUpdateHealthContext(data, onError);
            }
          }
        );
      } else if (data.hdyhau) {
        submitHdyhauSurvey(data, () => {
          handleUpdateHealthContext(data, onError);
        });
      } else {
        handleUpdateHealthContext(data, onError);
      }
    }
  };

  return (
    <div className="bg-evvy-cream min-h-screen">
      <Header
        currentSection={section}
        previousPage={previousPage}
        hash={hash}
      />
      <ProgressBar
        currentQuestion={currentQuestion.question}
        currentSection={section}
      />
      <div className="max-w-full sm:max-w-4xl py-8 mx-auto px-8 sm:px-0">
        <h3 className="text-center">{section && SECTION_TITLES[section]}</h3>
        <div>
          {currentQuestion && (
            <QuestionWrapper
              currentQuestion={currentQuestion}
              currentSection={section || ""}
              submitCurrentPage={submitCurrentPage}
              healthContext={healthContext}
              isSubmitting={isSubmitting}
              isLastPage={isLastPage}
              hdyhauSurvey={hdyhauSurvey}
              submitHdyhauSurvey={submitHdyhauSurvey}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HealthHistoryContextForm;
