import { Field } from "formik";
import { Question } from "../../../utils/healthHistoryV2";
import QuestionTitle from "./questionTitle";
import { cn } from "../../../utils/cn";

const TextFieldQuestion = ({
  question,
  currentValue,
}: {
  question: Question;
  currentValue: string;
}) => {
  const questionTitle = question.slug;
  return (
    <div className="mt-8 mb-8">
      <QuestionTitle questionTitle={questionTitle} />
      <Field
        id={questionTitle}
        name={questionTitle}
        component="textarea"
        rows={question.answerType === "multitext" ? 5 : 1} // Specify the number of rows for the text area
        className={cn("border-px w-full outline-none focus:ring-0 p-4")}
        placeholder="Your Answer"
        autoComplete="off"
        value={currentValue}
      />
    </div>
  );
};

export default TextFieldQuestion;
