import React, { useEffect, useState, useRef } from "react";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import FormFooter from "./formFooter";
import { sendViewedHealthHistoryPage } from "../../utils/analytics/customEventTracking";
import { PROVIDER_DISCLAIMER_TEXT } from "../../pages/constants";
import Markdown from "markdown-to-jsx";

const PersonalInformationForm = ({ ...subrouteProps }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({}); // key errors {field: str}

  // form only cares about submitting, all other subrouteProps passed to FormFooter
  const { submitCurrentPage, test, addressInfo } = subrouteProps;

  // for tooltips
  const addressFirstLineQuestion = useRef(null);
  const addressSecondLineQuestion = useRef(null);
  const cityQuestion = useRef(null);
  const zipQuestion = useRef(null);
  const stateQuestion = useRef(null);
  const phoneNumberQuestion = useRef(null);

  const allQuestions = {
    address_first_line: addressFirstLineQuestion,
    address_second_line: addressSecondLineQuestion,
    city: cityQuestion,
    state_code: stateQuestion,
    zip_code: zipQuestion,
    phone_number: phoneNumberQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  const getInitialValues = () => {
    return {
      address_first_line: addressInfo?.address_first_line || "",
      address_second_line: addressInfo?.address_second_line || "",
      city: addressInfo?.city || "",
      zip_code: addressInfo?.zip_code || "",
      state_code: addressInfo?.state_code || "",
      phone_number: addressInfo?.phone_number || "",
      provider_release: true,
    };
  };

  /* Effects */
  useEffect(() => {
    sendViewedHealthHistoryPage({
      section: "context",
      questionGroup: "personal-information",
      testOrder: test.test_order,
      testHash: test.hash,
    });
  }, []);

  return (
    <div>
      <div className="mb-2 border-b border-dashed py-10 sm:flex">
        <h3 className="flex-1">Personal Information</h3>
        <div className="flex-1">
          In order to process your sample, we need approval from a clinician in
          the state you're in. Please confirm your personal information below so
          we can run your labs.
        </div>
      </div>

      <Formik
        initialValues={getInitialValues()}
        validate={(values) => {
          var errors = {};
          Object.keys(allQuestions).forEach((key) =>
            ReactTooltip.hide(allQuestions[key].current)
          );

          var requiredQuestions = [
            "address_first_line",
            "city",
            "zip_code",
            "state_code",
            "phone_number",
          ];

          // required questions
          requiredQuestions.forEach((key) => {
            if (!values[key]) {
              errors[key] = "This is a required question";
              ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
            }
          });
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          setError(null);

          submitCurrentPage(values, (response) => {
            console.log("form got an error", response);
            if (typeof response === "object") {
              setApiErrors(response);
              Object.keys(response).forEach((key) =>
                ReactTooltip.show(allQuestions[key].current)
              );
            } else {
              setError(response || "Error saving personal information");
            }
          });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form>
            {error ? (
              <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
                {error}
              </div>
            ) : (
              ""
            )}

            <ReactTooltip
              effect="solid"
              place="left"
              type="error"
              backgroundColor="#FFA684"
              textColor="#11161A"
              className="rounded-xs py-20"
            />

            <div
              className={`border rounded-md mb-4 ${
                getError("address_first_line", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("address_first_line", errors, apiErrors)}
              data-for="address_first_line"
              ref={addressFirstLineQuestion}
            >
              <ReactTooltip
                id="address_first_line"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <div className="t4 mb-2">Street address</div>
              {/* wait to make sure this works properly for care intake before updating here! */}
              {/* <AddressSearchInput setFormFieldValue={setFieldValue} getInitialValues={getInitialValues} /> */}
              <Field
                name="address_first_line"
                as="input"
                className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                autoComplete="off"
              />
            </div>

            <div
              className={`border rounded-md mb-4 ${
                getError("address_second_line", errors, apiErrors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("address_second_line", errors, apiErrors)}
              data-for="address_second_line"
              ref={addressSecondLineQuestion}
            >
              <ReactTooltip
                id="address_second_line"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <div className="t4 mb-2">Apartment/Other</div>
              <Field
                name="address_second_line"
                as="input"
                className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                autoComplete="off"
              />
            </div>
            <div className="grid grid-cols-4 gap-2">
              <div
                className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                  getError("city", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("city", errors, apiErrors)}
                data-for="city"
                ref={cityQuestion}
              >
                <ReactTooltip
                  id="city"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">City</div>
                <Field
                  name="city"
                  as="input"
                  className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                  autoComplete="off"
                />
              </div>
              <div
                className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                  getError("state_code", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("state_code", errors, apiErrors)}
                data-for="state_code"
                ref={stateQuestion}
              >
                <ReactTooltip
                  id="state_code"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">State</div>
                <Field
                  name="state_code"
                  as="input"
                  className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                  autoComplete="off"
                />
              </div>
              <div
                className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                  getError("zip_code", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("zip_code", errors, apiErrors)}
                data-for="zip_code"
                ref={zipQuestion}
              >
                <ReactTooltip
                  id="zip_code"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">Zipcode</div>
                <Field
                  name="zip_code"
                  as="input"
                  className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                  autoComplete="off"
                />
              </div>
              <div
                className={`col-span-4 sm:col-span-2 border rounded-md mb-2 ${
                  getError("phone_number", errors, apiErrors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("phone_number", errors, apiErrors)}
                data-for="phone_number"
                ref={phoneNumberQuestion}
              >
                <ReactTooltip
                  id="phone_number"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <div className="t4 mb-2">Phone Number</div>
                <Field
                  name="phone_number"
                  as="input"
                  className="w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4"
                  autoComplete="off"
                />
              </div>
              {test.is_provider_ordered && test.provider && (
                <div className="mt-4 w-full col-span-4">
                  <div className="t4 mb-2">
                    Share Evvy info with your provider?
                  </div>
                  <div className="mb-2 w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4 bg-gray-200">{`${test.provider.first_name} ${test.provider.last_name}  (${test.provider.affiliated_practice})`}</div>
                  <div className="mb-4 w-full border border-gray-300 focus:outline-none focus:ring-transparent p-4 bg-gray-200">
                    {test.provider.email}
                  </div>
                  <div className="flex items-center">
                    <Field
                      id="provider_release"
                      name="provider_release"
                      type="checkbox"
                      required
                      className="bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
                    />
                    <label htmlFor="provider_release" className="ml-4">
                      <Markdown
                        options={{
                          overrides: {
                            p: {
                              props: {
                                className: "mb-0",
                              },
                            },
                          },
                        }}
                      >
                        {PROVIDER_DISCLAIMER_TEXT}
                      </Markdown>
                    </label>
                  </div>
                </div>
              )}
            </div>

            <FormFooter
              submit={handleSubmit}
              errors={errors}
              {...subrouteProps}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInformationForm;
